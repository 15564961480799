<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
    v-if="order?.status == 1"
    height="90vh"
  >
    <template v-slot:activator="{ props }">
      <v-btn color="primary" class="mr-2" variant="elevated" v-bind="props">
        Confirmar pedido
      </v-btn>
    </template>

    <v-card min-height="90vh" :loading="loading">
      <v-card-title>
        <span class="text-h5">Confirmar pedido</span>
      </v-card-title>
      <v-card-title v-if="presetSelectedStart || presetSelectedEnd">
        <span class="selected__style">
          Selecionado: entre
          {{ presetSelectedStart ? minutesToHours(presetSelectedStart) : "--" }}
          e
          {{
            presetSelectedEnd && presetSelectedStart
              ? minutesToHours(presetSelectedEnd + presetSelectedStart)
              : "--"
          }}
        </span>
      </v-card-title>
      <v-card-text class="abc123">
        <v-form ref="formData">
          <v-row>
            <v-col cols="12" class="pa-0 pb-1">
              <v-row class="ma-0">
                <v-col cols="10" class="pa-0">
                  <Vuetify3DatetimePicker
                    v-model="start"
                    :format="format"
                    :datepicker-props="{
                      'select-text': 'Selecionar',
                      'cancel-text': 'Cancelar',
                    }"
                    :text-field-props="{
                      variant: 'outlined',
                      density: 'compact',
                      label:
                        order.shipment_method == 1
                          ? 'Previsão retirada (a partir)'
                          : 'Previsão entrega (a partir)',
                      rules: [checkStartRule],
                    }"
                    :clearable="true"
                  ></Vuetify3DatetimePicker>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" outlined>
                        <v-icon left>mdi-clock</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, i) in presetsStart"
                        :key="`optionStart${i}`"
                        @click="presetSelectedStart = item.value"
                        :title="item.title"
                        :value="item.value"
                      >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pa-0 pb-3">
              <v-row class="ma-0">
                <v-col cols="10" class="pa-0">
                  <Vuetify3DatetimePicker
                    v-model="end"
                    :format="format"
                    :datepicker-props="{
                      'select-text': 'Selecionar',
                      'cancel-text': 'Cancelar',
                    }"
                    :text-field-props="{
                      variant: 'outlined',
                      density: 'compact',
                      label:
                        order.shipment_method == 1
                          ? 'Previsão retirada (até)'
                          : 'Previsão entrega (até)',
                      rules: [checkEndRule],
                    }"
                    :clearable="true"
                  ></Vuetify3DatetimePicker>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" outlined>
                        <v-icon left>mdi-clock</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, i) in presetsEnd"
                        :key="`option${i}`"
                        :title="item.title"
                        :value="item.value"
                        @click="presetSelectedEnd = item.value"
                      ></v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-text-field
                class="mt-3"
                v-model="coupon_copies"
                label="Cópias do cupom (padrão 1)"
                variant="outlined"
                density="compact"
                type="number"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => (v && v > -1) || 'Precisa ser igual ou maior que zero',
                  (v) => (v && v <= 5) || 'Limite de 5 por vez',
                ]"
              ></v-text-field>

              <v-autocomplete
                v-if="order.shipment_method != 1"
                class="mt-3"
                v-model="deliverymen_id"
                :items="deliverymens"
                v-model:search="searchDeliverymen"
                label="Entregador"
                :item-title="'name'"
                :item-value="'_id'"
                variant="outlined"
                :no-data-text="'Nenhum entregador encontrado'"
                density="compact"
                :chips="true"
                :closable-chips="true"
                :clearable="true"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip
                    v-bind="props"
                    :prepend-avatar="
                      $imgurl(item?.raw?.img ?? 'utils/genericuser.png')
                    "
                    :text="item.raw.name"
                  ></v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :prepend-avatar="
                      $imgurl(item?.raw?.img ?? 'utils/genericuser.png')
                    "
                    :title="
                      item?.raw?.name ??
                      item?.raw?.email ??
                      'S/ nome e s/ email! entrar em contato com administração.'
                    "
                  >
                    <template #subtitle>
                      <span>
                        <small>{{ `Entregas: ${
                      item?.raw?.deliverymen_current_shipping_count ?? 0
                    } em andamento e ${(item?.raw?.deliverymen_pending_shipping_count ?? 0)} p/ próximas viagens` }}</small>
                      </span>
                    </template>
                </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions class="pa-0">
          <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
              <v-alert :prominent="true" type="warning" v-if="loading">
                <template #prepend>
                  <v-progress-circular
                    :indeterminate="true"
                    color="white"
                  ></v-progress-circular>
                </template>
                Aguarde enquanto salvamos os dados
              </v-alert>
              <v-btn
                v-if="!loading"
                color="primary"
                variant="elevated"
                @click="acceptOrder(2)"
                block
                class="ma-0 mt-2"
              >
                Aceitar / Preparo imediato
              </v-btn>
              <v-btn
                v-if="!loading"
                color="secondary"
                variant="elevated"
                @click="acceptOrder(8)"
                block
                class="ma-0 mt-2"
              >
                Aceitar / Agendar
                {{ order.shipment_method == 1 ? "retirada" : "entrega" }}
              </v-btn>
              <v-btn
                v-if="!loading"
                color="primary"
                variant="outlined"
                @click="dialog = false"
                block
                class="ma-0 mt-2"
              >
                Decidir depois
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineAsyncComponent } from "vue";
import * as debounce from "lodash.debounce";
import moment from "moment";
export default {
  props: ["order"],
  components: {
    Vuetify3DatetimePicker: defineAsyncComponent(() =>
      import("@/components/Vuetify3DatetimePicker.vue")
    ),
  },
  data() {
    return {
      dialog: false,
      format: "YYYY-MM-DD HH:mm",
      start: null,
      end: null,
      loading: false,
      presetSelectedStart: null,
      presetSelectedEnd: null,
      coupon_copies: 1,
      loadingDeliverymens: false,
      searchDeliverymen: null,
      deliverymens: [],
      deliverymen_id: null,
    };
  },
  computed: {
    orderId() {
      return this.order?._id;
    },
    presetsStart() {
      const now = moment();
      let arr = [];
      const F = "DD/MM/YYYY";
      const F2 = "DD/MM/YYYY HH:mm";
      const F3 = "HH:mm";
      let i = 1;
      while (i < 30) {
        const minutes = 20 * i;
        const newDate = moment(now).add(minutes, "minutes");
        let title = "";
        if (newDate.format(F) !== moment(now).format(F)) {
          title = newDate.format(F2);
        } else {
          title = newDate.format(F3);
        }
        arr.push({
          title: `${title} (daqui ${this.minutesToHours(minutes)})`,
          value: minutes,
        });
        i++;
      }
      return arr;
    },
    presetsEnd() {
      if (!this.start) {
        return [
          {
            title: "Selecione o início primeiro",
            value: null,
          },
        ];
      }
      const now = moment();
      const referenceStart = moment(this.start);
      let arr = [];
      const F = "DD/MM/YYYY";
      const F2 = "DD/MM/YYYY HH:mm";
      const F3 = "HH:mm";
      const totalMinutes = referenceStart.diff(now, "minutes");
      let i = 1;
      while (i < totalMinutes + 30) {
        const minutes = 15 * i;
        const newDate = moment(referenceStart).add(minutes, "minutes");
        let title = "";
        if (newDate.format(F) !== moment(now).format(F)) {
          title = newDate.format(F2);
        } else {
          title = newDate.format(F3);
        }
        arr.push({
          title: `${title} (daqui ${this.minutesToHours(
            newDate.diff(moment(), "minutes")
          )})`,
          value: minutes,
        });
        i++;
      }
      return arr;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        this.init();
      }
    },
    presetSelectedStart(v) {
      if (v) {
        this.start = moment().add(v, "minutes").format(this.format);
      }
    },
    presetSelectedEnd(v) {
      if (v) {
        if (!this.start) return;
        this.end = moment(this.start).add(v, "minutes").format(this.format);
      }
    },
    searchDeliverymen() {
      this.debounceInput(this.getDeliverymens);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),

    init() {
      this.start = null;
      this.end = null;
      this.presetSelectedStart = null;
      this.presetSelectedEnd = null;
      this.coupon_copies = 1;
      this.deliverymen = null;
      this.getDeliverymens();
    },
    async getDeliverymens() {
      if (this.loadingDeliverymens) return;
      if (this.order?.shipment_method == 1) return;
      if (!this.dialog) return;
      this.loadingDeliverymens = true;
      const params = {
        deliverymen: 1,
        id: this.deliverymen_id,
        search: this.searchDeliverymen,
        include_shipping_count: 1,
      };
      await this.$http
        .get("/admin/users", { params })
        .then((response) => {
          this.loadingDeliverymens = false;
          this.deliverymens = response.data.items.data;
        })
        .catch((e) => {
          this.loadingDeliverymens = false;
          console.log(e);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message ?? "Tente novamente mais tarde",
            group: "app",
          });
        });
    },
    minutesToHours(minutes) {
      var hours = Math.floor(minutes / 60); // hours, integer
      var minutes = minutes % 60; // minutes left
      if (hours == 0) {
        return `${minutes}m`;
      }
      if (minutes == 0) {
        return `${hours}h`;
      }
      return `${hours}h${minutes}m`;
    },
    checkStartRule() {
      if (!this.start) {
        return "Campo Obrigatório";
      }
      const d1 = moment(this.start, this.format ?? null);
      if (this.end) {
        const d2 = moment(this.end, this.format ?? null);
        if (d1.isAfter(d2)) {
          return "Prev. Início posterior ao fim!";
        }
      }
      return true;
    },
    checkEndRule() {
      if (!this.end) {
        return "Campo Obrigatório";
      }
      const d2 = moment(this.end, this.format ?? null);
      if (d2.isBefore(moment(), "day")) {
        return "Previsão máxima no passado";
      }
      if (this.start) {
        const d1 = moment(this.start, this.format ?? null);
        if (d1.isAfter(d2)) {
          return "Prev. fim anterior ao início!";
        }
      }
      return true;
    },
    async acceptOrder(status) {
      if (!this.orderId) return;
      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        this.loading = true;
        const payload = {
          shipment_prevision_start: this.start,
          shipment_prevision_end: this.end,
          status: status,
          coupon_copies: this.coupon_copies > 5 ? 5 : this.coupon_copies,
          deliverymen_id: this.deliverymen_id,
        };

        await this.$http
          .put(`/admin/orders/${this.order?._id}/accept`, payload)
          .then((res) => {
            this.dialog = false;
            this.loading = false;
            this.start = null;
            this.end = null;
            this.$emit("reloadAll");
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e?.response?.data?.message ?? "Tente novamente mais tarde",
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
    },
  },
};
</script>

<style scoped>
.datepicker__content {
  display: flex;
  align-items: center;
}
.selected__style {
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
</style>