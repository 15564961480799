<template>
  <!-- v-progress-circular -->
  <v-progress-circular
    v-if="!sections || !parents"
    indeterminate
    color="primary"
  ></v-progress-circular>

  <v-form
    ref="formData"
    v-else
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <v-row>
      <v-col cols="12">
        <v-row class="ma-0 pa-0">
          <v-img
            :src="$imgurl(state.localData.img)"
            aspect-ratio="1"
            v-if="state.localData.img"
            style="height: 7rem"
          ></v-img>
        </v-row>
        <UploadComponent
          class="mt-2 mb-6"
          :limit="1"
          :data="state.localData"
          :field="'img'"
          :field_small="'img_small'"
          style="height: 100px"
          v-on:update_path="newUrlImage"
        ></UploadComponent>
        <v-select
          label="Seções"
          v-model="state.localData.section_ids"
          :items="sections"
          item-title="name"
          item-value="_id"
          variant="outlined"
          clearable
          :multiple="true"
          density="compact"
        ></v-select>
        <v-select
          label="Categoria mãe"
          v-model="state.localData.category_id"
          :items="parents"
          item-title="name"
          item-value="_id"
          variant="outlined"
          density="compact"
          clearable
        ></v-select>

        <v-text-field
          v-model="state.localData.name"
          label="Nome da categoria"
          variant="outlined"
          density="compact"
          :rules="[
            (v) => !!v || 'O nome da categoria é obrigatório',
            (v) =>
              (v && v.length <= 255) ||
              'O nome da categoria deve ter no máximo 255 caracteres',
          ]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="pb-0 mb-0">
        <v-checkbox
          v-model="state.localData.active"
          :true-value="1"
          :false-value="0"
          :label="`Categoria ${
            state.localData.active == 1 ? '' : 'in'
          }ativa. Clique para alterar`"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="mt-0 pt-0">
        <v-checkbox
          v-model="state.localData.flagged"
          :true-value="1"
          :false-value="0"
          :label="`Categoria ${
            state.localData.flagged == 1 ? 'Destacada' : 'Não destacada'
          }. Clique para alterar`"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="text" :to="{ name: 'categories.index' }">Cancelar</v-btn>
      <v-btn color="primary" type="submit" class="mr-4 my-2">
        Salvar &nbsp;
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    UploadComponent: defineAsyncComponent(() =>
      import("@/components/UploadComponent.vue")
    ),
  },

  watch: {
    "state.localData.section_ids": function (v) {
      if (!v || v.length == 0) {
        this.state.localData.category_id = null;
      } else {
        this.listParents(true);
      }
    },
  },

  data() {
    return {
      sections: null,
      parents: null,
      method: this.editItem?._id ? "put" : "post",
      title: this.editItem?._id ? "Editar seção" : "Nova seção",
      state: {
        loading: false,
        formModel: null,
        localData: this.editItem ?? {
          _id: null,
          name: null,
          category_id: null,
          active: 0,
          sections: null,
          section_ids: [],
          flagged: 0,
        },
        parents: [],
      },
    };
  },

  computed: {
    saveUrl() {
      const baseUrl = "/admin/categories";
      let url = null;
      if (this.state.localData.category_id) {
        url = this.state.localData?._id
          ? `${baseUrl}/${this.state.localData?.category_id}/subcategories/${this.state.localData?._id}`
          : `${baseUrl}/${this.state.localData?.category_id}/subcategories`;
      } else {
        url = this.state.localData?._id
          ? `${baseUrl}/${this.state.localData?._id}`
          : `${baseUrl}`;
      }
      return url;
    },
  },

  mounted() {
    this.listParents();
    this.listSections();
  },

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    async newUrlImage(imageUrl) {
      this.state.localData.img = imageUrl;
    },

    async save() {
      this.state.loading = true;

      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        let data = { ...this.state.localData };

        await this.$http[this.method](this.saveUrl, data)
          .then((r) => {
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Categoria salva com sucesso",
              group: "app",
            });

            this.$emit("close-dialog");
            this.$router.push({ name: "categories.index" });
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message,
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }

      this.state.loading = false;
    },

    async listParents(checkParentAfterSectionsIdsChange) {
      this.state.loading = true;

      let url = `/admin/categories/fathers`;
      if (this.state.localData._id) {
        url = `/admin/categories/fathers/${this.state.localData._id}`;
      }

      const params = {
        section_ids: this.state.localData.section_ids,
      };
      await this.$http
        .get(url, { params })
        .then((r) => {
          this.parents = r.data.data;
          if (
            checkParentAfterSectionsIdsChange &&
            this.state.localData.category_id
          ) {
            const index = this.parents.findIndex(
              (p) => p._id == this.state.localData.category_id
            );
            if (index == -1) {
              this.state.localData.category_id = null;
              this.$store.commit("showMessage", {
                type: "error",
                duration: 5000,
                text: "Não foi possível encontrar a categoria mãe que já estava selecionada na nova seleção de seções.",
                group: "app",
              });
            }
          }
        })
        .catch((e) => {
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message || e,
            group: "app",
          });
        });

      this.state.loading = false;
    },

    async listSections() {
      await this.$http
        .get("/admin/sections/list-all")
        .then((r) => {
          this.sections = r.data.data;
        })
        .catch((e) => {
          console.error("ERRO AO LISTAR SEÇÕES", e);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },
  },
};
</script>

<style>
</style>