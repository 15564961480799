
<template>
    <v-container>
        <v-card>
            <v-card-title>
                <div class="d-flex">
                    <div>
                        <span class="text-xl">Nova Notificação</span>
                    </div>
                </div>
            </v-card-title>
            <v-card-text>
              <ItemForm :editItem="editItem" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import ItemForm from "./Form.vue";
export default {
  name: "Index",
  components: {
    ItemForm,
  },
  data() {
    return {
      editItem: null,
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>