
<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title>
        <div class="d-flex justify-space-between align-center">
          <div>
            <span class="text-xl">Lista de Notificações</span>
          </div>

          <v-btn
            color="primary"
            :to="{ name: 'notifications.create' }"
            variant="elevated"
          >
            <v-icon>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </div>
      </v-card-title>
      <div class="d-flex justify-start mb-5 me-4">
        <v-text-field
          label="Procurar"
          v-model="search"
          :clearable="true"
        ></v-text-field>
      </div>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :itemsPerPage="items.length"
          class="elevation-1"
          return-object
        >
          <template v-slot:[`item.title`]="{ item }">
            {{ item.value.title }}
          </template>
          <template v-slot:[`item.body`]="{ item }">
            {{ item.value.body }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="multiline__item py-2">
              <v-chip :color="getStatusColor(item.value.status)" dark small>
                {{ getStatusText(item.value.status) }}
              </v-chip>
              <span v-if="item.value.scheduled_to">
                <small>
                  <small>Agendado para</small>
                </small>
              </span>
              <span v-if="item.value.scheduled_to">
                <small>
                  {{ $formatDate(item.value.scheduled_to, "N/A", true) }}
                </small>
              </span>
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{
              item.value.type == 1 ? "Múltiplas pessoas" : "Pessoa específica"
            }}
          </template>
          <template v-slot:expanded-row="{ columns, item }">
            <tr v-for="(it, i) in item.raw.errors" :key="`${i}`">
              <td :colspan="columns.length" v-if="it?.type == 0">
                Usuário: {{ it?.user?.name || "N/A" }}. Erro:
                {{ it.error || "N/A" }}
              </td>
              <td :colspan="columns.length" v-else-if="it?.type == 1">
                Erro: {{ it.error || "N/A" }}
              </td>
            </tr>
          </template>

          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import * as debounce from "lodash.debounce";
export default {
  name: "Index",
  data() {
    return {
      loading: false,
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
      items: [],
      headers: [
        {
          title: "Status",
          align: "start",
          sortable: false,
          key: "status",
        },
        {
          title: "Título",
          align: "start",
          sortable: false,
          key: "title",
        },
        {
          title: "Descrição",
          align: "start",
          sortable: false,
          key: "body",
        },
        {
          title: "Tipo de Envio",
          align: "start",
          sortable: false,
          key: "type",
        },
        { title: "", key: "data-table-expand" },
      ],
    };
  },

  watch: {
    "pagination.page": function () {
      if (this.loading) return;
      this.getList();
    },
    search() {
      this.debounceInput(this.prepareSearch);
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),
    getStatusText(s) {
      const statuses = {
        0: "Pendente Envio",
        1: "Enviando",
        2: "Enviado",
        3: "Enviado com erros",
        4: "Erro",
      };
      return statuses[s] || statuses[4];
    },
    getStatusColor(s) {
      const statuses = {
        0: "blue",
        1: "black",
        2: "green",
        3: "orange",
        4: "red",
      };
      return statuses[s] || statuses[4];
    },
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    async getList() {
      this.loading = false;
      const params = {
        search: this.search,
        ...this.pagination,
      };
      await this.$http
        .get("/admin/notifications", { params })
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.pagination.page = response?.data?.items?.current_page;
          this.pagination.pageCount = response?.data?.items?.last_page;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    async removeCoupon(id) {
      if (confirm("Deseja realmente remover este item?")) {
        try {
          await this.$http.delete(`/admin/coupons/${id}`);
          this.$store.commit("showMessage", {
            type: "success",
            duration: 5000,
            text: "Cupom removido com sucesso!",
            group: "app",
          });
          this.getList();
        } catch (error) {
          console.log(error);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: error.response.data.message,
            group: "app",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.multiline__item {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
</style>