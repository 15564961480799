<template>
  <Index
    :level="4"
    :createpathname="'users.storesectionmanager.create'"
    :editpathname="'users.storesectionmanager.edit'"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    Index: defineAsyncComponent(() => import("../000Base/Index.vue")),
  },
};
</script>

<style>
</style>