import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import store from "@/store";
import axios from "axios";
import { h, nextTick } from "vue";

async function getUser() {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("tk"),
    },
  });

  store.commit("setLoadingUser", true);
  await instance
    .get("/auth/me")
    .then((r) => {
      store.commit("setLoadingUser", false);
      store.commit("setUser", r?.data?.user);
    })
    .catch(() => {
      store.commit("setLoadingUser", false);
      localStorage.removeItem("tk");
      store.commit("setUser", null);
    });
}

const routes = [
  {
    path: "/",
    name: "Página Inicial",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      publicName: "Página Inicial",
    },
  },
  {
    path: "/auth",
    name: "Autenticação",
    component: () => import("@/views/Auth/AuthView.vue"),
    meta: {
      publicName: "Autenticação",
    },
  },
  {
    path: "/dashboard/:id?",
    name: "Dashboard",
    component: () => import("@/views/Dashboard/DashboardView.vue"),
    meta: {
      publicName: "Dashboard",
    },
  },
  {
    path: "/clube",
    name: "Clube",
    component: () => import("@/views/Clube/ClubeView.vue"),
    meta: {
      publicName: "Clube",
      levels: [1, 2, 3],
    },
  },

  // Rotas de Banners
  {
    path: "/banners",
    name: "Banners",
    component: () => import("@/views/Banners/BannersView.vue"),
    meta: {
      publicName: "Banners",
      levels: [1, 2],
    },
  },
  {
    path: "/banners/create",
    name: "banners.create",
    component: () => import("@/views/Banners/Create.vue"),
    meta: {
      publicName: "Criar Banner",
      levels: [1, 2],
    },
  },
  {
    path: "/banners/:id/edit",
    name: "banners.edit",
    component: () => import("@/views/Banners/Edit.vue"),
    meta: {
      publicName: "Editar Banner",
      levels: [1, 2],
    },
  },

  // Rotas de Jornal de Ofertas
  {
    path: "/news",
    name: "Jornal de Ofertas",
    component: () => import("@/views/JornalOfertas/JornalOfertasView.vue"),
    meta: {
      publicName: "Jornal de Ofertas",
      levels: [1, 2],
    },
  },
  {
    path: "/jornals/create",
    name: "jornals.create",
    component: () => import("@/views/JornalOfertas/Create.vue"),
    meta: {
      publicName: "Criar Jornal",
      levels: [1, 2],
    },
  },
  {
    path: "/jornals/:id/edit",
    name: "jornals.edit",
    component: () => import("@/views/JornalOfertas/Edit.vue"),
    meta: {
      publicName: "Editar Jornal",
      levels: [1, 2],
    },
  },

  // Rotas de pedidos
  {
    path: "/orders/:id?",
    name: "orders.index",
    component: () => import("@/views/Orders/Index.vue"),
    meta: {
      publicName: "Pedidos",
    },
  },
  {
    path: "/orders/create",
    name: "orders.create",
    component: () => import("@/views/Orders/Create.vue"),
    meta: {
      publicName: "Criar Pedido",
    },
  },
  {
    path: "/orders/:id/edit",
    name: "orders.edit",
    component: () => import("@/views/Orders/Edit.vue"),
    meta: {
      publicName: "Editar Pedido",
    },
  },

  // Rotas de Products
  {
    path: "/products",
    name: "products.index",
    component: () => import("@/views/Products/Index.vue"),
    meta: {
      publicName: "Produtos",
      levels: [1, 2],
    },
  },
  {
    path: "/products/create",
    name: "products.create",
    component: () => import("@/views/Products/Create.vue"),
    meta: {
      publicName: "Criar Produto",
      levels: [1, 2],
    },
  },
  {
    path: "/products/:id/edit",
    name: "products.edit",
    component: () => import("@/views/Products/Edit.vue"),
    meta: {
      publicName: "Editar Produto",
      levels: [1, 2],
    },
  },

  // Rotas de Stores
  {
    path: "/stores",
    name: "stores.index",
    component: () => import("@/views/Stores/Index.vue"),
    meta: {
      publicName: "Lojas",
      levels: [1, 2],
    },
  },
  {
    path: "/stores/create",
    name: "stores.create",
    component: () => import("@/views/Stores/Create.vue"),
    meta: {
      publicName: "Criar Loja",
      levels: [1, 2],
    },
  },
  {
    path: "/stores/:id/edit",
    name: "stores.edit",
    component: () => import("@/views/Stores/Edit.vue"),
    meta: {
      publicName: "Editar Loja",
      levels: [1, 2],
    },
  },
  {
    path: "/stores/:id/times",
    name: "stores.times",
    component: () => import("@/views/Stores/Times.vue"),
    meta: {
      publicName: "Editar Horários Loja",
      levels: [1, 2],
    },
  },

  // Rotas de Sections
  {
    path: "/sections",
    name: "sections.index",
    component: () => import("@/views/Sections/Index.vue"),
    meta: {
      publicName: "Seções",
      levels: [1],
    },
  },
  {
    path: "/sections/create",
    name: "sections.create",
    component: () => import("@/views/Sections/Create.vue"),
    meta: {
      publicName: "Criar Seção",
      levels: [1],
    },
  },
  {
    path: "/sections/:id/edit",
    name: "sections.edit",
    component: () => import("@/views/Sections/Edit.vue"),
    meta: {
      publicName: "Editar Seção",
      levels: [1],
    },
  },

  // Rotas de Categories
  {
    path: "/categories",
    name: "categories.index",
    component: () => import("@/views/Categories/Index.vue"),
    meta: {
      publicName: "Categorias",
      levels: [1, 2],
    },
  },
  {
    path: "/categories/create",
    name: "categories.create",
    component: () => import("@/views/Categories/Create.vue"),
    meta: {
      publicName: "Criar Categoria",
      levels: [1, 2],
    },
  },
  {
    path: "/categories/:id/edit",
    name: "categories.edit",
    component: () => import("@/views/Categories/Edit.vue"),
    meta: {
      publicName: "Editar Categoria",
      levels: [1, 2],
    },
  },

  // Rota de Movimentação de estoque
  {
    path: "/products/:id/stock/:stockId/edit",
    name: "stocks.edit",
    component: () => import("@/views/Products/EditStock.vue"),
    meta: {
      publicName: "Editar Estoque",
      levels: [1, 2],
    },
  },

  // Rota de Lista das movimentações de uma loja
  {
    path: "/products/:id/stock-movements/:stockId",
    name: "stocks.movements",
    component: () => import("@/views/Products/StockMovements.vue"),
    meta: {
      publicName: "Movimentação de Estoque",
      levels: [1, 2],
    },
  },

  // Rotas de variações
  {
    path: "/variations/:productId/create",
    name: "variations.create",
    component: () => import("@/views/Products/Variants/Create.vue"),
    meta: {
      publicName: "Criar Variação",
      levels: [1, 2],
    },
  },
  {
    path: "/variations/:productId/edit/:id",
    name: "variations.edit",
    component: () => import("@/views/Products/Variants/Edit.vue"),
    meta: {
      publicName: "Edtiar Variação",
      levels: [1, 2],
    },
  },

  // Rotas de itens de variações
  {
    path: "/variation-items/:productId/create/:variationId",
    name: "variation.items.create",
    component: () => import("@/views/Products/VariantItems/Create.vue"),
    meta: {
      publicName: "Criar Item da Variação",
      levels: [1, 2],
    },
  },
  {
    path: "/variation-items/:productId/edit/:variationId/:id",
    name: "variation.items.edit",
    component: () => import("@/views/Products/VariantItems/Edit.vue"),
    meta: {
      publicName: "Editar Item da Variação",
      levels: [1, 2],
    },
  },

  // Rotas de Entregadores
  {
    path: "/users",
    children: [
      {
        path: "deliverymen",
        children: [
          {
            path: "",
            name: "users.deliverymen.index",
            component: () => import("@/views/Persons/Deliverymen/Index.vue"),
            meta: {
              publicName: "Entregadores",
              levels: [1, 2, 3, 4, 5],
            },
          },
          {
            path: "create",
            name: "users.deliverymen.create",
            component: () => import("@/views/Persons/Deliverymen/Create.vue"),
            meta: {
              publicName: "Criar Entregador",
              levels: [1, 2, 3, 4, 5],
            },
          },
          {
            path: ":id/edit",
            name: "users.deliverymen.edit",
            component: () => import("@/views/Persons/Deliverymen/Edit.vue"),
            meta: {
              publicName: "Editar Entregador",
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
      },
      {
        path: "app",
        children: [
          {
            path: "",
            name: "users.usersapp.index",
            component: () => import("@/views/Persons/App/Index.vue"),
            meta: {
              publicName: "Usuários APP",
              levels: [1, 2],
            },
          },
          {
            path: "create",
            name: "users.usersapp.create",
            component: () => import("@/views/Persons/App/Create.vue"),
            meta: {
              publicName: "Criar Usuário APP",
              levels: [1, 2],
            },
          },
          {
            path: ":id/edit",
            name: "users.usersapp.edit",
            component: () => import("@/views/Persons/App/Edit.vue"),
            meta: {
              publicName: "Editar Usuário APP",
              levels: [1, 2],
            },
          },
        ],
      },
      {
        path: "admin",
        children: [
          {
            path: "",
            name: "users.admin.index",
            component: () => import("@/views/Persons/Admin/Index.vue"),
            meta: {
              publicName: "Administradores do Painel",
              levels: [1, 2],
            },
          },
          {
            path: "create",
            name: "users.admin.create",
            component: () => import("@/views/Persons/Admin/Create.vue"),
            meta: {
              publicName: "Criar Administrador Painel",
              levels: [1, 2],
            },
          },
          {
            path: ":id/edit",
            name: "users.admin.edit",
            component: () => import("@/views/Persons/Admin/Edit.vue"),
            meta: {
              publicName: "Editar Administrador Painel",
              levels: [1, 2],
            },
          },
        ],
      },
      {
        path: "storeadmin",
        children: [
          {
            path: "",
            name: "users.storeadmin.index",
            component: () => import("@/views/Persons/StoreAdmin/Index.vue"),
            meta: {
              publicName: "Gerentes de Loja",
              levels: [1, 2],
            },
          },
          {
            path: "create",
            name: "users.storeadmin.create",
            component: () => import("@/views/Persons/StoreAdmin/Create.vue"),
            meta: {
              publicName: "Criar Administrador de Loja",
              levels: [1, 2],
            },
          },
          {
            path: ":id/edit",
            name: "users.storeadmin.edit",
            component: () => import("@/views/Persons/StoreAdmin/Edit.vue"),
            meta: {
              publicName: "Editar Administrador de Loja",
              levels: [1, 2],
            },
          },
        ],
      },
      {
        path: "managers",
        children: [
          {
            path: "",
            name: "users.storesectionmanager.index",
            component: () =>
              import("@/views/Persons/StoreSectionManager/Index.vue"),
            meta: {
              publicName: "Gerentes de Seção",
              levels: [1, 2, 3],
            },
          },
          {
            path: "create",
            name: "users.storesectionmanager.create",
            component: () =>
              import("@/views/Persons/StoreSectionManager/Create.vue"),
            meta: {
              publicName: "Criar Gerente",
              levels: [1, 2, 3],
            },
          },
          {
            path: ":id/edit",
            name: "users.storesectionmanager.edit",
            component: () =>
              import("@/views/Persons/StoreSectionManager/Edit.vue"),
            meta: {
              publicName: "Editar Gerente",
              levels: [1, 2, 3],
            },
          },
        ],
      },
      {
        path: "employees",
        children: [
          {
            path: "",
            name: "users.storesectionemployee.index",
            component: () =>
              import("@/views/Persons/StoreSectionEmployee/Index.vue"),
            meta: {
              publicName: "Funcionários",
              levels: [1, 2, 3, 4],
            },
          },
          {
            path: "create",
            name: "users.storesectionemployee.create",
            component: () =>
              import("@/views/Persons/StoreSectionEmployee/Create.vue"),
            meta: {
              publicName: "Criar Funcionário",
              levels: [1, 2, 3, 4],
            },
          },
          {
            path: ":id/edit",
            name: "users.storesectionemployee.edit",
            component: () =>
              import("@/views/Persons/StoreSectionEmployee/Edit.vue"),
            meta: {
              publicName: "Editar Funcionário",
              levels: [1, 2, 3, 4],
            },
          },
        ],
      },
      {
        path: "superadmin",
        children: [
          {
            path: "",
            name: "users.superadmin.index",
            component: () => import("@/views/Persons/SuperAdmin/Index.vue"),
            meta: {
              publicName: "Super Administradores",
              levels: [1],
            },
          },
          {
            path: "create",
            name: "users.superadmin.create",
            component: () => import("@/views/Persons/SuperAdmin/Create.vue"),
            meta: {
              publicName: "Criar Super Administrador",
              levels: [1],
            },
          },
          {
            path: ":id/edit",
            name: "users.superadmin.edit",
            component: () => import("@/views/Persons/SuperAdmin/Edit.vue"),
            meta: {
              publicName: "Editar Super Administrador",
              levels: [1],
            },
          },
        ],
      },
    ],
  },

  // Rotas de Cupons
  {
    path: "/coupons",
    name: "coupons.index",
    component: () => import("@/views/Coupons/Index.vue"),
    meta: {
      publicName: "Cupons",
      levels: [1, 2],
    },
  },
  {
    path: "/coupons/create",
    name: "coupons.create",
    component: () => import("@/views/Coupons/Create.vue"),
    meta: {
      publicName: "Criar Cupom",
      levels: [1, 2],
    },
  },
  {
    path: "/coupons/:id/edit",
    name: "coupons.edit",
    component: () => import("@/views/Coupons/Edit.vue"),
    meta: {
      publicName: "Editar Cupom",
      levels: [1, 2],
    },
  },

  // Rotas de Pushs
  {
    path: "/notifications",
    name: "notifications.index",
    component: () => import("@/views/Notifications/Index.vue"),
    meta: {
      publicName: "Notificações",
      levels: [1, 2],
    },
  },
  {
    path: "/notifications/create",
    name: "notifications.create",
    component: () => import("@/views/Notifications/Create.vue"),
    meta: {
      publicName: "Criar Notificação",
      levels: [1, 2],
    },
  },
  {
    path: "/notifications/:id/edit",
    name: "notifications.edit",
    component: () => import("@/views/Notifications/Edit.vue"),
    meta: {
      publicName: "Editar Notificação",
      levels: [1, 2],
    },
  },

  // Rotas de App Help
  {
    path: "/apphelp",
    name: "apphelp.index",
    component: () => import("@/views/AppHelp/Index.vue"),
    meta: {
      publicName: "Ajuda APP",
      levels: [1, 2],
    },
  },
  {
    path: "/apphelp/create",
    name: "apphelp.create",
    component: () => import("@/views/AppHelp/Create.vue"),
    meta: {
      publicName: "Criar Ajuda APP",
      levels: [1, 2],
    },
  },
  {
    path: "/apphelp/:id/edit",
    name: "apphelp.edit",
    component: () => import("@/views/AppHelp/Edit.vue"),
    meta: {
      publicName: "Editar Ajuda APP",
      levels: [1, 2],
    },
  },

  // Rotas de Sobre APP
  {
    path: "/about/edit",
    name: "about.edit",
    component: () => import("@/views/About/Edit.vue"),
    meta: {
      publicName: "Sobre APP",
      levels: [1, 2],
    },
  },

  // Rotas de Política de Privacidade APP
  {
    path: "/privacypolicy/edit",
    name: "privacypolicy.edit",
    component: () => import("@/views/PrivacyPolicy/Edit.vue"),
    meta: {
      publicName: "Política de Privacidade APP",
      levels: [1, 2],
    },
  },

  // Rota de Membros do clube
  {
    path: "/club/members",
    name: "club.members.index",
    component: () => import("@/views/Clube/Members.vue"),
    meta: {
      publicName: "Membros Clube Takahashi",
      levels: [1, 2, 3],
    },
  },
  {
    path: "/club/members/:id",
    name: "club.members.show",
    component: () => import("@/views/Clube/MemberDetails.vue"),
    meta: {
      publicName: "Visualizar Membro Clube Takahashi",
      levels: [1, 2, 3],
    },
  },

  // Rotas de Impressoras
  {
    path: "/printers",
    name: "printers.index",
    component: () => import("@/views/Printers/Index.vue"),
    meta: {
      publicName: "Impressoras",
      levels: [1, 2, 3],
    },
  },
  {
    path: "/printers/create",
    name: "printers.create",
    component: () => import("@/views/Printers/Create.vue"),
    meta: {
      publicName: "Criar Impressora",
      levels: [1, 2, 3],
    },
  },
  {
    path: "/printers/:id/edit",
    name: "printers.edit",
    component: () => import("@/views/Printers/Edit.vue"),
    meta: {
      publicName: "Editar Impressora",
      levels: [1, 2, 3],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let user = null;
  if (to.meta.levels) {
    user = store.state.user;
    if (!user) {
      await getUser();
      user = store.state.user;
    }
    if (!user || !to.meta.levels?.includes(user?.level)) {
      alert("Acesso negado");
      return next(from.path);
    }
  }
  return next();
});

router.afterEach(async (to, from) => {
  nextTick(() => {
    let pageTitle = to?.meta?.publicName ?? "PAINEL";
    document.title = `Takahashi® | ${pageTitle}`;
  });
  const user = store.state.user;
  if (!user && to.path != "/auth") {
    await getUser();
    if (!store.state.user) {
      router.push("/auth");
    } /*else {
      router.push("/dashboard");
    }*/
  }
});

export default router;
