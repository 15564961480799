<template>
  <v-text-field
    v-if="value != null && id != null"
    v-model="newValue"
    :style="{
      maxWidth: '70px',
    }"
    variant="solo"
    density="compact"
    :hide-details="true"
    @blur="!newValue ? (newValue = value) : null"
    :loading="loading"
    :disabled="loading"
    @submit.capture.prevent.stop
  ></v-text-field>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  watch: {},
  data() {
    return {
      newValue: this.value,
      loading: false,
    };
  },
  watch: {
    newValue(v) {
      if (v != null && v != "" && v != this.value) {
        this.$debounce("ordereditor", this.updateOrder, 1500);
      }
    },
  },
  methods: {
    async updateOrder() {
      if (this.loading) return;
      const data = {
        custom_order: this.newValue,
        prevent_sync_sections: 1,
      };
      this.loading = true;
      await this.$http
        .put(`/admin/categories/${this.id}`, data)
        .then((r) => {
          this.loading = false;
          this.$emit("getList");
        })
        .catch((e) => {
          this.loading = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message ?? "Ocorreu um erro",
            group: "app",
          });
        });
    },
  },
};
</script>

<style>
</style>