<template>
  <v-container>
    <v-card
      width="100%"
      :loading="loading"
      :disabled="loading"
      v-if="loading || (!loading && items.length)"
    >
      <v-card-title> Horários por Seções </v-card-title>
      <v-card-text v-if="!loading">
        <v-alert type="info" prominent closable>Para permitir compra em qualquer horário, defina de 00:00h até 00:00h do mesmo dia</v-alert>
        <v-row class="ma-0" v-for="(item, i) in items" :key="`secion${i}`">
          <v-card width="100%">
            <v-card-title>
              {{ item?.section?.name ?? "Seção s/ nome" }}
            </v-card-title>
            <v-card-text>
              <v-row
                class="ma-0 mb-2"
                v-for="(itemTime, indexTime) in times"
                :key="`times${indexTime}`"
              >
                <v-text-field
                  v-model="items[i][itemTime.startField]"
                  :label="itemTime.startFieldName"
                  variant="outlined"
                  density="compact"
                  type="time"
                  hide-details=""
                ></v-text-field>
                <div class="mx-1"></div>
                <v-text-field
                  v-model="items[i][itemTime.endField]"
                  :label="`Até ${
                    items[i][itemTime.nextDayField]
                      ? itemTime.nextDayName
                      : itemTime.endFieldName
                  }`"
                  variant="outlined"
                  density="compact"
                  type="time"
                  hide-details=""
                ></v-text-field>
                <div class="mx-2"></div>
                <v-switch
                  v-model="items[i][itemTime.nextDayField]"
                  :label="itemTime.nextDayFieldName"
                  :true-value="1"
                  :false-value="0"
                  density="compact"
                  variant="outlined"
                  color="red"
                  hide-details=""
                ></v-switch>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="ma-0">
          <v-row class="ma-0 mb-2">
            <v-btn
              block
              color="blue darken-5"
              variant="elevated"
              @click="$router.go(-1)"
              >Voltar</v-btn
            >
          </v-row>
          <v-row class="ma-0">
            <v-btn
              :loading="loadingSave"
              block
              color="green darken-5"
              variant="elevated"
              @click="save()"
              >Salvar</v-btn
            >
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
    <v-alert v-else type="error" prominent>
      {{ loadingItemsError ?? "Erro ao carregar items" }}
    </v-alert>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loadingSave: false,
      loadingItemsError: null,
      items: [],
      times: [
        {
          startField: "monday_start",
          startFieldName: "Segunda - A partir de",
          endField: "monday_end",
          endFieldName: "Segunda",
          nextDayField: "monday_ends_on_next_day",
          nextDayName: "Terça",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
        {
          startField: "tuesday_start",
          startFieldName: "Terça - A partir de",
          endField: "tuesday_end",
          endFieldName: "Terça",
          nextDayField: "tuesday_ends_on_next_day",
          nextDayName: "Quarta",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
        {
          startField: "wednesday_start",
          startFieldName: "Quarta - A partir de",
          endField: "wednesday_end",
          endFieldName: "Quarta",
          nextDayField: "wednesday_ends_on_next_day",
          nextDayName: "Quinta",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
        {
          startField: "thursday_start",
          startFieldName: "Quinta - A partir de",
          endField: "thursday_end",
          endFieldName: "Quinta",
          nextDayField: "thursday_ends_on_next_day",
          nextDayName: "Sexta",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
        {
          startField: "friday_start",
          startFieldName: "Sexta - A partir de",
          endField: "friday_end",
          endFieldName: "Sexta",
          nextDayField: "friday_ends_on_next_day",
          nextDayName: "Sábado",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
        {
          startField: "saturday_start",
          startFieldName: "Sábado - A partir de",
          endField: "saturday_end",
          endFieldName: "Sábado",
          nextDayField: "saturday_ends_on_next_day",
          nextDayName: "Domingo",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
        {
          startField: "sunday_start",
          startFieldName: "Domingo - A partir de",
          endField: "sunday_end",
          endFieldName: "Domingo",
          nextDayField: "sunday_ends_on_next_day",
          nextDayName: "Segunda",
          nextDayFieldName: "Caso passe de meia-noite, clique aqui",
        },
      ],
    };
  },
  computed: {
    storeId() {
      return this.$route.params?.id;
    },
  },
  watch: {
    items: {
      deep: true,
      handler(n, o) {
        console.log("n, o", n, o);
      },
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      if (this.loading) return;
      if (!this.storeId) return;
      this.loading = true;
      this.loadingItemsError = null;
      await this.$http
        .get(`/admin/stores-sections-times/${this.storeId}`)
        .then((r) => {
          this.loading = false;
          this.items = r?.data?.items ?? [];
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
          this.loadingItemsError = error?.response?.data?.message ?? null;
        });
    },
    async save() {
      if (this.loadingSave) return;
      if (!this.storeId) return;
      this.loadingSave = true;
      const params = {
        items: this.items,
      };
      await this.$http
        .post(`/admin/stores-sections-times/${this.storeId}`, params)
        .then((r) => {
          this.loadingSave = false;
          this.get();
          this.$store.commit("showMessage", {
            type: "success",
            duration: 5000,
            text: "Salvo com sucesso!",
            group: "app",
          });
        })
        .catch((error) => {
          this.loadingSave = false;
          console.log({ error });
        });
    },
  },
};
</script>

<style>
</style>