<template>
  <v-form
    ref="formData"
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <!-- Create progress linear -->
    <v-progress-circular
      v-if="state.loading"
      indeterminate
      color="primary"
    ></v-progress-circular>

    <v-text-field
      v-model="state.localData.name"
      label="Nome da opção da variação"
      variant="outlined"
      :rules="[
        (v) => !!v || 'O nome da opção da variação é obrigatório',
        (v) =>
          (v && v.length <= 255) ||
          'O nome da opção da variação deve ter no máximo 255 caracteres',
      ]"
      density="compact"
    ></v-text-field>

    <v-textarea
      v-model="state.localData.description"
      label="Descrição da opção da variação (opcional, seja breve)"
      variant="outlined"
      rows="3"
      density="compact"
      :rules="[
        (v) => !!v || 'Campo obrigatório!',
      ]"
    ></v-textarea>

    <v-text-field
      v-model="state.localData.selection_limit"
      label="Quantidade máxima de seleção deste item"
      type="number"
      variant="outlined"
      density="compact"
      :disabled="variant?.type !== 0"
      v-show="variant?.type === 0"
    ></v-text-field>

    <currency-input
      v-model="state.localData.price"
      variant="outlined"
      density="compact"
      label="Preço"
    ></currency-input>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        variant="text"
        @click="
          () =>
            $router.push({
              name: 'products.edit',
              params: { id: productId },
              query: { tab: 'variants' },
            })
        "
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :disabled="state.loading"
        :loading="state.loading"
      >
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CurrencyInput: defineAsyncComponent(() =>
      import("@/components/CurrencyInput.vue")
    ),
  },
  data() {
    return {
      method: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/products/${this.$route.params.productId}/variations-sets/${this.$route.params.variationId}/items/${this.$route.params.id}`
        : `/admin/products/${this.$route.params.productId}/variations-sets/${this.$route.params.variationId}/items`,
      productId: null,
      variationId: null,
      state: {
        formModel: null,
        localData: {
          _id: null,
          product_variation_set_id: null,
          name: null,
          type: null,
          selection_limit: null,
          price: null,
        },
      },
    };
  },

  mounted() {
    this.productId = this.$route.params.productId;
    this.variationId = this.$route.params.variationId;
    this.state.localData.product_id = this.productId;
    this.setLocalData();
  },

  methods: {
    setLocalData() {
      if (this.editItem?._id) {
        this.state.localData = {
          ...this.editItem,
          product_id: this.productId,
          product_variation_set_id: this.variationId,
          id: this.editItem._id,
        };
      }
    },
    async save() {
      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        this.state.loading = true;
        await this.$http[this.method](this.saveUrl, this.state.localData)
          .then((res) => {
            this.state.loading = false;

            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Variação adicionada com sucesso",
              group: "app",
            });

            this.$router.push({
              name: "products.edit",
              params: { id: this.productId },
              query: { tab: "variants" },
            });
          })
          .catch((err) => {
            this.state.loading = false;

            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message,
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
    },
  },
};
</script>

<style>
</style>