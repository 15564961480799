<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-text>
        <FormCategory :editItem="editItem" v-if="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormCategory from './Form.vue';

export default {
  components: { FormCategory },
  data() {
    return {
      editItem: null,
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$http.get(`/admin/categories/${this.$route.params.id}`)
        .then(response => {
          this.editItem = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
}
</script>

<style>

</style>