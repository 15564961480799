<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-title>
        <h4 class="text-left">Edição de Produto</h4>
      </v-card-title>
      <v-card-text>
        <ProductForm
          :editItem="editItem"
          v-if="editItem"
          ref="productFormRef"
          @getProductData="getProductData($event)"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    ProductForm: defineAsyncComponent(() => import("./Form.vue")),
  },
  data() {
    return {
      id: null,
      editItem: null,
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getProductData();
  },

  methods: {
    async getProductData(onlyUpdateMedias) {
      await this.$http
        .get(`/admin/products/${this.id}`)
        .then((response) => {
          if (onlyUpdateMedias) {
            this.editItem.medias = response?.data?.data?.medias || [];
          } else {
            this.editItem = response?.data?.data ?? {};
          }
          // Não remova o setTimeout.
          // Por algum motivo, o vue não encontra esse ref neste momento, nem com o nextTick!
          setTimeout(() => {
            this.$refs.productFormRef?.setLocalData(onlyUpdateMedias);
          }, 1)
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>