<template>
  <v-container>
    <p>{{ dashboardId }}</p>
    <DashboardSection v-if="isSection && sectionId" :section="sectionId" />
    <DashboardClub v-if="isClub" />
  </v-container>
</template>

<script>
import DashboardSection from "@/components/Dashboards/DashboardSection.vue";
import DashboardClub from "@/components/Dashboards/DashboardClub.vue";

export default {
  components: {DashboardSection, DashboardClub},
  name: "DashboardView",
  data() {
    return {
      isSection: false,
      isClub: false,
      sectionId: null,
      dashboardId: null,
    };
  },

  watch: {
    $route() {
      this.getDashboard();
    }
  },

  mounted() {
    this.getDashboard();
  },

  methods: {
    getDashboard() {
      this.dashboardId = this.$route.params?.id;
      if (this.$route.params?.id == "clube") {
        this.isClub = true;
        this.sectionId = null;
      } else {
        this.isSection = true;
        this.sectionId = this.$route.params?.id;
      }
    }
  }
}
</script>

<style>
.indicator {
  width: 22%;
  height: 100px;
  box-shadow: aliceblue 0px 0px 40px 0px;
}
</style>