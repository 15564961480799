
<template>
  <v-form
    ref="formData"
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <v-row>
      <v-col>
        <h2 class="mb-2">Sobre o APP</h2>
        <editor
          v-model="state.localData.value"
          :api-key="tinyMceKey"
          :init="{
            ...tinyMceConfig,
            height: 500,
          }"
        />
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        variant="elevated"
        :loading="state.loading"
        type="submit"
      >
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "Index",

  components: {
    editor: Editor,
  },

  props: {
    editItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      tinyMceKey: "de687uwugp8rt6xptmqrz70xdqiujy4alfqd5dbauqguaw0h",
      tinyMceConfig: {
        height: 500,
        menubar: true,
        language: "pt_BR",
        height: 500,
        theme: "silver",
        convert_urls: false,
        statusbar: false,
        image_title: true,
        automatic_uploads: true,
        images_upload_handler: this.upload_handler,
        deprecation_warnings: false,
        images_upload_credentials: true,
        file_picker_types: "image",
        plugins: [
          "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help",
        ],
        toolbar:
          "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
      },
      saveMethod: "post",
      saveUrl: "/admin/about",
      state: {
        formModel: false,
        loading: false,
        localData: {
          value: null,
        },
      },
    };
  },
  watch: {},

  mounted() {
    if (this.editItem) {
      this.state.localData = { ...this.editItem };
    }
  },

  methods: {
    upload_handler(blobInfo, success, failure, progress) {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      this.$http
        .post("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (event) {
            const progressPercentage = Math.round(
              (event.loaded * 100) / event.total
            );
            progress(progressPercentage);
          },
        })
        .then((r) => {
          success(this.$imgurl(r?.data?.url));
        })
        .catch((error) => {
          failure(`Erro ao enviar a imagem. ${error}`);
        });
    },
    async save() {
      const valid = this.state.localData.value;
      if (valid) {
        let data = { ...this.state.localData };
        this.state.loading = true;
        this.$http[this.saveMethod](this.saveUrl, data)
          .then((response) => {
            this.state.loading = false;
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Sobre salva com sucesso",
              group: "app",
            });
          })
          .catch((error) => {
            this.state.loading = false;
            console.log(error);

            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: error.response.data.message,
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os campos corretamente",
          group: "app",
        });
      }
    },
  },
};
</script>