
<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div class="d-flex justify-space-between align-center">
          <div>
            <span class="text-xl">Lista de Cupons</span>
          </div>

          <v-btn
            color="primary"
            :to="{ name: 'coupons.create' }"
            variant="elevated"
          >
            <v-icon>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :itemsPerPage="items.length"
          class="elevation-1"
          return-object
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.value.name }}
            <br />
            <v-chip :color="item.value.active ? 'success' : 'error'" dark small>
              {{ item.value.active ? "Ativo" : "Inativo" }}
            </v-chip>
            <br />
            <span
              style="line-height: 1; display: block"
              v-if="item.value.created_at && !item.value.allowed_datetime_up_to"
            >
              <small
                >A partir de {{ $formatDate(item.value.created_at, "") }}</small
              >
            </span>
            <span
              style="line-height: 1; display: block"
              v-if="!item.value.created_at && item.value.allowed_datetime_up_to"
            >
              <small
                >Até
                {{ $formatDate(item.value.allowed_datetime_up_to, "") }}</small
              >
            </span>
            <span
              style="line-height: 1; display: block"
              v-if="item.value.created_at && item.value.allowed_datetime_up_to"
            >
              <small
                >De {{ $formatDate(item.value.created_at, "") }} até
                {{ $formatDate(item.value.allowed_datetime_up_to, "") }}</small
              >
            </span>
          </template>

          <template v-slot:[`item.discount`]="{ item }">
            <span v-if="item.value.discount_type == '0'"
              >{{ item.value.discount }}%</span
            >
            <span v-else>
              {{ $formatCurrency(item.value.discount) }}
            </span>
            <br />
            <span
              style="line-height: 1; display: block"
              v-if="item.value.usage_type == '0'"
            >
              <small>(Desconto valor total carrinho)</small>
            </span>
            <span
              style="line-height: 1; display: block"
              v-if="item.value.usage_type == '1'"
            >
              <small>(Desconto somente nos produtos)</small>
            </span>
            <span
              style="line-height: 1; display: block"
              v-if="item.value.usage_type == '2'"
            >
              <small>(Desconto no Frete)</small>
            </span>
          </template>

          <template v-slot:[`item.max_discount_value`]="{ item }">
            {{ $formatCurrency(item.value.max_discount_value) }}
          </template>

          <template v-slot:[`item.min_cart_value`]="{ item }">
            {{ $formatCurrency(item.value.min_cart_value) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <v-btn
                density="compact"
                variant="tonal"
                text
                @click="
                  () =>
                    $router.push({
                      name: 'coupons.edit',
                      params: { id: item.value._id },
                    })
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              &nbsp;&nbsp;
              <v-btn
                density="compact"
                variant="elevated"
                color="error"
                @click="() => removeCoupon(item.value._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      items: [],
      headers: [
        {
          title: "Código",
          align: "start",
          sortable: false,
          key: "code",
        },
        {
          title: "Nome",
          align: "start",
          sortable: false,
          key: "name",
        },
        {
          title: "Desconto",
          align: "start",
          sortable: false,
          key: "discount",
        },
        {
          title: "Desconto máximo",
          align: "start",
          sortable: false,
          key: "max_discount_value",
        },
        {
          title: "Valor mínimo do carrinho",
          align: "start",
          sortable: false,
          key: "min_cart_value",
        },
        {
          title: "Limite de uso",
          align: "start",
          sortable: false,
          key: "usage_limit",
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  mounted() {
    this.listCoupons();
  },

  methods: {
    async listCoupons() {
      try {
        const response = await this.$http.get("/admin/coupons");
        this.items = response.data.items.data;
      } catch (error) {
        console.log(error);
      }
    },

    async removeCoupon(id) {
      if (confirm("Deseja realmente remover este item?")) {
        try {
          await this.$http.delete(`/admin/coupons/${id}`);
          this.$store.commit("showMessage", {
            type: "success",
            duration: 5000,
            text: "Cupom removido com sucesso!",
            group: "app",
          });
          this.listCoupons();
        } catch (error) {
          console.log(error);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: error.response.data.message,
            group: "app",
          });
        }
      }
    },
  },
};
</script>