<template>
  <v-dialog width="95vw" max-width="575px" v-model="dialog" v-if="id">
    <template #activator="propsDialog">
      <v-tooltip position="bottom" text="Rastrear Entregador">
        <template #activator="propsTooltip">
          <v-btn
            v-bind="{
              ...propsDialog.props,
              ...propsTooltip.props,
              ...btnprops,
            }"
            icon
          >
            <v-icon color="black">mdi-map-marker</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card width="100%" :loading="loadingLastPosition">
      <v-card-title>
        Rastrear Entregador
        <v-btn
          icon
          size="x-small"
          class="ml-2"
          :loading="loadingLastPosition"
          @click="getLastPosition()"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-card-title>
      <v-alert
        type="error"
        :prominent="true"
        v-if="!loadingLastPosition && errMessage"
        >{{ errMessage }}</v-alert
      >
      <v-card-text>
        <v-responsive :aspect-ratio="16 / 9">
          <div
            id="map"
            ref="map"
            :style="{
              width: '100%',
              height: '100%',
            }"
          ></div>
        </v-responsive>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" color="primary" @click="dialog = false"
          >Fechar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    btnprops: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      dialog: false,
      map: null,
      marker: null,
      initialPosition: {
        latitude: -23.461951,
        longitude: -46.319822,
      },
      lastPosition: null,
      loadingLastPosition: false,
      errMessage: null,
    };
  },
  computed: {
    mapRef() {
      return this.$refs.map;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        this.init();
      }
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.$nextTick(() => {
        if (!this.dialog) return;
        if (!this.$refs.map) return;
        console.log("passed");
        this.getLastPosition();
        if (this.map) {
          this.map.eachLayer((layer) => {
            this.map.removeLayer(layer);
          });
          this.map.remove();
        }
        this.map = L.map("map").setView(
            [this.initialPosition.latitude, this.initialPosition.longitude],
            16
          );

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          maxZoom: 19,
        }).addTo(this.map);

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
          iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
          iconUrl: require("leaflet/dist/images/marker-icon.png"),
          shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        });
      });
    },
    async getLastPosition() {
      this.lastPosition = null;
      this.errMessage = null;
      this.loadingLastPosition = true;
      await this.$http
        .get(`/admin/users/deliverymen-last-position/${this.id}`)
        .then((r) => {
          this.loadingLastPosition = false;
          this.lastPosition = r?.data?.data ?? null;
          this.setMarkerFromLastPosition();
        })
        .catch((e) => {
          this.loadingLastPosition = false;
          this.errMessage =
            e?.response?.data?.message || "Ocorreu um erro, tente novamente!";
        });
    },
    setMarkerFromLastPosition() {
      if (!this.map) return;
      if (this.marker) {
        this.marker.remove();
      }
      if (this.lastPosition) {
        this.marker = L.marker([
          this.lastPosition.latitude,
          this.lastPosition.longitude,
        ]).addTo(this.map);
        const datetime = this.$formatDate(
          this.lastPosition.created_at,
          null,
          true
        );
        this.marker
          .bindPopup(`<b>Última atualização</b><br />${datetime}`)
          .openPopup();
        this.map.flyTo(
          [this.lastPosition.latitude, this.lastPosition.longitude],
          16
        );
      }
    },
  },
};
</script>

<style>
</style>