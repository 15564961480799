<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-title>
        <div class="text-left mb-4">
          <span class="text-h5">{{ pageTitle }}</span>
        </div>
      </v-card-title>
      <v-card-text>
        <DeliverymenForm
          :editItem="editItem"
          v-if="editItem"
          :level="level"
          :indexpathname="indexpathname"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DeliverymenForm from "./Form.vue";

export default {
  components: {
    DeliverymenForm,
  },
  name: "Index",
  props: {
    level: {
      type: Number,
      default: null,
    },
    indexpathname: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      id: null,
      editItem: null,
    };
  },
  computed: {
    pageTitle() {
      const titles = {
        0: "Editar Usuário APP",
        1: "Editar Super Administrador",
        2: "Editar Administrador do Painel",
        3: "Editar Administrador de Loja",
        4: "Editar Gerente",
        5: "Editar Funcionário",
        6: "Editar Entregador",
      };
      return titles[this.level ?? 0];
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getDeliverymenData();
  },
  methods: {
    async getDeliverymenData() {
      await this.$http
        .get(`/admin/users/${this.id}`)
        .then((response) => {
          this.editItem = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
</style>