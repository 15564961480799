<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4 class="text-left">Nova Categoria</h4>
      </v-card-title>
      <v-card-text>
        <FormCategory :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormCategory from './Form.vue';

export default {
components: { FormCategory },
  data() {
    return {
      isOpenDialog: false,
      editItem: null,
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>

<style>

</style>