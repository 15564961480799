<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
    v-if="![4, 5, 10].includes(order?.status)"
    max-height="90vh"
  >
    <template v-slot:activator="{ props }">
      <v-btn color="error" class="mr-2" variant="outlined" v-bind="props">
        Cancelar pedido
      </v-btn>
    </template>

    <v-card min-height="90vh" :loading="loading">
      <v-card-title>
        <span class="text-h5">Cancelar pedido</span>
      </v-card-title>
      <v-card-text class="abc123">
        <v-alert type="error" :prominent="true" icon="mdi-alert">
          Esta ação é irreversível. Tenha cautela.
        </v-alert>

        <v-form ref="formData">
          <v-textarea
            class="mt-4"
            v-model="motive"
            variant="outlined"
            label="Escreva um motivo"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-textarea>
        </v-form>

        <v-card-actions>
          <v-row class="ma-0">
            <v-col>
              <v-alert :prominent="true" type="warning" v-if="loading">
                <template #prepend>
                  <v-progress-circular
                    :indeterminate="true"
                    color="white"
                  ></v-progress-circular>
                </template>
                Aguarde enquanto salvamos os dados
              </v-alert>
              <v-btn
                v-if="!loading"
                color="error"
                variant="elevated"
                @click="cancelOrder()"
                block
                class="ma-0 mt-2"
              >
                Cancelar agora
              </v-btn>

              <v-btn
                v-if="!loading"
                color="primary"
                variant="outlined"
                @click="dialog = false"
                block
                class="ma-0 mt-2"
              >
                Desistir do cancelamento
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  props: ["order"],
  components: {},
  data() {
    return {
      dialog: false,
      motive: null,
      loading: false,
    };
  },
  computed: {
    orderId() {
      return this.order?._id;
    },
  },
  watch: {},
  methods: {
    async cancelOrder() {
      if (!this.orderId) return;
      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        this.loading = true;
        const payload = {
          motive: this.motive,
        };

        await this.$http
          .put(`/admin/orders/${this.order?._id}/cancel`, payload)
          .then((res) => {
            this.dialog = false;
            this.loading = false;
            this.start = null;
            this.end = null;
            this.$emit("reloadAll");
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e?.response?.data?.message ?? "Tente novamente mais tarde",
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
    },
  },
};
</script>
  
  <style scoped>
.datepicker__content {
  display: flex;
  align-items: center;
}
</style>