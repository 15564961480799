<template>
  <v-form
    ref="productData"
    v-model="state.formModel"
    @keypress.enter.prevent="
      state.localData?._id
        ? save()
        : save(async (id) => {
            await this.$router.replace({
              name: 'products.edit',
              params: { id: id },
              query: { tab: 'details' },
            });
          })
    "
    @submit.prevent="
      state.localData?._id
        ? save()
        : save(async (id) => {
            await this.$router.replace({
              name: 'products.edit',
              params: { id: id },
              query: { tab: 'details' },
            });
          })
    "
  >
    <div class="d-flex justify-space-between mb-4">
      <div>
        <v-btn
          color="primary"
          type="submit"
          class="mr-4 my-2"
          size="small"
          v-if="tab == 'details'"
        >
          Salvar &nbsp;
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn
          v-if="!state.localData?._id && tab == 'details'"
          color="white"
          class="mr-8 my-2"
          size="small"
          @click.prevent.stop.capture="save()"
        >
          Salvar e Voltar
        </v-btn>
      </div>

      <div>
        <!-- <v-btn color="white" class="mr-3 my-2" disabled="true" size="small">
          Apagar
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->

        <!-- <v-btn color="white" class="my-2" size="small">
          Duplicar
          <v-icon>mdi-content-copy</v-icon>
        </v-btn> -->
      </div>
    </div>

    <v-tabs v-model="tab" class="mb-8" color="error">
      <v-tab
        v-for="(item, index) in tabsFiltered"
        :key="`tab${index}`"
        :value="item['value']"
        @click.prevent.stop.capture="handleClick(item['value'])"
        :disabled="item['value'] == tab"
      >
        {{ item["text"] }}
      </v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="details">
        <v-row no-gutters>
          <v-col cols="6" class="pt-4">
            <v-text-field
              variant="outlined"
              v-model="state.localData.name"
              label="Nome do produto (obrigatório)"
              density="compact"
              :rules="[(v) => !!v || 'Campo Obrigatório!']"
            ></v-text-field>

            <v-select
              variant="outlined"
              v-model="state.localData.section_ids"
              label="Seções (obrigatório)"
              density="compact"
              :items="sections"
              :loading="loadingSections"
              :clearable="true"
              item-title="name"
              item-value="_id"
              multiple
              :rules="[(v) => !!v || 'Campo Obrigatório!']"
            ></v-select>

            <v-autocomplete
              variant="outlined"
              v-model="state.localData.category_ids"
              v-model:search="searchCategory"
              label="Categorias"
              density="compact"
              :items="categories"
              :loading="loadingCategories"
              :item-title="'name'"
              :item-value="'_id'"
              :clearable="true"
              :multiple="true"
            >
              <template #item="{ item }">
                <v-divider class="mb-1 mt-1"></v-divider>
                <v-list-item @click="toggleSelectCategory(item?.value)">
                  <template v-slot:prepend>
                    <v-checkbox-btn
                      v-if="
                        (state.localData?.category_ids ?? []).findIndex(
                          (cID) => cID == item?.value
                        ) > -1
                      "
                      :color="
                        (state.localData?.category_ids ?? []).findIndex(
                          (cID) => cID == item?.value
                        ) > -1
                          ? 'indigo-darken-4'
                          : undefined
                      "
                      :model-value="true"
                    ></v-checkbox-btn>
                    <v-checkbox-btn
                      v-else
                      :color="
                        (state.localData?.category_ids ?? []).findIndex(
                          (cID) => cID == item?.value
                        ) > -1
                          ? 'indigo-darken-4'
                          : undefined
                      "
                      :model-value="false"
                    ></v-checkbox-btn>
                  </template>
                  <CategoryChip
                    :category="categories.find((c) => c._id == item?.value)"
                    :listmode="true"
                  />
                </v-list-item>
              </template>
              <template #selection="{ item }">
                <CategoryChip
                  :category="categories.find((c) => c._id == item?.value)"
                />
              </template>
            </v-autocomplete>

            <v-select
              variant="outlined"
              v-model="state.localData.variation_enabled"
              label="Ativar Variações"
              density="compact"
              :items="variations_options"
              item-title="name"
              item-value="value"
            ></v-select>

            <v-select
              variant="outlined"
              v-model="state.localData.stock_type"
              label="Tipo de Estoque"
              density="compact"
              :items="stock_options"
              item-title="name"
              item-value="value"
            ></v-select>

            <v-select
              variant="outlined"
              v-model="state.localData.availability_control"
              label="Controle de Disponibilidade"
              density="compact"
              :items="availability_options"
              item-title="name"
              item-value="value"
            ></v-select>

            <v-select
              variant="outlined"
              v-model="state.localData.unity_type"
              label="Tipo de Unidade Referencial"
              density="compact"
              :items="unity_types"
              item-title="name"
              item-value="value"
            ></v-select>

            <currency-input
              v-model="state.localData.price"
              :label="
                state.localData.variation_enabled == 1 ? 'Preço base' : 'Preço'
              "
              variant="outlined"
              density="compact"
              :clearable="true"
              :rules="[(v) => !!v || 'Campo Obrigatório!']"
            ></currency-input>
            <currency-input
              v-model="state.localData.price_loyalty"
              :label="
                state.localData.variation_enabled == 1
                  ? 'Preço base Clube'
                  : 'Preço Clube'
              "
              variant="outlined"
              density="compact"
              :clearable="true"
              :rules="[(v) => !!v || 'Campo Obrigatório!']"
            ></currency-input>

            <v-text-field
              variant="outlined"
              v-model="state.localData.barcode"
              label="Código de barras"
              density="compact"
            ></v-text-field>

            <v-text-field
              variant="outlined"
              v-model="state.localData.code_pdv"
              label="Código no PDV"
              density="compact"
            ></v-text-field>

            <v-textarea
              variant="outlined"
              v-model="state.localData.description"
              label="Descrição do Produto"
              density="compact"
              rows="3"
            ></v-textarea>

            <v-switch
              :label="
                state.localData.age_restriction == 1
                  ? 'Restrito para +18. Clique para alterar'
                  : 'Não restrito para +18. Clique para alterar'
              "
              :color="state.localData.age_restriction == 1 ? 'primary' : 'grey'"
              v-model="state.localData.age_restriction"
              :true-value="1"
              :false-value="0"
            ></v-switch>
          </v-col>

          <v-col cols="1"></v-col>
          <v-col cols="5">
            <p class="text-left">
              <strong> Este produto pode ser vendido pela metade? </strong
              ><br />
            </p>
            <p class="text-left">
              O cliente precisará escolher outra metade para poder finalizar a
              compra. A outra metade de produto só poderá ser escolhida para
              produtos na
              <strong>mesma seção e categoria.</strong><br />
              Esta opção é útil para escolher o saber de metade da pizza, por
              exemplo.
              <br />
              <v-switch
                :label="
                  state.localData.allow_half_purchase == 1 ? 'Sim' : 'Não'
                "
                :color="
                  state.localData.allow_half_purchase == 1 ? 'primary' : 'grey'
                "
                v-model="state.localData.allow_half_purchase"
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </p>

            <v-text-field
              variant="outlined"
              v-model="state.localData.half_purchase_title"
              label="Título da opção"
              density="compact"
              :disabled="
                state.localData.allow_half_purchase == 1 ? false : true
              "
            ></v-text-field>

            <v-textarea
              variant="outlined"
              v-model="state.localData.half_purchase_description"
              label="Descrição"
              density="compact"
              rows="3"
              :disabled="
                state.localData.allow_half_purchase == 1 ? false : true
              "
            ></v-textarea>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item
        value="price_and_stock"
        v-if="
          tabsFiltered?.findIndex((t) => t.value == 'price_and_stock') != -1
        "
      >
        <!-- lista de estoque nas lojas -->
        <div class="text-center" v-if="!editItem || !editItem.stocks">
          <p class="text-center">Não há estoque no momento</p>
        </div>

        <v-table density="compact" v-else>
          <thead>
            <tr>
              <th class="text-left">Loja</th>
              <th class="text-center">Estoque atual</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="stock in editItem.stocks" :key="stock._id">
              <td class="text-left">{{ stock.store.name }}</td>
              <td class="text-center">{{ stock.current_stock_available }}</td>
              <td class="text-right">
                <v-btn
                  color="white"
                  class="mr-3 my-2"
                  size="small"
                  :to="{
                    name: 'stocks.movements',
                    params: { id: stock.product_id, stockId: stock._id },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  color="white"
                  class="mr-3 my-2"
                  size="small"
                  :to="{
                    name: 'stocks.edit',
                    params: { id: stock.product_id, stockId: stock._id },
                  }"
                >
                  Editar
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-window-item>

      <v-window-item value="variants">
        <v-expansion-panels
          v-if="state.localData.sets && state.localData.sets.length > 0"
        >
          <v-expansion-panel
            v-for="variation in state.localData.sets"
            :key="variation.id"
          >
            <v-expansion-panel-title>
              {{ variation.name }}
              <v-btn
                variant="text"
                size="small"
                class="ml-2"
                :to="{
                  name: 'variations.edit',
                  params: { productId: state.localData._id, id: variation._id },
                }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                variant="text"
                size="small"
                class="ml-2"
                color="red"
                :loading="loadingDeletesVariations[variation._id]"
                @click.stop.prevent.capture="deleteVariation(variation)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-table>
                <tbody>
                  <tr v-for="item in variation.items" :key="item.id">
                    <td class="text-left">{{ item.name }}</td>
                    <td class="text-right">
                      <span>
                        {{ $formatCurrency(item.price) }}
                      </span>
                      <v-btn
                        size="small"
                        variant="text"
                        :to="{
                          name: 'variation.items.edit',
                          params: {
                            productId: state.localData._id,
                            variationId: variation._id,
                            id: item._id,
                          },
                        }"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        variant="text"
                        size="small"
                        class="ml-2"
                        color="red"
                        :loading="loadingDeletesVariationsItems[item._id]"
                        @click.stop.prevent.capture="
                          deleteVariationItem(variation, item)
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-table>

              <div class="d-flex justify-space-between mt-4">
                <v-spacer></v-spacer>
                <v-btn
                  :to="{
                    name: 'variation.items.create',
                    params: {
                      productId: state.localData._id,
                      variationId: variation._id,
                    },
                  }"
                >
                  Adicionar
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <p class="text-center" v-else>
          <small>não há variações cadastradas até o momento</small>
        </p>

        <div class="d-flex justify-center mt-5 mb-4">
          <v-btn
            :to="{
              name: 'variations.create',
              params: { productId: state.localData._id },
            }"
          >
            Adicionar variação
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-window-item>

      <v-window-item value="photos">
        <p class="mb-2">Imagem do produto (obrigatória)</p>

        <div class="px-6">
          <UploadComponent
            class="mt-2 mb-6"
            :limit="5"
            :data="state.localData"
            :mini="state.localData.image || state.localData.image_small"
            :field="'image'"
            :field_small="'image_small'"
            style="height: 100px"
            v-on:update_path="recebeUrlImage"
          ></UploadComponent>
        </div>
        <!-- @update_path="$set(state.localData, 'image', $event)"
          @update_path_small="$set(state.localData, 'image_small', $event)" -->

        <p class="text-left px-5 mt-2 mb-5">
          Formatos aceitos: <strong>JPEG, JPG e PNG</strong><br />
          Peso máximo: <strong>20 MB</strong><br />
          Resolução mínima: <strong>300x275</strong>
        </p>

        <v-row
          v-if="state.localData.medias && state.localData.medias.length > 0"
        >
          <v-col
            cols="3"
            v-for="media in state.localData.medias"
            :key="media.url"
          >
            <img
              :src="$imgurl(media.url)"
              :alt="media.url"
              style="width: 100%; height: 200px; object-fit: cover"
            />
            <br />
            <v-col class="ma-0 pa-2">
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                size="small"
                @click="setAsCover(media._id)"
                v-if="media.product_cover_image != 1"
              >
                Definir como capa <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" size="small" @click="removeMedia(media.url)">
                Remover <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-col>
          </v-col>
        </v-row>

        <p v-else class="text-center">
          <small>não há mídias até o momento</small>
        </p>
      </v-window-item>

      <v-window-item value="availability">
        <AvailabilityControl :id="state.localData?._id" />
      </v-window-item>
    </v-window>
  </v-form>
</template>

<script>
import UploadComponent from "@/components/UploadComponent.vue";
import { computed, defineAsyncComponent } from "vue";
import * as debounce from "lodash.debounce";
export default {
  components: {
    UploadComponent,
    CurrencyInput: defineAsyncComponent(() =>
      import("@/components/CurrencyInput.vue")
    ),
    AvailabilityControl: defineAsyncComponent(() =>
      import("./AvailabilityControl.vue")
    ),
    CategoryChip: defineAsyncComponent(() =>
      import("./components/CategoryChip.vue")
    ),
  },

  props: {
    editItem: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      tab: "details",
      title: this.editItem?._id ? "Editar produto" : "Novo produto",
      method: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/products/${this.editItem._id}`
        : "/admin/products",
      loadingSections: false,
      sections: [],
      loadingCategories: false,
      searchCategory: null,
      categories: [],
      variations_options: [
        {
          name: "Sim",
          value: 1,
        },
        {
          name: "Não",
          value: 0,
        },
      ],
      availability_options: [
        {
          name: "Sim",
          value: 1,
        },
        {
          name: "Não",
          value: 0,
        },
      ],
      unity_types: [
        {
          name: "Unidade",
          value: 0,
        },
        {
          name: "Pacote",
          value: 1,
        },
        {
          name: "Kit",
          value: 2,
        },
        {
          name: "Combo",
          value: 3,
        },
        {
          name: "Caixa",
          value: 4,
        },
        {
          name: "Peça",
          value: 5,
        },
        {
          name: "Kilo",
          value: 6,
        },
        {
          name: "Litro",
          value: 7,
        },
      ],
      stock_options: [
        {
          name: "Controlado",
          value: 0,
        },
        {
          name: "Não Controlado",
          value: 1,
        },
      ],
      subcategories: [],

      state: {
        loading: false,
        formModel: null,
        localData: {
          _id: null,
          name: null,
          description: null,
          categories: null,
          category_ids: [],
          sections: null,
          section_ids: [],
          variation_enabled: null,
          availability_control: null,
          unity_type: null,
          stock_type: null,
          price: null,
          price_loyalty: null,
          stock: null,
          catalog: null,
          barcode: null,
          code_pdv: null,
          image: null,
          image_small: null,
          option_title: null,
          option_description: null,
          option_half: false,
          active: false,
          age_restriction: 0,
          medias: [],
        },
      },
      loadingDeletesVariations: [],
      loadingDeletesVariationsItems: [],
    };
  },

  computed: {
    tabs() {
      let tabs = [
        {
          value: "details",
          text: "Detalhes",
          show: true,
        },
        {
          value: "price_and_stock",
          text: "Estoque",
          show: this.state.localData.stock_type != 1,
        },
        {
          value: "variants",
          text: "Variações",
          show:
            this.state.localData?.variation_enabled || this.tab == "variants",
        },
        {
          value: "photos",
          text: "Fotos",
          show: true,
        },
        {
          value: "availability",
          text: "Disponibilidade",
          show:
            this.state.localData?.availability_control ||
            this.tab == "availability",
        },
      ];
      return tabs;
    },
    tabsFiltered() {
      return this.tabs.filter((t) => t.show);
    },
  },

  watch: {
    tab: function (v) {
      if (this.state?.localData?._id) {
        const action = !v || v == "details" ? "replace" : "push";
        this.$router[action]({
          name: "products.edit",
          params: { id: this.state?.localData?._id },
          query: { tab: v },
        });
      }
    },
    "$route.query.tab": function (v) {
      this.tab = v ? v : "details";
    },
    "state.localData.category_ids": function (v) {
      this.$debounce("category.ids.product.form", this.listCategories);
    },
  },

  mounted() {
    this.setLocalData();
    this.listSections();
    this.listCategories();
    this.setTabSelected();
  },

  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),
    toggleSelectCategory(id) {
      if (!id) return;
      const index = this.state?.localData?.category_ids?.findIndex(
        (cID) => cID == id
      );
      if (index == -1) {
        this.state?.localData?.category_ids?.push(id);
      } else {
        this.state?.localData?.category_ids?.splice(index, 1);
      }
    },
    async deleteVariation(v) {
      if (this.loadingDeletesVariations[v?._id]) return;
      if (window.confirm(`Deseja realmente excluir a variação ${v?.name}?`)) {
        this.loadingDeletesVariations[v?._id] = true;
        await this.$http
          .delete(
            `/admin/products/${this.state?.localData?._id}/variations-sets/${v?._id}`
          )
          .then((r) => {
            this.loadingDeletesVariations[v?._id] = false;
            this.$emit("getProductData", false);
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Variação excluída com sucesso",
              group: "app",
            });
          })
          .catch((e) => {
            this.loadingDeletesVariations[v?._id] = false;
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e?.response?.data?.message ?? "Erro ao excluir variação",
              group: "app",
            });
          });
      }
    },
    async deleteVariationItem(v, i) {
      if (this.loadingDeletesVariationsItems[i?._id]) return;
      if (
        window.confirm(
          `Deseja realmente excluir o item ${i?.name} da variação ${v?.name}?`
        )
      ) {
        this.loadingDeletesVariationsItems[i._id] = true;
        await this.$http
          .delete(
            `/admin/products/${this.state?.localData?._id}/variations-sets/${v?._id}/items/${i?._id}`
          )
          .then((r) => {
            this.loadingDeletesVariationsItems[i._id] = false;
            this.$emit("getProductData", false);
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Item da variação excluída com sucesso",
              group: "app",
            });
          })
          .catch((e) => {
            this.loadingDeletesVariationsItems[i._id] = false;
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text:
                e?.response?.data?.message ??
                "Erro ao excluir Item da variação",
              group: "app",
            });
          });
      }
    },
    async handleClick(value) {
      const i = this.tabs?.find((t) => t.value == value);
      if (!this.state.localData?._id) {
        if (
          window.confirm(
            `Antes de ir para ${i["text"]}, é necessário salvar o produto. Deseja continuar?`
          )
        ) {
          const validation = await this.$refs.productData?.validate();
          if (!validation.valid) {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: "Preencha todos os dados corretamente",
              group: "app",
            });
          } else {
            this.debounceInput(() => {
              this.save(async (id) => {
                await this.$router.replace({
                  name: "products.edit",
                  params: { id: id },
                  query: { tab: "details" },
                });
                this.$router.push({
                  name: "products.edit",
                  params: { id: id },
                  query: { tab: i["value"] },
                });
              });
            });
          }
        }
      } else {
        if (i.value != "details") {
          const validation = await this.$refs.productData?.validate();
          if (!validation.valid) {
            return this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: "Preencha todos os dados corretamente",
              group: "app",
            });
          } else {
            this.debounceInput(() => {
              this.save(async (id) => {});
            });
          }
        }
        this.tab = i.value;
      }
    },
    setLocalData(onlyUpdateMedias) {
      if (this.editItem?._id) {
        if (onlyUpdateMedias) {
          this.state.localData.medias = this.editItem.medias;
        } else {
          this.state.localData = {
            ...this.editItem,
          };
        }
      }
    },

    setTabSelected() {
      if (this.$route.query.tab) {
        const tab = this.tabs?.find((t) => t.value == this.$route.query.tab);
        if (tab) {
          this.tab = tab.value;
        }
      }
    },

    async save(successAction) {
      console.log("save called");
      this.state.loading = true;
      const validation = await this.$refs.productData?.validate();
      if (validation.valid) {
        let data = { ...this.state.localData };
        await this.$http[this.method](this.saveUrl, data)
          .then((r) => {
            if (successAction != null) {
              successAction(r?.data?.id);
            } else {
              this.$store.commit("showMessage", {
                type: "success",
                duration: 5000,
                text: "Produto salvo com sucesso",
                group: "app",
              });
              this.$router.push({ name: "products.index" });
            }
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text:
                e?.response?.data?.message ||
                e ||
                "Erro ao salvar. Verifique os dados informados",
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
      this.state.loading = false;
    },

    async listSections() {
      if (this.loadingSections) return;
      this.loadingSections = true;
      await this.$http
        .get("/admin/sections/list-all")
        .then((r) => {
          this.sections = r?.data?.data ?? [];
          this.loadingSections = false;
        })
        .catch((e) => {
          this.loadingSections = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },

    async listCategories() {
      if (this.loadingCategories) return;
      this.loadingCategories = true;
      const params = {
        search: this.searchCategory,
        ids: this.state?.localData?.category_ids ?? [],
      };
      console.log("list categories called with params ", params, this.editItem);
      await this.$http
        .get("/admin/categories/list", { params })
        .then((r) => {
          this.categories = r?.data?.data ?? [];
          console.log("categories then ", this.categories);
          this.loadingCategories = false;
        })
        .catch((e) => {
          this.loadingCategories = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message,
            group: "app",
          });
        });
    },

    async listSubcategories(categoryId) {
      await this.$http
        .get(`/admin/categories/children/${categoryId}`)
        .then((r) => {
          this.subcategories = r.data.data;
        })
        .catch((e) => {
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },

    async recebeUrlImage(imageUrl) {
      console.log("Recebido evento do component de upload", imageUrl);

      if (!this.state.localData._id) {
        this.state.localData.medias.push({
          url: imageUrl,
          type: "image",
        });
        return;
      }

      const res = await this.$http.post(
        `/admin/products/${this.state.localData._id}/medias/add`,
        {
          url: imageUrl,
          type: "image",
        }
      );

      if (res.data.status == "success") {
        this.state.localData.medias.push({
          _id: res.data.id,
          url: imageUrl,
          type: "image",
        });

        console.log("Imagens/Medias", this.state.localData.medias);
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Erro ao salvar mídia, por favor tente novamente.",
          group: "app",
        });
      }
    },

    async setAsCover(id) {
      const res = await this.$http.put(
        `/admin/products/${this.state.localData._id}/medias/${id}`,
        {
          product_cover_image: 1,
        }
      );
      console.log("Retorno da API", res);
      if (res.data.data == true) {
        this.$emit("getProductData", true);
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Erro ao alterar mídia, por favor tente novamente.",
          group: "app",
        });
      }
    },

    async removeMedia(url) {
      if (window.confirm("Deseja realmente excluir esta foto?")) {
        const index = this.state.localData.medias.findIndex(
          (m) => m.url === url
        );

        if (this.state.localData.medias[index]._id) {
          const res = await this.$http.delete(
            `/admin/products/${this.state.localData._id}/medias/${this.state.localData.medias[index]._id}`
          );
          console.log("Retorno da API", res);
          if (res.data.data == true) {
            this.state.localData.medias.splice(index, 1);
          } else {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: "Erro ao remover mídia, por favor tente novamente.",
              group: "app",
            });
          }
        } else this.state.localData.medias.splice(index, 1);
      }
    },
  },
};
</script>

<style>
</style>