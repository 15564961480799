<template>
  <v-container>
    <v-card>
        <v-card-title>
            <h4 class="text-left">Nova variação</h4>
        </v-card-title>
        <v-card-text>
          <VariantForm :editItem="editItem" />
        </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VariantForm from './Form.vue';

export default {
	components: { VariantForm },
	data() {
		return {
			productId: null,
			editItem: {
				_id: null,
				product_id: null,
				name: null,
				description: null,
				selection_minimum: null,
				selection_maximum: null
			},
		};
	},

	mounted() {
		this.productId = this.$route.params.id;
		this.editItem.product_id = this.productId;
	},

	methods: {
		
	},
};
</script>

<style>

</style>