<template>
  <v-dialog
    v-model="dialog"
    :persistent="loadingDefineDriver"
    width="500"
    v-if="order"
  >
    <template v-slot:activator="{ props }">
      <v-btn color="primary" class="mr-2" variant="elevated" v-bind="props">
        {{ titleAction }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Selecione um entregador</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Pesquisar"
          append-inner-icon="mdi-search"
          variant="outlined"
          density="compact"
          :clearable="true"
          v-model="search"
        ></v-text-field>
        <!-- Lista de entregadores -->
        <div class="tableDrivers">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
            :server-items-length="items.length"
            :itemsPerPage="items.length"
            item-value="_id"
            @click:row="clickRowDeliverymen"
          >
            <template v-slot:[`item.check`]="{ item }">
              <v-checkbox
                v-model="selected"
                :value="item.raw._id"
                :hide-details="true"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div class="multiline__item">
                <span>{{ item.raw.name }}</span>
                <span>
                  <small>{{
                    `Entregas: ${
                      item?.raw?.deliverymen_current_shipping_count ?? 0
                    } em andamento e ${
                      item?.raw?.deliverymen_pending_shipping_count ?? 0
                    } p/ próximas viagens`
                  }}</small>
                </span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <DeliverymenTracking
                :id="item.raw._id"
                :btnprops="{
                  density: 'compact',
                  class: {
                    'mr-2': true,
                  },
                }"
              />
            </template>
            <template v-slot:bottom></template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pagination.pageCount"
              :total-visible="10"
            ></v-pagination>
          </div>
        </div>

        <v-card-actions>
          <v-btn
            color="primary"
            variant="outlined"
            @click="loadingDefineDriver ? null : (dialog = false)"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="elevated"
            @click="defineDriver"
            :loading="loadingDefineDriver"
          >
            {{ titleAction }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as debounce from "lodash.debounce";
import { defineAsyncComponent } from "vue";
export default {
  props: ["order"],
  components: {
    DeliverymenTracking: defineAsyncComponent(() =>
      import("@/components/DeliverymenTracking/DeliverymenTracking.vue")
    ),
  },
  data() {
    return {
      selected: null,
      dialog: false,
      search: null,
      loading: false,
      pagination: {
        pageCount: null,
        page: null,
      },
      headers: [
        {
          title: "",
          key: "check",
          sortable: false,
          align: "start",
        },
        {
          title: "Nome",
          key: "name",
          sortable: false,
          align: "start",
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "start",
        },
      ],
      items: [],
      loadingDefineDriver: false,
    };
  },
  computed: {
    selectedDriver() {
      return this.order?.current_shipment?.user_id;
    },
    titleAction() {
      return this.selectedDriver ? "Alterar Entregador" : "Definir Entregador";
    },
  },
  watch: {
    "pagination.page": function () {
      if (this.loading) return;
      this.getList();
    },
    search() {
      this.debounceInputDeliverymen(this.prepareSearch);
    },
    dialog(v) {
      if (v) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    debounceInputDeliverymen: debounce((functionRef) => {
      functionRef();
    }, 650),
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    clickRowDeliverymen(_, v) {
      if (this.selected == v?.item?.value) {
        this.selected = null;
      } else {
        this.selected = v?.item?.value;
      }
    },
    init() {
      if (this.selectedDriver) {
        this.selected = this.selectedDriver;
      } else {
        this.selected = null;
      }
      this.getList();
    },
    async getList() {
      if (!this.order?._id) return;
      if (!this.dialog) return;
      if (this.loading) return;

      this.loading = true;

      const params = {
        search: this.search,
        ...this.pagination,
        id: this.selected,
        deliverymen: 1,
        include_shipping_count: 1,
      };

      await this.$http
        .get("/admin/users", { params })
        .then((res) => {
          this.loading = false;
          this.items = res.data.items.data;
          this.pagination.page = res?.data?.items?.current_page;
          this.pagination.pageCount = res?.data?.items?.last_page;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message ?? "Erro ao listar entregadores",
            group: "app",
          });
          console.log(e);
        });
    },
    async defineDriver() {
      if (!this.order?._id) return;
      if (!this.selected) return;
      if (this.loadingDefineDriver) return;
      this.loadingDefineDriver = true;

      await this.$http
        .put(
          `/admin/orders/${this.order?._id}/define-deliverymen/${this.selected}`
        )
        .then((res) => {
          this.$emit("updateParent");
          this.loadingDefineDriver = false;
          this.dialog = false;

          this.selected = null;
        })
        .catch((e) => {
          this.loadingDefineDriver = false;
          console.log(e);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message ?? "Erro ao definir entregador",
            group: "app",
          });
        });
    },
  },
};
</script>

<style scoped>
.tableDrivers {
  height: 300px;
  overflow-y: auto;
}
.multiline__item {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
</style>