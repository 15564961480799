import { createStore } from "vuex";

export default createStore({
  state: {
    theme: localStorage.getItem("t") ?? "light",
    loadingUser: false,
    user: null,
    notificationData: null,
    mobile: false,
    isRailNavigationDrawer: true,
    orderId: null,
    updateOrders: false,
  },
  getters: {},
  mutations: {
    changeTheme(state) {
      const v = state.theme == "light" ? "dark" : "light";
      state.theme = v;
      localStorage.setItem("t", v);
    },
    setLoadingUser(state, payload) {
      state.loadingUser = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    showMessage(state, payload) {
      state.notificationData = payload;
    },
    setMobile(state, payload) {
      state.mobile = payload;
    },
    setIsRailNavigationDrawer(state, payload) {
      state.isRailNavigationDrawer = payload;
    },
    setOrderId(state, payload) {
      state.orderId = payload;
    },
    setUpdateOrders(state, payload) {
      state.updateOrders = payload;
      console.log("updateOrders", state.updateOrders);
    },
  },
  actions: {},
  modules: {},
});
