<template>
  <v-form
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="state.localData.name"
          label="Nome da seção"
          variant="outlined"
          :rules="[
            (v) => !!v || 'O nome da seção é obrigatório',
            (v) =>
              (v && v.length <= 255) ||
              'O nome da seção deve ter no máximo 255 caracteres',
          ]"
        ></v-text-field>
      </v-col>

      <v-row class="ma-0">
        <v-col cols="6">
          <h2 class="mb-1">Icone maior Home</h2>
          <a
            :href="$imgurl(state.localData.img_full ?? 'utils/noimage.png')"
            target="_blank"
          >
            <v-img
              style="background-color: white"
              :src="$imgurl(state.localData.img_full ?? 'utils/noimage.png')"
              :aspect-ratio="1"
              width="50%"
              class="mx-auto"
            ></v-img>
            <UploadComponent
            class="mt-3 mb-2"
            :limit="1"
            :data="state.localData"
            :mini="state.localData.img_full"
            :field="'img_full'"
            :field_small="'img_full_mini'"
            style="height: 100px"
            v-on:update_path="($event) => newUrlImage('img_full', $event)"
          ></UploadComponent>
          </a>
        </v-col>
        <v-col cols="6">
          <h2 class="mb-1">Icone menu inferior</h2>
          <a
            :href="$imgurl(state.localData.img_icon_app ?? 'utils/noimage.png')"
            target="_blank"
          >
            <v-img
              style="background-color: white"
              :src="
                $imgurl(state.localData.img_icon_app ?? 'utils/noimage.png')
              "
              :aspect-ratio="1"
              width="50%"
              class="mx-auto"
            ></v-img>
          </a>
          <UploadComponent
            class="mt-3 mb-2"
            :limit="1"
            :data="state.localData"
            :mini="state.localData.img_icon_app"
            :field="'img_icon_app'"
            :field_small="'img_icon_app_mini'"
            style="height: 100px"
            v-on:update_path="($event) => newUrlImage('img_icon_app', $event)"
          ></UploadComponent>
        </v-col>
      </v-row>

      <v-col cols="12">
        <v-checkbox
          v-model="state.localData.active"
          :true-value="1"
          :false-value="0"
          label="Seção ativa"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="text" :to="{ name: 'sections.index' }">Cancelar</v-btn>
      <v-btn color="primary" type="submit" class="mr-4 my-2">
        Salvar &nbsp;
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    UploadComponent: defineAsyncComponent(() =>
      import("@/components/UploadComponent.vue")
    ),
  },

  data() {
    return {
      method: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/sections/${this.editItem._id}`
        : "/admin/sections",
      title: this.editItem?._id ? "Editar seção" : "Nova seção",
      state: {
        loading: false,
        formModel: null,
        localData: this.editItem ?? {
          _id: null,
          name: "",
          active: false,
        },
      },
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    async newUrlImage(field, imageUrl) {
      this.state.localData[field] = imageUrl;
    },

    async save() {
      this.state.loading = true;

      let data = { ...this.state.localData };

      await this.$http[this.method](this.saveUrl, data)
        .then((r) => {
          this.$store.commit("showMessage", {
            type: "success",
            duration: 5000,
            text: "Seção salva com sucesso",
            group: "app",
          });

          this.$emit("close-dialog");
          this.$router.push({ name: "sections.index" });
        })
        .catch((e) => {
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
      this.state.loading = false;
    },
  },
};
</script>

<style>
</style>