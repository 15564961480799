<template>
  <v-container>
    <v-card>
      <v-progressive-linear
        v-if="!items"
        indeterminate
        color="primary"
      ></v-progressive-linear>

      <v-card-title>
        <div class="d-flex justify-space-between align-center">
          <h4 class="text-left">Histórico de movimentação do estoque</h4>

          <v-btn
            v-if="productId"
            variant="text"
            class="mb-2"
            size="small"
            :to="{
              name: 'products.edit',
              params: { id: productId },
              query: { tab: 'price_and_stock' },
            }"
          >
            voltar
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="items"
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :itemsPerPage="items.length"
          return-object
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <div class="text-left">
              {{
                Intl.DateTimeFormat("pt-br").format(
                  new Date(item.value.created_at)
                )
              }}
            </div>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <div class="text-left">
              {{ item.value.type == "0" ? "Saída" : "Entrada" }}
            </div>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      productId: null,
      stockId: null,
      items: null,
      headers: [
        {
          title: "Data",
          key: "created_at",
          sortable: true,
          align: "start",
        },
        {
          title: "Tipo de movimentação",
          key: "type",
          sortable: true,
          align: "start",
        },
        {
          title: "Quantidade",
          key: "amount",
          sortable: true,
          align: "center",
        },
      ],
    };
  },

  mounted() {
    this.productId = this.$route.params.id;
    this.stockId = this.$route.params.stockId;

    console.log("params", this.$route.params);
    console.log("productId", this.productId);
    console.log("stockId", this.stockId);

    this.listMovimentations();
  },

  methods: {
    async listMovimentations() {
      await this.$http
        .get(
          `/admin/products/${this.productId}/stocks/${this.stockId}/stocks-movements`
        )
        .then((res) => {
          this.items = res.data.items.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>