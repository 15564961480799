<template>
  <v-chip
    v-if="category"
    :class="{
      chip__content: true,
      subchip: chiplevel > 0 || listmode,
    }"
    :style="{
      background: chiplevel > 0 || listmode ? 'transparent' : '#80808073',
      height: 'max-content',
      paddingTop: '.5rem',
      paddingBottom: chiplevel > 0 ? '0' : '.5rem',
      borderRadius: '7px',
    }"
  >
    <span
      v-if="chiplevel == 0"
      :style="{
        lineHeight: 1,
        textAlign: 'left',
      }"
    >
      <span v-html="'&#x2022;'"></span> {{ category?.name ?? "S/ Nome" }}
      <br />
      <CategoryChip
        :category="category?.level_up_category_recursive"
        :chiplevel="chiplevel + 1"
        :listmode="listmode"
      />
    </span>
    <span
      v-else
      :style="{
        lineHeight: 1,
      }"
    >
      <span v-html="spacer + '&#8627;'"></span>
      <small>
        Mãe: {{ category?.name ?? "S/ Nome" }}
      </small>
      <br />
      <CategoryChip
        :category="category?.level_up_category_recursive"
        :chiplevel="chiplevel + 1"
        :listmode="listmode"
      />
    </span>
  </v-chip>
</template>

<script>
export default {
  name: "CategoryChip",
  props: {
    category: {
      type: Object,
      default: null,
    },
    chiplevel: {
      type: Number,
      default: 0,
    },
    listmode: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    spacer() {
      return " ".repeat(this.chiplevel);
    },
  },
};
</script>

<style scoped>
.chip__content.subchip .v-chip__underlay,
.chip__content.subchip >>> .v-chip__underlay {
  display: none;
}
</style>