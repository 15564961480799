<template>
  <v-form
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
    ref="formData"
  >
    <v-select
      v-model="state.localData.active"
      label="Status"
      :rules="[(v) => v != null || 'Campo obrigatório']"
      :items="statuses"
      item-title="title"
      item-value="value"
      variant="outlined"
      density="compact"
    ></v-select>
    <v-text-field
      v-model="state.localData.name"
      label="Nome"
      :rules="[
        (v) => !!v || 'Nome é obrigatório',
        (v) =>
          (v && v.length <= 100) || 'Nome deve ter no máximo 100 caracteres',
      ]"
      variant="outlined"
      density="compact"
    >
    </v-text-field>

    <v-text-field
      v-model="state.localData.phone"
      class="append__no__margin"
      label="Telefone"
      :rules="[
        (v) => !!v || 'Telefone é obrigatório',
        (v) =>
          (v && v.length <= 20) || 'Telefone deve ter no máximo 20 caracteres',
      ]"
      variant="outlined"
      density="compact"
    >
      <template #append>
        <input
          v-maska
          :data-maska="phoneMask"
          v-model="state.localData.phone"
          :style="{
            width: '1px',
            height: '1px',
            opacity: 0,
            userSelect: 'none',
          }"
        />
      </template>
    </v-text-field>

    <v-text-field
      v-model="state.localData.email"
      label="E-mail"
      :rules="[
        (v) => !!v || 'E-mail é obrigatório',
        (v) =>
          (v && v.length <= 255) || 'E-mail deve ter no máximo 255 caracteres',
        (v) => /.+@.+\..+/.test(v) || 'E-mail inválido',
      ]"
      variant="outlined"
      density="compact"
    ></v-text-field>

    <v-autocomplete
      v-if="![0, 1, 2].includes(level)"
      v-model="state.localData.store_id"
      :label="storeLabel"
      :chips="true"
      :closable-chips="true"
      :clearable="true"
      :items="stores"
      item-title="name"
      item-value="_id"
      v-model:search="searchStore"
      no-data-text="Nenhum resultado por enquanto"
      :loading="loadingStores"
      :rules="[rulesStoreAndSection]"
      variant="outlined"
      density="compact"
    ></v-autocomplete>

    <v-autocomplete
      v-if="[4, 5, 6].includes(level)"
      v-model="state.localData.section_id"
      :label="sectionLabel"
      :chips="true"
      :closable-chips="true"
      :clearable="true"
      :items="sections"
      item-title="name"
      item-value="_id"
      v-model:search="searchSection"
      no-data-text="Nenhum resultado por enquanto"
      :loading="loadingSections"
      :rules="[rulesStoreAndSection]"
      variant="outlined"
      density="compact"
    ></v-autocomplete>

    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Senha"
          variant="outlined"
          density="compact"
          type="password"
          :clearable="true"
          v-model="state.localData.password"
          :rules="[rulesPassword]"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Confirmar Senha"
          variant="outlined"
          density="compact"
          type="password"
          :clearable="true"
          v-model="state.localData.password_confirmation"
          :rules="[rulesPasswordConfirmation]"
        ></v-text-field>
      </v-col>
    </v-row>
    <p v-if="editItem">
      <small
        >* para alterar a senha, basta preencher os campos</small
      >
    </p>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" variant="text" :to="{ name: indexpathname }">
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        variant="elevated"
        :loading="state.loading"
        type="submit"
      >
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { vMaska } from "maska";
import { defineAsyncComponent } from "vue";

export default {
  directives: {
    maska: vMaska,
  },
  props: {
    level: {
      type: Number,
      default: null,
    },
    indexpathname: {
      type: String,
      default: null,
    },
    editItem: {
      type: Object,
      default: null,
    },
  },
  name: "Form",
  data() {
    return {
      saveMethod: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/users/${this.$route.params.id}`
        : "/admin/users",
      state: {
        formModel: false,
        loading: false,
        localData: {
          _id: null,
          name: null,
          phone: null,
          email: null,
          password: null,
          password_confirmation: null,
          store_id: null,
          section_id: null,
        },
      },
      stores: [],
      searchStore: null,
      loadingStores: false,
      sections: [],
      searchSection: null,
      loadingSections: false,
      statuses: [
        { title: "Ativo", value: 1 },
        { title: "Inativo", value: 0 },
      ],
    };
  },

  computed: {
    phoneMask() {
      const v = this.state?.localData?.phone;
      const p1 = "(##) ####-#####";
      const p2 = "(##) #####-####";
      if (!v) return p1;
      const getNumbersAsString = (str) => (str.match(/\d+/g) || []).join("");
      if (getNumbersAsString(v).length > 10) return p2;
      return p1;
    },
    storeLabel() {
      const l = this.level;
      if (l == 6) return "Loja (opcional)";
      return "Loja";
    },
    sectionLabel() {
      const l = this.level;
      if (l == 6) return "Seção (opcional)";
      return "Seção";
    },
  },

  watch: {
    searchStore() {
      this.$debounce("searchStore", this.getStores);
    },
    searchSections() {
      this.$debounce("searchSections", this.getSections);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.editItem) {
        this.state.localData = { ...this.editItem };
      }
      this.getStores();
      this.getSections();
    },
    rulesStoreAndSection(v) {
      const l = this.level;
      if ([0, 1, 2, 6].includes(l)) return true;
      if ([3, 4, 5].includes(l) && !v) {
        return "Campo obrigatório!";
      }
      return true;
    },
    rulesPassword(v) {
      if (!this.state?.localData?._id) {
        this.$debounce(
          "rulesPasswordForm000Base",
          this.$refs.formData?.validate
        );
        if (!v) {
          return "Campo obrigatório!";
        }
        if (v.length < 6) {
          return "Senha muito curta!";
        }
      } else if (v) {
        if (v.length < 6) {
          return "Senha muito curta!";
        }
      }
      return true;
    },
    rulesPasswordConfirmation(v) {
      this.$debounce(
        "rulesPasswordConfirmationForm000Base",
        this.$refs.formData?.validate
      );
      if (!this.state?.localData?.password) {
        if (this.state?.localData?.password_confirmation) {
          this.state.localData.password_confirmation = "";
        }
        return true;
      }
      if (v != this.state?.localData?.password) {
        return "Senhas não conferem!";
      }
      if (v.length < 6) {
        return "Senha muito curta!";
      }

      return true;
    },
    async save() {
      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        this.state.loading = true;

        const data = { ...this.state.localData, level: this.level };

        console.log(
          "dados para enviar",
          { data },
          this.saveMethod,
          this.saveUrl
        );

        if (!data.password) {
          delete data.password;
          delete data.password_confirmation;
        }

        for (let key in data) {
          if (!["store_id", "section_id"].includes(key)) {
            if (data[key] === null) {
              delete data[key];
            }
          }
        }

        await this.$http[this.saveMethod](this.saveUrl, data)
          .then((response) => {
            this.state.loading = false;
            console.log({ response });
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Entregador salvo com sucesso",
              group: "app",
            });
            this.$router.push({ name: this.indexpathname });
          })
          .catch((e) => {
            this.state.loading = false;
            console.log(e);
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text:
                e?.response?.data?.message ??
                "Ocorreu um erro, tente novamente mais tarde!",
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
    },
    async getSections() {
      if (![4, 5, 6].includes(this.level)) return;
      if (this.loadingSections) return;
      this.loadingSections = true;
      const params = {
        id: this?.state?.localData?.section_id,
        search: this.searchSection,
      };
      await this.$http
        .get("/admin/sections", { params })
        .then((r) => {
          console.log({ r });
          this.loadingSections = false;
          this.sections = r?.data?.items?.data ?? [];
        })
        .catch((e) => {
          console.log({ e });
          this.loadingSections = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ??
              "Ocorreu um erro, tente novamente mais tarde!",
            group: "app",
          });
        });
    },
    async getStores() {
      if ([0, 1, 2].includes(this.level)) return;
      if (this.loadingStores) return;
      this.loadingStores = true;
      const params = {
        id: this?.state?.localData?.store_id,
        search: this.searchStore,
      };
      await this.$http
        .get("/admin/stores", { params })
        .then((r) => {
          this.loadingStores = false;
          this.stores = r?.data?.items?.data ?? [];
        })
        .catch((e) => {
          console.log({ e });
          this.loadingStores = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ??
              "Ocorreu um erro, tente novamente mais tarde!",
            group: "app",
          });
        });
    },
  },
};
</script>

<style scoped>
</style>