<template>
  <div v-if="show && user" class="content__user">
    <p>
      <strong>Usuário: {{ user?.name ?? '--' }}</strong>
    </p>
    <p class="level__name">{{ level }}</p>
  </div>
</template>
  
  <script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  props: ["show"],
  setup() {
    const store = useStore();

    const computedData = {
      user: computed(() => {
        return store.state.user;
      }),
      level: computed(() => {
        const levels = {
          0: "Usuário APP",
          1: "Super Administrador",
          2: "Administrador",
          3: "Gerente Loja",
          4: "Gerente Seção",
          5: "Funcionário Seção",
          6: "Motoboy",
        }
        return levels[computedData?.user?.value?.level ?? 0];
      }),
    };
    return {
      ...computedData,
    };
  },
};
</script>
  
  <style scoped>
  .content__user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.5rem;
  }
  .content__user p {
    line-height: 1;
    margin: unset;
    font-size: .8rem;
  }
  .content__user p.level__name {
    font-size: .7rem;
  }
</style>