<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4 class="text-left">Nova Loja</h4>
      </v-card-title>
      <v-card-text>
        <StoreForm :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import StoreForm from "./Form.vue";

export default {
  name: "Create",
  components: {
    StoreForm,
  },
  data() {
    return {
      editItem: {
        name: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        description: "",
        image: "",
        phone_number: "",
      },
    };
  },

  methods: {},
};
</script>

<style>
</style>