<template>
  <v-container
    :class="`pa-0 ${!isSelected && !isLast ? 'border-b' : ''}`"
    style="cursor: pointer"
  >
    <v-card
      v-ripple
      elevation="0"
      class="py-2 px-2"
      :color="isSelected ? 'blue' : null"
    >
      <div style="position: relative">
        <v-row class="ma-0 pa-0" align="center">
          <v-col class="pa-0 ma-0">
            <p class="text-left" style="display: flex; align-items: center">
              <strong>Pedido #{{ orderNumber }}</strong>
              <ShipmentMethodVisual
                class="ml-1"
                :value="order.shipment_method"
              />
            </p>
            <p class="text-left">
              Feito às
              <strong>{{ $formatDate(order.created_at, "N/A", true) }}</strong>
            </p>
            <p class="text-left">
              Previsão
              {{ order.shipment_method == 1 ? "retirada" : "entrega" }}:
              <strong>
                {{
                  order.shipment_prevision_start && order.shipment_prevision_end
                    ? "Entre"
                    : ""
                }}
                {{ $formatDate(order.shipment_prevision_start, "-", true)
                }}{{
                  order.shipment_prevision_start && order.shipment_prevision_end
                    ? " até "
                    : ""
                }}{{
                  $formatDate(order.shipment_prevision_end, "-", true)
                }}</strong
              >
            </p>
          </v-col>
          <v-chip
            :color="color"
            variant="elevated"
            size="small"
            style="width: 1rem; height: 1.3rem"
          >
          </v-chip>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "OrderCard",
  props: {
    order: Object,
    color: String,
    isLast: Boolean,
  },

  components: {
    ShipmentMethodVisual: defineAsyncComponent(() =>
      import("./components/ShipmentMethodVisual.vue")
    ),
  },

  data() {
    return {};
  },

  computed: {
    orderNumber() {
      let n = this.order?.number || null;
      if (n) {
        return `${n}`.padStart(4, "0");
      }
      return this.order?._id;
    },
    statusTitle() {
      const index = this.$orderStatuses.findIndex(
        (status) => status.key === this.order?.status
      );
      return this.$orderStatuses[index].title;
    },
    isSelected() {
      return this.order?._id == this.$store.state?.orderId;
    },
  },

  mounted() {},

  methods: {},
};
</script>