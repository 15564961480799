<template>
  <v-container>
    <v-card>
      <!-- progress linear -->
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title>
        <h4 class="text-left">Editando variação</h4>
      </v-card-title>
      <v-card-text>
        <VariantForm :editItem="editItem" v-if="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VariantForm from "./Form.vue";

export default {
  components: { VariantForm },
  data() {
    return {
      productId: null,
      variationId: null,
      editItem: null,
    };
  },

  mounted() {
    this.productId = this.$route.params.productId;
    this.variationId = this.$route.params.id;

    this.setEditItem();
  },

  methods: {
    async setEditItem() {
      await this.$http
        .get(
          `/admin/products/${this.productId}/variations-sets/${this.variationId}`
        )
        .then((res) => {
          this.editItem = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>