
<template>
  <v-form
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="state.localData.code"
          label="Código"
          :rules="[
            (v) => !!v || 'Código é obrigatório',
            (v) =>
              (v && v.length <= 50) ||
              'Código deve ter no máximo 50 caracteres',
          ]"
          variant="outlined"
          density="compact"
        ></v-text-field>

        <v-text-field
          v-model="state.localData.name"
          label="Nome"
          :rules="[
            (v) => !!v || 'Nome é obrigatório',
            (v) =>
              (v && v.length <= 100) ||
              'Nome deve ter no máximo 100 caracteres',
          ]"
          variant="outlined"
          density="compact"
        ></v-text-field>

        <v-select
          v-model="state.localData.type"
          label="Tipo de cupom"
          :rules="[(v) => v != null || 'Campo obrigatório']"
          variant="outlined"
          density="compact"
          :items="coupon_types"
          item-title="text"
          item-value="value"
        ></v-select>

        <v-text-field
          v-model="state.localData.document"
          label="CPF para uso do Cupom"
          variant="outlined"
          density="compact"
          v-if="state.localData.type == 1"
        ></v-text-field>

        <v-select
          v-model="state.localData.discount_type"
          label="Tipo de desconto"
          variant="outlined"
          density="compact"
          :items="discount_types"
          item-title="text"
          item-value="value"
        ></v-select>

        <CurrencyInput
          v-model="state.localData.discount"
          label="Valor do desconto"
          variant="outlined"
          density="compact"
          type="number"
          step="0.01"
          min="0"
          v-if="state.localData.discount_type == 1"
        ></CurrencyInput>

        <v-text-field
          v-model="state.localData.discount"
          label="Valor de desconto"
          variant="outlined"
          density="compact"
          type="number"
          v-else
        ></v-text-field>

        <v-select
          v-model="state.localData.usage_type"
          label="Tipo de uso"
          variant="outlined"
          density="compact"
          :items="usage_types"
          :item-title="`text`"
          :item-value="`value`"
        ></v-select>
      </v-col>

      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="state.localData.allowed_datetime_from"
              label="Data de início"
              variant="outlined"
              density="compact"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="state.localData.allowed_datetime_up_to"
              label="Data de término"
              variant="outlined"
              density="compact"
              type="date"
            ></v-text-field>
          </v-col>
        </v-row>

        <CurrencyInput
          v-model="state.localData.max_discount_value"
          label="Desconto máximo"
          variant="outlined"
          density="compact"
        ></CurrencyInput>

        <CurrencyInput
          v-model="state.localData.min_cart_value"
          label="Valor mínimo do carrinho"
          variant="outlined"
          density="compact"
        ></CurrencyInput>
        <v-text-field
          v-model="state.localData.usage_limit"
          label="Limite de uso"
          variant="outlined"
          density="compact"
          type="number"
        ></v-text-field>
        <v-autocomplete
          v-model="state.localData.section_ids"
          :label="'Seção'"
          :chips="true"
          :closable-chips="true"
          :clearable="true"
          :multiple="true"
          :items="sections"
          item-title="name"
          item-value="_id"
          v-model:search="searchSection"
          no-data-text="Nenhum resultado por enquanto"
          :loading="loadingSections"
          variant="outlined"
          density="compact"
          :rules="[(v) => !!v || 'Campo obrigatório!']"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <UploadComponent
          class="mb-6"
          :limit="1"
          :data="state.localData"
          :mini="state.localData.image || state.localData.image_small"
          :field="'image'"
          :field_small="'image_small'"
          style="height: 60px"
          v-on:update_path="recebeUrlImage"
        ></UploadComponent>

        <img
          v-if="state.localData.image"
          :src="$imgurl(state.localData.image)"
          :alt="state.localData.image"
          style="
            width: 100%;
            height: 200px;
            object-fit: cover;
            object-position: center;
          "
        />
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" variant="text" :to="{ name: 'coupons.index' }">
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        variant="elevated"
        :loading="state.loading"
        type="submit"
      >
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import UploadComponent from "@/components/UploadComponent.vue";
import { defineAsyncComponent } from "vue";
export default {
  name: "Index",

  components: {
    UploadComponent,
    CurrencyInput: defineAsyncComponent(() =>
      import("@/components/CurrencyInput.vue")
    ),
  },

  props: {
    editItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      discount_types: [
        { text: "Porcentagem", value: 0 },
        { text: "Valor", value: 1 },
      ],
      usage_types: [
        { text: "Desconto valor total carrinho", value: 0 },
        { text: "Desconto somente em produtos", value: 1 },
        { text: "Desconto no frete", value: 2 },
      ],
      coupon_types: [
        { text: "Geral", value: 0 },
        { text: "Para um CPF específico", value: 1 },
      ],
      saveMethod: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/coupons/${this.$route.params.id}`
        : "/admin/coupons",
      state: {
        formModel: false,
        loading: false,
        localData: {
          _id: null,
          code: null,
          name: null,
          active: null,
          discount_type: null,
          usage_type: null,
          max_discount_value: null,
          min_cart_value: null,
          usage_limit: null,
          allowed_datetime_from: null,
          allowed_datetime_up_to: null,
          type: null,
          document: null,
          value: null,
          image: null,
          section_ids: [],
        },
      },
      sections: [],
      searchSection: null,
      loadingSections: false,
    };
  },

  watch: {
    searchSections() {
      this.$debounce("searchSections", this.getSections);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.editItem) {
        this.state.localData = { ...this.editItem };
        this.state.localData.active = this.state.localData.active ? 1 : 0;
        this.state.localData.discount_type = this.state.localData.discount_type
          ? 1
          : 0;
        this.state.localData.type = this.state.localData.type ? 1 : 0;
        this.state.localData.usage_type =
          Number.parseInt(this.state.localData.usage_type) || 0;
      }
      this.getSections();
    },
    async recebeUrlImage(imageUrl) {
      this.state.localData.image = imageUrl;
    },

    async getSections() {
      if (this.loadingSections) return;
      this.loadingSections = true;
      const params = {
        ids: this?.state?.localData?.section_ids,
        search: this.searchSection,
      };
      await this.$http
        .get("/admin/sections", { params })
        .then((r) => {
          console.log({ r });
          this.loadingSections = false;
          this.sections = r?.data?.items?.data ?? [];
        })
        .catch((e) => {
          console.log({ e });
          this.loadingSections = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ??
              "Ocorreu um erro, tente novamente mais tarde!",
            group: "app",
          });
        });
    },

    async save() {
      if (this.state.formModel) {
        let data = { ...this.state.localData };
        this.state.loading = true;
        this.$http[this.saveMethod](this.saveUrl, data)
          .then((response) => {
            this.state.loading = false;
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Cupom salvo com sucesso",
              group: "app",
            });
            this.$router.push({ name: "coupons.index" });
          })
          .catch((error) => {
            this.state.loading = false;
            console.log(error);

            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: error.response.data.message,
              group: "app",
            });
          });
      }
    },
  },
};
</script>