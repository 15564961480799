<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4 class="text-left">Nova Impressora</h4>
      </v-card-title>
      <v-card-text>
        <ItemForm :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "Create",
  components: {
    ItemForm: defineAsyncComponent(() => import("./Form.vue")),
  },
  data() {
    return {
      editItem: {
        active: 1,
        _id: null,
        name: null,
        manufacturer: null,
        host: null,
        port: null,
        width: null,
        font_type: null,
        characters_font_1: null,
        characters_font_2: null,
        characters_font_3: null,
      },
    };
  },

  methods: {},
};
</script>

<style>
</style>