<template>
  <v-card>
    <v-card-title>
      Controle de Disponibilidade
      <v-btn
        icon
        class="ml-2"
        :loading="state.loadingAvailability"
        @click="getAvailabilityData()"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-form ref="availabilityForm">
        <v-row
          v-for="(item, index) in state.days"
          :key="`availability${index}`"
          class="ma-0 pa-0"
        >
          <v-col cols="12" :class="state.availabilityData[`day${index + 1}_enabled`] ? `ma-0 pa-0 mb-4` : `ma-0 pa-0`">
            <v-row class="ma-0">
              <v-switch
                v-model="state.availabilityData[`day${index + 1}_enabled`]"
                :label="
                  state.availabilityData[`day${index + 1}_enabled`]
                    ? `${item} ativo`
                    : `Ativar ${item}`
                "
                :true-value="1"
                :false-value="0"
                density="compact"
                variant="outlined"
                color="red"
                hide-details=""
              ></v-switch>
              <v-text-field
                v-if="state.availabilityData[`day${index + 1}_enabled`]"
                v-model="state.availabilityData[`day${index + 1}_start`]"
                :label="`${item} - A partir de`"
                variant="outlined"
                density="compact"
                type="time"
                hide-details=""
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-text-field
                v-if="state.availabilityData[`day${index + 1}_enabled`]"
                v-model="state.availabilityData[`day${index + 1}_end`]"
                :label="`${item} - Até`"
                variant="outlined"
                density="compact"
                type="time"
                hide-details=""
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        block
        color="primary"
        class="mt-2"
        :loading="state.loadingSetAvailability"
        @click.stop.capture="setAvailabilityData()"
        >Salvar</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
import { reactive } from "vue";
import { self } from "@/main";
import { useStore } from "vuex";
export default {
  props: ["id"],
  setup(props) {
    const state = reactive({
      loadingAvailability: false,
      loadingSetAvailability: false,
      availabilityData: reactive({}),
      days: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      booleans: [
        {
          name: "Sim",
          value: 1,
        },
        {
          name: "Não",
          value: 0,
        },
      ],
    });
    const store = useStore();

    async function getAvailabilityData() {
      if (state.loadingAvailability) return;
      state.loadingAvailability = true;
      await self()
        .$http.get(`/admin/products/${props.id}/availability`)
        .then((r) => {
          console.log("getAvailabilityData", { r });
          state.loadingAvailability = false;
          state.availabilityData = r?.data?.data || {};
        })
        .catch((e) => {
          state.loadingAvailability = false;
          store.commit("showMessage", {
            type: "error",
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
      state.loadingAvailability = false;
    }
    async function setAvailabilityData() {
      console.log("state.availabilityData", {...state.availabilityData})
      if (state.loadingSetAvailability) return;
      state.loadingSetAvailability = true;
      await self()
        .$http.post(`/admin/products/${props.id}/availability`, state.availabilityData)
        .then((r) => {
          state.loadingSetAvailability = false;
          store.commit("showMessage", {
            type: "success",
            duation: 5000,
            text: "Salvo com sucesso",
            group: "app",
          });
        })
        .catch((e) => {
          state.loadingSetAvailability = false;
          store.commit("showMessage", {
            type: "error",
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
      state.loadingSetAvailability = false;
    }
    getAvailabilityData();
    return {
      state,
      getAvailabilityData,
      setAvailabilityData,
    };
  },
};
</script>

<style>
</style>