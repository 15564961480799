<template>
  <v-container>
    <div class="d-flex justify-space-between mb-5">
      <v-btn color="primary" class="mb-2" :to="{ name: 'stores.create' }">
        Adicionar Loja
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-start mb-5 me-4">
      <v-text-field
        label="Procurar"
        v-model="search"
        :clearable="true"
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="stores"
      :server-items-length="stores.length"
      :itemsPerPage="stores.length"
      return-object
      :loading="loading"
      loading-text="Carregando"
      no-data-text="Nada aqui por enquanto"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          variant="tonal"
          size="small"
          class="mr-2"
          :to="{ name: 'stores.times', params: { id: item.value._id } }"
        >
          <v-icon class="mr-1">mdi-clock-outline</v-icon>
          Horários
        </v-btn>
        <v-btn
          variant="tonal"
          size="small"
          class="mr-2"
          :to="{ name: 'stores.edit', params: { id: item.value._id } }"
        >
          <v-icon class="mr-1">mdi-pencil</v-icon>
          Editar
        </v-btn>
      </template>
      <template v-slot:bottom></template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.pageCount"
        :total-visible="10"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    FormStore: defineAsyncComponent(() => import("./Form.vue")),
  },
  data() {
    return {
      loading: false,
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
      headers: [
        {
          title: "Nome",
          key: "name",
          sortable: true,
          align: "start",
        },
        {
          title: "CEP",
          key: "zipcode",
          sortable: false,
        },
        {
          title: "Cidade",
          key: "city",
          sortable: false,
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
        },
      ],
      store: null,
      stores: [],
    };
  },

  watch: {
    "pagination.page": function () {
      if (this.loading) return;
      this.getList();
    },
    search() {
      this.$debounce("storeslist", this.prepareSearch);
    },
  },

  mounted() {
    this.getList();
  },
  methods: {
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    async getList() {
      if (this.loading) return;
      try {
        this.loading = true;
        const params = {
          club_fields: 1,
          club_members: 1,
          search: this.search,
          ...this.pagination,
        };
        await this.$http
          .get("/admin/stores", { params })
          .then((r) => {
            this.loading = false;
            this.stores = r?.data?.items?.data ?? [];
            this.pagination.page = r?.data?.items?.current_page;
            this.pagination.pageCount = r?.data?.items?.last_page;
          })
          .catch((e) => {
            this.loading = false;
            this.$store.commit("showMessage", {
              type: "error",
              duation: 5000,
              text:
                e?.response?.data?.message ||
                "Ocorreu um erro, tente novamente!",
              group: "app",
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>