import "es6-promise/auto";
import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VDataTable } from "vuetify/labs/VDataTable";
import axios from "axios";
import * as debounce from "lodash.debounce";

const createAxios = () =>
  axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("tk"),
    },
  });

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: "#fff",
    surface: "#fff",
    primary: "#153764",
    secondary: "#2B3E62",
  },
};

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTable,
  },
  directives,
  ssr: true,
  defaultTheme: "light",
  themes: {
    light: {
      dark: false,
      variables: {},
      colors: {
        primary: myCustomLightTheme.primary,
        secondary: myCustomLightTheme.secondary,
      },
    },
  },
});

window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

window.onload = function () {
  store.commit("setMobile", window.mobileAndTabletCheck());
};

window.onresize = function () {
  store.commit("setMobile", window.mobileAndTabletCheck());
};

const app = createApp(App).use(store).use(router).use(vuetify);
app.config.globalProperties.$generateAssetPath = function (file) {
  return require(`@/${file}`);
};
app.config.globalProperties.$imgurl = function (v) {
  if (!v) return null;
  return `${process.env.VUE_APP_AWS_BUCKET_URL}${v}`;
};
app.config.globalProperties.$rootColor = "#0D2A54";
app.config.globalProperties.$formatCurrency = (v) =>
  Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(v);
app.config.globalProperties.$formatDate = (v, emptyString, full, options) =>
  v
    ? Intl.DateTimeFormat(
        "pt-BR",
        full
          ? options ?? {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }
          : {}
      ).format(new Date(v))
    : emptyString || null;

app.config.globalProperties.$regularStyle = {
  variant: "solo",
  density: "comfortable",
  clearable: true,
};
app.config.globalProperties.$http = createAxios();
app.config.globalProperties.$resetAxiosConfig = () =>
  (app.config.globalProperties.$http = createAxios());
app.config.globalProperties.$orderStatuses = [
  {
    key: 1,
    title: "Aguardando Confirmação",
    color: "black",
  },
  {
    key: 2,
    title: "Confirmado, Preparando",
    color: "green",
  },
  {
    key: 3,
    title: "Em transporte",
    color: "blue",
  },
  {
    key: 4,
    title: "Entregue",
    color: "gray",
  },
  {
    key: 5,
    title: "Cancelado",
    color: "red",
  },
  {
    key: 6,
    title: "Reembolsado",
    color: "red",
  },
  {
    key: 7,
    title: "Problemas na Entrega",
    color: "yellow",
  },
  {
    key: 8,
    title: "Entrega Agendada",
    color: "yellow",
  },
  {
    key: 10,
    title: "Processando pagamento no gateway",
    color: "yellow",
  },
];
let _debounces = {};
app.config.globalProperties.$debounce = (k, f, d) => {
  if (_debounces[k]) _debounces[k]?.cancel();
  _debounces[k] = debounce(() => {
    f();
    delete _debounces[k];
  }, d ?? 650);
  _debounces[k]();
};
app.config.globalProperties.$getComposedDataFromLocalStorage = (masterKey, subkeys = [], defaultReturn = null, shouldJsonDecode = false) =>{
  let i = localStorage.getItem(masterKey);
  if(!i) return defaultReturn;
  if(shouldJsonDecode) {
    i = JSON.parse(i);
  }
  subkeys.forEach((k) => {
    i = i[k] ?? defaultReturn;
  });
  return i;
};
app.mount("#app");
export const self = () => app.config.globalProperties;
export default app;
