<template>
  <v-row>
    <v-col cols="2">
      <v-row class="ma-0">
        <v-img
          :src="$imgurl(item.product_image)"
          :lazy-src="$imgurl(item.product_image)"
          alt="Imagem do produto"
          :aspect-ratio="1"
          class="img__item"
          :cover="true"
        />
      </v-row>
    </v-col>
    <v-col cols="10">
      <div class="d-flex justify-space-between item__desc">
        <div>
          <span class="mr-2">
            <strong>{{ item.amount }}x</strong>
            <small>
              {{
                `&nbsp;(` +
                Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.base_price) +
                `)`
              }}
            </small>
          </span>
          <strong>{{item.half_item  == 1 ? '(METADE) ' : ''}}{{ item.product_name }}</strong>
        </div>
        <span>
          <small>Total do item </small>
          <strong>
            {{
              Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(item.item_price * item.amount)
            }}
          </strong>
        </span>
      </div>
      <div
        class="d-flex justify-space-between item__desc variation"
        v-for="(opt, i) in item?.options ?? []"
        :key="`opion${i}`"
      >
        <div>
          <span class="mr-2">
            <small>
              <strong>&nbsp;&nbsp;• {{ opt.amount }}x </strong>
              <small>
                {{
                  `&nbsp;(` +
                  Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }).format(opt.item_price) +
                  `)`
                }}
              </small>
            </small>
          </span>
          <span>
            <small>{{ opt.set_name }} - {{ opt.set_item_name }}</small>
          </span>
        </div>
        <span>
          <strong>
            <small>
              {{
                Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(opt.item_price * opt.amount)
              }}
            </small>
          </strong>
        </span>
      </div>
      <div
        class="d-flex justify-space-between item__desc variation"
        v-if="item.instructions"
      >
        <div>
          <span class="mr-2">
            <small>
              <strong>&nbsp;&nbsp;• OBS.:</strong>
            </small>
          </span>
          <strong>
            <small>{{ item.instructions }}</small>
          </strong>
        </div>
        <span> </span>
      </div>
    </v-col>
    <v-divider></v-divider>
  </v-row>
</template>

<script>
export default {
  name: "OrderItem",

  props: {
    item: Object,
  },

  data() {
    return {};
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {},
  },

  computed: {
    address_resume() {
      return `${this.order.address.street}, ${this.order.address.number} - ${this.order.address.neighborhood}, ${this.order.address.city} - ${this.order.address.state}`;
    },
  },
};
</script>

<style scoped>
.item__desc * {
  line-height: 1;
}
.img__item {
  border-radius: 3600px;
}
</style>