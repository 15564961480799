<template>
  <v-container>
    <v-card>
      <!-- progress linear -->
      <v-progress-linear
        v-if="!editItem || !variant"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title>
        <h4 class="text-left">Editando Item da Variação</h4>
      </v-card-title>
      <v-card-text>
        <VariantForm
          :editItem="editItem"
          :variant="variant"
          v-if="editItem && variant"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VariantForm from "./Form.vue";

export default {
  components: { VariantForm },
  data() {
    return {
      productId: null,
      variationId: null,
      variantItemId: null,
      variant: null,
      editItem: null,
    };
  },

  mounted() {
    this.productId = this.$route.params.productId;
    this.variationId = this.$route.params.variationId;
    this.variantItemId = this.$route.params.id;

    this.getVariant();
    this.getVariantItem();
  },

  methods: {
    async getVariant() {
      await this.$http
        .get(
          `/admin/products/${this.productId}/variations-sets/${this.variationId}`
        )
        .then((res) => {
          this.variant = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getVariantItem() {
      await this.$http
        .get(
          `/admin/products/${this.productId}/variations-sets/${this.variationId}/items/${this.variantItemId}`
        )
        .then((res) => {
          this.editItem = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>