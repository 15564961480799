<template>
  <v-container class="fill-height" >
    <v-row class="fill-height ma-0">
      <v-col cols="4">
        <v-card outlined class="fill-height card__orders" style="height: 100vh">
          <v-card-title class="title__item">
            <h4 class="text-left">Pedidos</h4>
            <v-tooltip :text="'Recarregar todos'" location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="ml-1"
                  icon
                  size="x-small"
                  @click="reloadAll()"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-menu
              top
              bottom
              :close-on-click="false"
              :close-on-content-click="false"
              v-model="menuDialog"
            >
              <template v-slot:activator="menuProps">
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="tooltipProps">
                    <v-btn
                      class="ml-1"
                      size="x-small"
                      :color="isEmptyFilter ? 'gray' : 'red'"
                      v-bind="{ ...menuProps.props, ...tooltipProps.props }"
                      icon
                    >
                      <v-icon> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <span>Filtros</span>
                </v-tooltip>
              </template>
              <template v-slot:default>
                <v-card width="375px" min-height="75vh">
                  <v-card-title>Filtros</v-card-title>
                  <v-card-text>
                    <v-col>
                      <v-select
                        variant="outlined"
                        :clearable="true"
                        density="compact"
                        v-model="filters.statuses"
                        :multiple="true"
                        :chips="true"
                        :closable-chips="true"
                        label="Status"
                        :items="$orderStatuses"
                        item-title="title"
                        item-value="key"
                      ></v-select>
                      <v-row class="ma-0">
                        <Vuetify3DatetimePicker
                          v-for="(item, i) in [
                            { label: 'Data Início', value: 'start' },
                            { label: 'Data Fim', value: 'end' },
                          ]"
                          :key="`select${i}`"
                          v-model="filters[item.value]"
                          format="YYYY-MM-DD"
                          :formatoptions="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :datepicker-props="{
                            'select-text': 'Selecionar',
                            'cancel-text': 'Cancelar',
                          }"
                          :text-field-props="{
                            variant: 'outlined',
                            density: 'compact',
                            label: item.label,
                          }"
                          :clearable="true"
                          style="width: 100%"
                        ></Vuetify3DatetimePicker>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      @click="
                        filters = {};
                        menuDialog = false;
                      "
                      v-if="!isEmptyFilter"
                    >
                      Limpar Filtros
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="menuDialog = false">Fechar</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-menu>
            <v-text-field
              class="ml-1"
              v-model="filters.search"
              density="compact"
              variant="outlined"
              :clearable="true"
              :hide-details="true"
              label="Buscar"
            ></v-text-field>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <div style="height: 90%; overflow: auto" v-if="sectionId">
              <v-expansion-panels variant="popout" class="my-4">
                <OrdersStatusList
                  v-for="status in computedOrderStatuses"
                  :key="status.key"
                  :ref="`ref${status.key}`"
                  :status="status"
                  :sectionId="sectionId"
                  :filters="filters"
                />
              </v-expansion-panels>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="8">
        <CardDetail @reloadAll="reloadAll" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";
import moment from "moment";
import * as debounce from "lodash.debounce";
export default {
  components: {
    CardDetail: defineAsyncComponent(() =>
      import("@/components/Orders/CardDetail.vue")
    ),
    OrdersStatusList: defineAsyncComponent(() =>
      import("@/components/Orders/OrdersStatusList.vue")
    ),

    Vuetify3DatetimePicker: defineAsyncComponent(() =>
      import("@/components/Vuetify3DatetimePicker.vue")
    ),
  },
  name: "OrdersIndex",
  data() {
    return {
      menuDialog: false,
      filters: {
        statuses: [],
        search: null,
        start: moment().format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    computedOrderStatuses() {
      const selected = this.filters?.statuses ?? [];
      if (selected.length == 0) {
        return this.$orderStatuses;
      }
      return this.$orderStatuses.filter((i) => selected.includes(i.key));
    },
    isEmptyFilter() {
      let empty = true;
      Object.values(this.filters).forEach((i) => {
        if (i) empty = false;
      });
      return empty;
    },
    sectionId() {
      return this.$route.params.id;
    },
    order() {
      return this.$store.state?.orderView?.order || null;
    },
    color() {
      return this.$store.state?.orderView?.color || null;
    },
    updateOrders() {
      return this.$store.state?.updateOrders || false;
    },
  },

  watch: {
    updateOrders(val) {
      if (val == true) {
        this.reloadAll();
      }
    },
    sectionId() {
      this.$store.commit("setOrderId", null);
    },
    filters: {
      deep: true,
      handler() {
        this.debounceInput(this.reloadAll);
      },
    },
  },

  mounted() {},

  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 1000),
    reloadAll() {
      this.$orderStatuses.forEach((k, _) => {
        let item = this.$refs[`ref${k.key}`];
        if (Array.isArray(item)) {
          if (item.length == 0) {
            item = null;
          } else {
            item = item[0];
          }
        }
        if (item) {
          try {
            item.listOrdersByStatus();
          } catch (_) {}
        }
      });
    },
  },
};
</script>

<style scoped>
.card__orders {
  max-height: 80vh;
  overflow: scroll;
}
.title__item {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>