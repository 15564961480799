
<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-title>
        <div class="d-flex">
          <div>
            <span class="text-xl">Editar Política Privacidade APP</span>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <ItemForm :editItem="editItem" v-if="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ItemForm from "./Form";

export default {
  name: "Index",
  components: {
    ItemForm,
  },
  data() {
    return {
      id: null,
      editItem: null,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const response = await this.$http.get(`/admin/privacypolicy`);
        this.editItem = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>