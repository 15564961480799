<template>
  <v-app :theme="theme">
    <navigation-drawer ref="navigationDrawer"></navigation-drawer>
    <v-app-bar v-if="user" app>
      <LoggedUserInfo :show="user" />
      <v-spacer></v-spacer>
      <v-tooltip location="bottom" :text="theme == 'light' ? 'Ativar o modo escuro' : 'Ativar o modo claro'">
        <template #activator="tooltip">
          <v-btn icon v-bind="tooltip.props" @click="changeTheme()">
            <v-icon>mdi-brightness-6</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <PrinterSelector />
      <logged-user-actions :show="user" :navigationdrawerref="navigationDrawer" />
    </v-app-bar>
    <v-main :style="{
      minHeight: '100vh',
      height: 'max-content'
    }">
      <router-view />
      <v-snackbar v-model="state.snackbar" multi-line :color="state.color">
        {{ state.text }}

        <template v-slot:actions>
          <v-btn
            variant="elevated"
            @click.prevent.stop.capture="
              $event.preventDefault();
              state.snackbar = false;
            "
            class="text-white"
            :color="$rootColor"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { computed, defineAsyncComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    LoggedUserActions: defineAsyncComponent(() =>
      import("@/components/LoggedUserActions.vue")
    ),
    LoggedUserInfo: defineAsyncComponent(() =>
      import("@/components/LoggedUserInfo.vue")
    ),
    NavigationDrawer: defineAsyncComponent(() =>
      import("@/components/NavigationDrawer.vue")
    ),
    PrinterSelector: defineAsyncComponent(() =>
      import("@/components/PrinterSelector.vue")
    ),
  },
  setup() {
    const store = useStore();
    const state = reactive({
      snackbar: false,
      text: null,
      duration: 10000,
    });
    const computedData = {
      notificationData: computed(() => {
        return store.state.notificationData;
      }),
      user: computed(() => {
        return store.state.user;
      }),
      theme: computed(() => {
        return store.state.theme ?? 'light';
      }),
    };

    watch(computedData.notificationData, (newValue, oldValue) => {
      if (newValue) {
        switch (newValue?.type) {
          case "success":
            state.color = "green";
            break;
          case "warning":
            state.color = "orange darken-3";
            break;
          case "error":
            state.color = "red darken-2";
            break;

          default:
          case "warning":
            state.color = "orange darken-3";
            break;
        }
        state.text = newValue?.text || "";
        state.duration = newValue?.duration || 10000;
        state.snackbar = true;
      }
    });

    watch(
      () => state.snackbar,
      (newValue, oldValue) => {
        if (newValue == false) {
          store.commit("showMessage", null);
        }
      }
    );

    const navigationDrawer = ref(null);

    const changeTheme = () => {
      store.commit('changeTheme')
    };

    return {
      state,
      ...computedData,
      navigationDrawer,
      changeTheme,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
