<template>
  <Edit :level="5" :indexpathname="'users.storesectionemployee.index'" />
</template>
  
  <script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    Edit: defineAsyncComponent(() => import("../000Base/Edit.vue")),
  },
};
</script>
  
  <style>
</style>