<template>
  <v-dialog v-model="dialog" v-if="user" width="95vw" max-width="575px">
    <template v-slot:activator="{ props }">
      <v-tooltip
        location="bottom"
        :text="
          selectedPrinter
            ? `Impressora Selecionada: ${selectedPrinter?.name ?? ''}`
            : 'Selecionar Impressora'
        "
      >
        <template v-slot:activator="propsTooltip">
          <v-btn
            class="content__activator"
            icon
            :loading="loading"
            v-bind="{
              ...props,
              ...propsTooltip.props,
            }"
            v-ripple
          >
            <v-icon
              :color="selectedPrinter ? 'green' : adviseColor"
              style="transition: 0.15s"
              :size="
                selectedPrinter
                  ? 'large'
                  : adviseColor == 'red'
                  ? 'x-large'
                  : 'x-small'
              "
              >mdi-printer</v-icon
            >
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card
      width="100%"
      :loading="loading || loadingSelectPrinter"
      :disabled="loadingSelectPrinter"
    >
      <v-card-title>{{
        selectedPrinter ? "Impressoras disponíveis" : "Selecione uma impressora"
      }}</v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="printers"
          :server-items-length="printers.length"
          :itemsPerPage="printers.length"
          item-value="_id"
          :no-data-text="'Nada por aqui por enquanto'"
          :loading-text="'Carregando'"
          @click:row="clickRowPrinter"
        >
          <template v-slot:[`item.check`]="{ item }">
            <v-checkbox
              style="pointer-events: none"
              v-model="selectedPrinterId"
              :value="item.raw._id"
              :hide-details="true"
              :readonly="true"
            ></v-checkbox>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      printers: [],
      loading: false,
      loadingSelectPrinter: false,
      intervalId: null,
      lastRanAdviseWasRed: false,
      adviseColor: "black",
      headers: [
        {
          title: "",
          key: "check",
          sortable: false,
          align: "start",
        },
        {
          title: "Nome",
          key: "name",
          sortable: false,
          align: "start",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state?.user;
    },
    selectedPrinterId() {
      return this.user?.printer_id;
    },
    selectedPrinter() {
      if (!this.selectedPrinterId) return null;
      return this.printers?.find((i) => i._id == this.selectedPrinterId);
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        this.getPrinters();
      }
    },
  },
  mounted() {
    this.getPrinters();
    this.startAlertIfNeeded();
  },
  methods: {
    startAlertIfNeeded() {
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        if (!this.loading) {
          if (!this.selectedPrinter) {
            this.adviseColor = this.lastRanAdviseWasRed ? "black" : "red";
            this.lastRanAdviseWasRed = !this.lastRanAdviseWasRed;
          }
        }
      }, 1000);
    },
    clickRowPrinter(_, v) {
      if (this.selectedPrinterId != v?.item?.value) {
        this.selectPrinter(v?.item?.value);
      }
    },
    async getPrinters() {
      this.loading = true;
      await this.$http
        .get("/admin/printers")
        .then((r) => {
          this.loading = false;
          this.printers = r?.data?.items ?? [];
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    async selectPrinter(id) {
      if (this.selectedPrinterId && this.selectedPrinterId == id) {
        return;
      }
      if (this.loadingSelectPrinter) return;
      this.loadingSelectPrinter = true;
      await this.$http
        .post(`/admin/printers/select/${id}`)
        .then(async () => {
          await this.$http
            .get("/auth/me")
            .then((r) => {
              this.loadingSelectPrinter = false;
              this.$store.commit("setUser", r?.data?.user);
            })
            .catch(() => {
              window.location.reload();
            });
        })
        .catch((e) => {
          this.loadingSelectPrinter = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message || e,
            group: "app",
          });
        });
    },
  },
};
</script>

<style scoped>
.content__activator {
  padding: 0.5rem;
  border-radius: 3600px;
  cursor: pointer;
}
</style>