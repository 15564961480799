
<template>
  <v-form
    ref="formData"
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="state.localData.title"
          label="Título"
          :rules="[
            (v) => !!v || 'Campo obrigatório',
            (v) =>
              (v && v.length <= 100) || 'Deve ter no máximo 100 caracteres',
          ]"
          variant="outlined"
          density="compact"
        ></v-text-field>
        <v-text-field
          v-model="state.localData.body"
          label="Descrição"
          :rules="[
            (v) => !!v || 'Campo obrigatório',
            (v) =>
              (v && v.length <= 100) || 'Deve ter no máximo 100 caracteres',
          ]"
          variant="outlined"
          density="compact"
        ></v-text-field>

        <Vuetify3DatetimePicker
          v-model="state.localData.scheduled_to"
          :format="'YYYY-MM-DD HH:mm'"
          :datepicker-props="{
            'select-text': 'Selecionar',
            'cancel-text': 'Cancelar',
          }"
          :text-field-props="{
            variant: 'outlined',
            density: 'compact',
            label: 'Agendar Envio (opcional, em branco para envio imediato)',
            rules: [],
          }"
          :clearable="true"
        ></Vuetify3DatetimePicker>

        <v-select
          v-model="state.localData.type"
          label="Tipo de Notificação"
          :rules="[(v) => v != null || 'Campo obrigatório']"
          variant="outlined"
          density="compact"
          :items="types"
          item-title="text"
          item-value="value"
        ></v-select>

        <v-alert
          type="warning"
          prominent
          v-if="
            state.localData.type == 1 && state.localData.user_ids?.length == 0
          "
          class="mb-4"
          >Atenção: Se não informar os usuários, será enviado para todos os
          usuários do APP. Tenha certeza antes de enviar.</v-alert
        >

        <v-autocomplete
          v-if="state.localData.type != null"
          v-model="state.localData.user_ids"
          :label="
            state.localData.type == 1 ? `Usuários-alvo (opcional)` : `Usuário`
          "
          :rules="[(v) => userIdsValidator(v)]"
          variant="outlined"
          density="compact"
          :items="users"
          item-title="name"
          item-value="_id"
          v-model:search="search"
          :chips="true"
          :closable-chips="true"
          :loading="loadingUsers"
          :multiple="true"
          :clearable="true"
          :max-items="3"
          @update:modelValue="checkSelectionLimit"
        ></v-autocomplete>

        <v-select
          v-model="state.localData.action"
          label="Ação no APP"
          variant="outlined"
          density="compact"
          :items="actions"
          item-title="text"
          item-value="value"
        ></v-select>

        <v-text-field
          v-if="state.localData.action"
          v-model="state.localData.action.data"
          label="Valor a ser enviado"
          :rules="[
            (v) => !!v || 'Campo obrigatório',
            (v) =>
              (v && v.length <= 100) || 'Deve ter no máximo 100 caracteres',
          ]"
          variant="outlined"
          density="compact"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        variant="text"
        :to="{ name: 'notifications.index' }"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        variant="elevated"
        :loading="state.loading"
        type="submit"
      >
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "Index",

  components: {
    Vuetify3DatetimePicker: defineAsyncComponent(() =>
      import("@/components/Vuetify3DatetimePicker.vue")
    ),
  },

  props: {
    editItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      types: [
        {
          text: "Pessoa Única",
          value: 0,
        },
        {
          text: "Múltiplas Pessoas",
          value: 1,
        },
      ],
      actions: [
        {
          text: "Sem ação, apenas informativo",
          value: 0,
        },
        {
          text: "Navegar para algum site",
          value: {
            action: "navigate_external",
          },
        },
        {
          text: "Navegar para rota do APP",
          value: {
            action: "navigate_flutter",
          },
        },
      ],
      saveMethod: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/notifications/${this.$route.params.id}`
        : "/admin/notifications",
      state: {
        formModel: false,
        loading: false,
        localData: {
          _id: null,
          type: null,
          title: null,
          body: null,
          action: null,
          user_ids: [],
          scheduled_to: null,
        },
      },
      loadingUsers: false,
      users: [],
      search: null,
    };
  },
  watch: {
    search() {
      this.$debounce("listUsersNotifications", this.listUsers);
    },
  },

  mounted() {
    this.listUsers();
    if (this.editItem) {
      this.state.localData = { ...this.editItem };
    }
  },

  methods: {
    checkSelectionLimit() {
      if (
        this.state.localData.type == 0 &&
        this.state.localData.user_ids.length > 1
      ) {
        this.state.localData.user_ids = this.state.localData.user_ids.slice(
          0,
          1
        );
      }
    },
    userIdsValidator(v) {
      if (this.state.localData.type == 0) {
        if (v == null) {
          return "Campo obrigatório";
        }
        if (v.length == 0) {
          return "Campo obrigatório";
        }
        return true;
      }
      return true;
    },
    async listUsers() {
      this.loadingUsers = true;
      const params = {
        level: 0,
        ids: this.state.localData.user_ids || null,
        perPage: 10 + (this.state.localData.user_ids?.length || 0),
        search: this.search,
      };
      await this.$http
        .get("/admin/users", { params })
        .then((response) => {
          this.loadingUsers = false;
          this.users = response.data.items.data;
        })
        .catch((error) => {
          this.loadingUsers = false;
          console.log(error);
        });
    },
    async save() {
      console.log({ ...this.state.localData });
      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        let data = { ...this.state.localData };
        this.state.loading = true;
        this.$http[this.saveMethod](this.saveUrl, data)
          .then((response) => {
            this.state.loading = false;
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Notificação salva com sucesso",
              group: "app",
            });
            this.$router.push({ name: "notifications.index" });
          })
          .catch((error) => {
            this.state.loading = false;
            console.log(error);

            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: error.response.data.message,
              group: "app",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os campos corretamente",
          group: "app",
        });
      }
    },
  },
};
</script>