<template>
  <v-container>
    <v-card>
      <v-tabs v-model="tab" class="mb-8" color="error">
        <v-tab :value="0">Outros</v-tab>
        <!-- Na ausência de section se enquadra em Home (Página inicial) ou Clube, de acordo com o tipo -->
        <v-tab
          v-for="section in sections"
          :key="section.name"
          :value="section._id"
        >
          {{ section.name }}
        </v-tab>
      </v-tabs>

      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title> </v-card-title>
      <v-card-text>
        <div class="d-flex justify-start mb-5 me-4">
          <v-sheet class="ma-1 pa-1">
            <v-btn
              color="primary"
              class="mb-2"
              :to="{ name: 'banners.create' }"
            >
              Adicionar Banner
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-sheet>
        </div>
        <div class="d-flex justify-start mb-5 me-4">
          <v-text-field
            label="Procurar"
            v-model="search"
            :clearable="true"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="banners"
          :server-items-length="banners.length"
          :itemsPerPage="banners.length"
          hide-default-footer
          return-object
        >
          <template v-slot:[`item.img`]="{ item }">
            <a :href="$imgurl(item.value.img)" target="_blank">
              <v-img
                :src="$imgurl(item.value.img)"
                width="80%"
                class="mx-auto my-8"
              ></v-img>
            </a>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.value.type ?? "Home" }}
          </template>
          <template v-slot:[`item.url`]="{ item }">
            <a :href="item.value.url" target="_blank">
              {{ item.value.url }}
            </a>
          </template>
          <template v-slot:[`item.section_id`]="{ item }">
            {{
              item.value.section_id == null ? "Home" : item.value.section.name
            }}
          </template>
          <template v-slot:[`item.active`]="{ item }">
            {{ item.value.active == 1 ? "Ativo" : "Desativado" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-right">
              <v-btn
                variant="tonal"
                size="small"
                class="mr-2"
                :to="{
                  name: 'banners.edit',
                  params: { id: item.value._id },
                }"
              >
                <v-icon>mdi-pencil</v-icon>
                editar
              </v-btn>

              <v-btn
                variant="tonal"
                color="error"
                size="small"
                class="mr-2"
                @click="destroyBanner(item.value._id)"
              >
                <v-icon>mdi-delete</v-icon>
                remover
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script >
import * as debounce from "lodash.debounce";
export default {
  data() {
    return {
      tab: 0,
      sections: [],
      isOpenDialog: false,
      isLoading: false,
      product: null,
      banners: [],
      headers: [
        {
          title: "Imagem",
          key: "img",
          sortable: true,
          align: "start",
        },
        {
          title: "Nome",
          key: "name",
          sortable: true,
          align: "start",
        },
        {
          title: "Link",
          key: "url",
          sortable: true,
          align: "center",
        },
        {
          title: "Tipo",
          key: "type",
          sortable: true,
          align: "end",
        },
        {
          title: "Ativo",
          key: "active",
          sortable: true,
          align: "center",
        },
        {
          title: "",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
    };
  },
  watch: {
    "pagination.page": function () {
      if (this.isLoading) return;
      this.listBanners();
    },
    search() {
      this.debounceInput(this.prepareSearch);
    },
    tab() {
      this.listBanners(true);
    },
  },
  mounted() {
    this.listSections();
    this.setTabSelected();
    this.listBanners();
  },
  computed: {},
  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.listBanners();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    activeBanners(data) {
      const activeBanners = [];
      if (data === "home") {
        for (const banner of this.banners) {
          console.log(banner.section_id);
          if (banner.section_id == null) {
            activeBanners.push(banner);
          }
        }
      } else {
        for (const banner of this.banners) {
          if (banner.section_id == data) {
            activeBanners.push(banner);
          }
        }
      }

      return activeBanners;
    },
    editProduct(item) {
      this.product = item;
      this.isOpenDialog = true;
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.product = null;
      this.listBanners();
    },
    listBanners(reset) {
      if (this.isLoading) return;
      this.isLoading = true;
      if (reset) {
        this.banners = [];
        this.search = null;
        this.pagination = {};
      }
      const sectionId = this.tab == 0 ? null : this.tab;
      this.$http
        .get("/admin/banners", {
          params: {
            search: this.search,
            ...this.pagination,
            section_id: sectionId,
          },
        })
        .then((response) => {
          this.banners = response.data.items.data;
          this.pagination.page = response?.data?.items?.current_page;
          this.pagination.pageCount = response?.data?.items?.last_page;
          setTimeout(
            () => {
              this.isLoading = false;
            },
            reset ? 1000 : 0
          );
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async listSections() {
      await this.$http
        .get("/admin/sections/list-all")
        .then((r) => {
          this.sections = r.data.data;
        })
        .catch((e) => {
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },
    setTabSelected() {
      if (this.$route.query.tab) {
        const tabs = ["price_and_stock", "details", "variants", "photos"];
        if (tabs.includes(this.$route.query.tab)) {
          this.tab = this.$route.query.tab;
        }
      }
    },
    destroyBanner(id) {
      if (confirm(`Você deseja realmente remover este produto? ID: ${id}`)) {
        this.isLoading = true;
        this.$http
          .delete(`/admin/banners/${id}`)
          .then((response) => {
            this.isLoading = false;
            this.listBanners();

            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Banner removido com sucesso!",
              group: "app",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
</style>