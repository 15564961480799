<template>
  <v-form
    ref="formData"
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <div class="d-flex justify-space-between mb-4">
      <div>
        <v-btn color="primary" type="submit" class="mr-4 my-2" size="small">
          Salvar &nbsp;
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </div>
    <v-tabs v-model="tab" class="mb-8" color="error">
      <v-tab value="details">Informações do Jornal</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="details">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="state.localData.name"
              label="Nome do Jornal (obrigatório)"
              :rules="[(v) => v != null || 'Campo obrigatório']"
            ></v-text-field>

            <a :href="$imgurl(state.localData.img)" target="_blank">
              <v-img
                :src="$imgurl(state.localData.img)"
                width="50%"
                class="mx-auto my-8"
              ></v-img>
            </a>
          </v-col>

          <v-col cols="12" class="pt-4">
            <a
              v-if="state.localData.url"
              :href="$imgurl(state.localData.url)"
              target="_blank"
            >
              PDF enviado. Clique aqui para ver
            </a>

            <div class="px-6">
              <UploadComponent
                class="mt-2 mb-6"
                :limit="1"
                :data="state.localData"
                :mini="state.localData.img"
                :field="'image'"
                :field_small="'image_small'"
                style="height: 100px"
                v-on:update_path="receiveUrl"
                accept="application/pdf"
                :mimesTypes="['application/pdf']"
              ></UploadComponent>
            </div>

            <p class="text-left px-5 mt-2">
              Formatos aceitos: <strong>JPEG, JPG e PNG</strong><br />
              Peso máximo: <strong>20 MB</strong><br />
              Resolução mínima: <strong>300x275</strong>
            </p>
          </v-col>

          <v-col cols="12" class="pt-4">
            <v-text-field
              v-model="state.localData.datetime_start"
              type="datetime-local"
              label="Mostrar a partir de"
            ></v-text-field>
            <v-text-field
              v-model="state.localData.datetime_end"
              type="datetime-local"
              label="Mostrar até"
            ></v-text-field>
            <!-- <v-select
              variant="outlined"
              v-model="state.localData.page"
              label="Página"
              density="compact"
              :items="sectionsPage"
              item-title="name"
              item-value="value"
            ></v-select> -->
          </v-col>
          <!-- <v-col cols="12" class="pt-4">
            <v-select
              variant="outlined"
              v-model="state.localData.active"
              label="Ativo"
              density="compact"
              :items="sectionsActive"
              item-title="name"
              item-value="value"
            ></v-select>
          </v-col> -->
        </v-row>
      </v-window-item>
    </v-window>
  </v-form>
</template>

<script>
import UploadComponent from "@/components/UploadComponent.vue";

export default {
  components: {
    UploadComponent,
  },
  props: {
    editItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tab: null,
      title: this.editItem?._id ? "Editar Jornal" : "Novo Jornal",
      method: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/news/${this.editItem._id}`
        : "/admin/news",
      sectionsActive: [
        { name: "Sim", value: "1" },
        { name: "Não", value: "0" },
      ],
      sectionsPage: [
        { name: "Página 1 - Capa", value: "1" },
        { name: "Página 2 - Contra capa", value: "2" },
      ],
      state: {
        loading: false,
        formModel: null,
        localData: {
          _id: null,
          page: null,
          img: null,
          active: null,
        },
      },
    };
  },

  mounted() {
    this.setTabSelected();
    this.setLocalData();
    console.log("Dados pra editar", this.editItem);
  },

  watch: {},

  methods: {
    setLocalData() {
      if (this.editItem?._id) {
        this.state.localData = {
          ...this.editItem,
        };
      }
    },

    setTabSelected() {
      if (this.$route.query.tab) {
        const tabs = ["price_and_stock", "details", "variants", "photos"];
        if (tabs.includes(this.$route.query.tab)) {
          this.tab = this.$route.query.tab;
        }
      }
    },

    async save() {
      this.state.loading = true;

      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        let data = { ...this.state.localData };
        console.log("Dados para enviar ao backend", data);

        await this.$http[this.method](this.saveUrl, data)
          .then((r) => {
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Banner salvo com sucesso",
              group: "app",
            });

            this.$emit("close-dialog");
            this.$router.push({ name: "Jornal de Ofertas" });
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message,
              group: "app",
            });
          });
        console.log("erros de validacao do form");
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
      this.state.loading = false;
    },
    receiveUrl(data) {
      console.log("Recebido evento do component de upload", data);
      this.state.localData.url = data;
    },
    async listSections() {
      await this.$http
        .get("/admin/sections/list-all")
        .then((r) => {
          this.sections = r.data.data;
        })
        .catch((e) => {
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },
  },
};
</script>

<style>
</style>