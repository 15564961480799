<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-title>
        <h4 class="text-left">Edição de Jornal</h4>
      </v-card-title>
      <v-card-text>
        <JornalForm :editItem="editItem" v-if="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import JornalForm from "./Form";

export default {
  components: {
    JornalForm,
  },
  data() {
    return {
      id: null,
      editItem: null,
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getBannerData();
  },

  methods: {
    getBannerData() {
      this.$http
        .get(`/admin/news/${this.id}`)
        .then((response) => {
          console.info({response})
          this.editItem = response.data.data;
        })
        .catch((error) => {
          console.error({error});
        });
    },
  },
};
</script>

<style>
</style>