<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title class="text-left">
        <span class="text-lg">Detalhes</span>
      </v-card-title>
      <v-card-text>
        <v-row v-if="state.localData">
          <v-col cols="6" class="text-left">
            <p class="mb-2">
              <strong>Nome: </strong>{{ state.localData.name }}
            </p>
            <p class="mb-2">
              <strong>Telefone: </strong>{{ state.localData.phone }}
            </p>
          </v-col>
          <v-col cols="6" class="text-left">
            <p class="mb-2">
              <strong>Email: </strong>{{ state.localData.email }}
            </p>
            <p class="mb-2">
              <strong>CPF: </strong>{{ state.localData.document }}
            </p>
          </v-col>
        </v-row>
        <v-progress-circular
          v-else
          :active="true"
          color="primary"
          :indeterminate="true"
          size="70"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      loading: false,
      state: {
        localData: null,
      },
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getMember();
  },

  methods: {
    async getMember() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        club_fields: 1,
      };
      await this.$http
        .get(`/admin/club/${this.id}`, { params })
        .then((res) => {
          this.loading = false;
          this.state.localData = res.data.data;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.commit("showMessage", {
            type: "error",
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
    },
  },
};
</script>

<style>
</style>