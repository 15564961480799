<template>
  <v-container>
    <template v-slot:pageTitle>
      <h4 class="text-left">Categorias</h4>
    </template>
    <v-card>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-title>
        <div
          class="d-flex mb-5"
          :style="{
            alignItems: 'center',
          }"
        >
          <v-btn color="primary" :to="{ name: 'categories.create' }">
            Adicionar categoria
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-col class="pa-0 ml-2" style="flex-grow: 0">
            <div class="text-subtitle-2">Qtd por página</div>
            <v-select
              v-model="pagination.perPage"
              :items="[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]"
              :variant="'solo'"
              :density="'compact'"
              :hide-details="true"
            ></v-select>
          </v-col>
          <v-col class="pa-0 ml-2" style="flex-grow: 0">
            <div class="text-subtitle-2">Filtrar Destaque</div>
            <v-btn-toggle
              v-model="pagination.flagged"
              rounded="0"
              color="deep-purple-accent-3"
              group
              density="compact"
              title="here"
              variant="outlined"
            >
              <v-btn :value="1"> Sim </v-btn>
              <v-btn :value="0"> Não </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="pa-0 ml-2" style="flex-grow: 0">
            <div class="text-subtitle-2">Ordenar por</div>
            <v-btn-toggle
              v-model="pagination.orderBy"
              rounded="0"
              color="deep-purple-accent-3"
              group
              density="compact"
              title="here"
              variant="outlined"
            >
              <v-btn value="_id"> ID </v-btn>
              <v-btn value="custom_order"> Ordem </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="pa-0 ml-2" style="flex-grow: 0">
            <div class="text-subtitle-2">Direção</div>
            <v-btn-toggle
              v-model="pagination.order"
              rounded="0"
              color="deep-purple-accent-3"
              group
              density="compact"
              variant="outlined"
              :borderless="true"
            >
              <v-btn value="desc" icon="mdi-sort-descending"></v-btn>
              <v-btn value="asc" icon="mdi-sort-ascending"></v-btn>
            </v-btn-toggle>
          </v-col>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-start mb-5 me-4">
          <v-text-field
            label="Procurar"
            v-model="search"
            :clearable="true"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="categories"
          :server-items-length="categories.length"
          :itemsPerPage="categories.length"
          :hide-default-footer="true"
          :show-footer="false"
          :disable-pagination="true"
          :hide-actions="true"
          return-object
          :loading="isLoading"
          loading-text="Carregando"
          no-data-text="Nada por aqui por enquanto"
        >
          <template v-slot:[`item.custom_order`]="{ item }">
            <!-- Forçar renderização ao removê-lo durante o carregamento, para previnir que ele mantenha os dados antigos do index X -->
            <OrderEditor
              v-if="!isLoading"
              :id="item?.value?._id"
              :value="item.value?.custom_order"
              @getList="getList()"
            />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span
              :style="{
                textAlign: 'start',
                width: '100%',
                display: 'inline-block',
              }"
            >
              {{ item?.value?.name ?? "--" }}
            </span>
          </template>
          <template v-slot:[`item.level_up_category`]="{ item }">
            <span
              :style="{
                textAlign: 'start',
                width: '100%',
                display: 'inline-block',
              }"
            >
              {{ item?.value?.level_up_category?.name ?? "--" }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              :to="{ name: 'categories.edit', params: { id: item.value._id } }"
            >
              <v-icon>mdi-pencil</v-icon>
              Editar
            </v-btn>
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              color="red"
              :loading="loadingDeletes[item.value._id]"
              @click.capture.prevent="deleteItem(item.value)"
            >
              <v-icon>mdi-close</v-icon>
              Excluir
            </v-btn>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    OrderEditor: defineAsyncComponent(() => import("./OrderEditor.vue")),
  },
  data() {
    return {
      isLoading: false,
      category: null,
      categories: [],
      headers: [
        {
          title: "Ordem",
          key: "custom_order",
          sortable: false,
          align: "start",
        },
        {
          title: "Nome",
          key: "name",
          sortable: false,
          align: "start",
        },
        {
          title: "Categoria mãe",
          key: "level_up_category",
          sortable: false,
          align: "start",
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
      loadingDeletes: [],
      search: null,
      pagination: {
        pageCount: null,
        page: null,
        perPage: 10,
        orderBy: "_id",
        order: "desc",
      },
    };
  },
  watch: {
    pagination: {
      deep: true,
      handler(n, o) {
        localStorage.setItem("cat_pag", JSON.stringify(n));
        if (this.isLoading) return;
        this.$debounce("categorieslist", this.getList);
      },
    },

    search(v) {
      this.$debounce("categorieslist", this.prepareSearch);
    },
  },
  created() {},
  mounted() {
    this.isLoading = true;
    this.pagination =
      this.$getComposedDataFromLocalStorage("cat_pag", [], null, true) ??
      this.pagination ??
      {};
    this.pagination.page = null;
    this.pagination = { ...this.pagination };
    this.isLoading = false;
    this.getList();
  },
  methods: {
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    async getList() {
      if (this.isLoading) return;
      this.isLoading = true;
      await this.$http
        .get("/admin/categories", {
          params: {
            search: this.search,
            ...this.pagination,
          },
        })
        .then((response) => {
          this.categories = response?.data?.items?.data ?? [];
          this.pagination.page = response?.data?.items?.current_page;
          this.pagination.pageCount = response?.data?.items?.last_page;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ?? "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
    },
    async deleteItem(item) {
      if (this.loadingDeletes[item._id]) return;
      if (window.confirm(`Deseja realmente excluir o item ${item.name}?`)) {
        this.loadingDeletes[item._id] = true;
        await this.$http
          .delete(`/admin/categories/${item._id}`)
          .then((r) => {
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Categoria excluída com sucesso",
              group: "app",
            });

            this.getList();
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text:
                e.response.data.message ?? "Ocorreu um erro, tente novamente!",
              group: "app",
            });
          });
      }
    },
  },
};
</script>

<style>
</style>