<template>
  <v-navigation-drawer
    v-if="user"
    :color="theme == 'light' ? $rootColor : null"
    :expand-on-hover="true"
    :theme="theme"
    v-model="state.drawer"
    v-model:rail="isRail"
    :permanent="isRail"
    :class="{
      'hide-scroll': isRail == true,
    }"
    :style="{
      padding: isRail ? 0 : '1rem',
    }"
  >
    <template v-slot:prepend>
      <v-img
        :src="logoImg"
        :aspect-ratio="comp"
        width="80%"
        class="mx-auto my-8"
      ></v-img>
    </template>
    <template v-slot:default>
      <v-list v-model:opened="state.open">
        <v-list-group value="Dashboard" v-if="[1, 2].includes(user?.level)">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-list-box-outline"
              value="1"
              color="white"
              :class="isRail ? '' : 'nav__item not__rail'"
            >
              <template v-slot:prepend>
                <v-icon color="white" style="opacity: 1"
                  >mdi-view-dashboard</v-icon
                >
              </template>
              <template v-slot:default>
                <span style="color: white">Painel</span>
              </template>
              <template v-slot:append="{ isActive }">
                <v-icon v-if="isActive" color="white" style="opacity: 1">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else color="white" style="opacity: 1"
                  >mdi-chevron-down
                </v-icon>
              </template>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(item, i) in state.sections"
            :key="`state${i}`"
            :to="`/dashboard/${item._id}`"
            :exact="true"
            value="1"
            color="white"
            :class="isRail ? 'nav__item' : 'nav__item not__rail'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">{{ item.name }}</span>
              </small>
            </template>
            <template v-slot:append>
              <v-icon color="white" style="opacity: 1" size="small"
                >mdi-list-box-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            :to="`/dashboard/clube`"
            :exact="true"
            value="1"
            color="white"
            :class="isRail ? 'nav__item' : 'nav__item not__rail'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Clube</span>
              </small>
            </template>
            <template v-slot:append>
              <v-icon color="white" style="opacity: 1" size="small"
                >mdi-list-box-outline</v-icon
              >
            </template>
          </v-list-item>
        </v-list-group>

        <v-divider class="my-8" color="white"></v-divider>
        <v-list-group value="Orders">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-list-box-outline"
              value="1"
              color="white"
              :class="isRail ? '' : 'nav__item not__rail'"
            >
              <template v-slot:prepend>
                <v-icon color="white" style="opacity: 1">mdi-home</v-icon>
              </template>
              <template v-slot:default>
                <span style="color: white">Pedidos</span>
              </template>
              <template v-slot:append="{ isActive }">
                <v-icon v-if="isActive" color="white" style="opacity: 1">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else color="white" style="opacity: 1"
                  >mdi-chevron-down
                </v-icon>
              </template>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(item, i) in state.sections"
            :key="`state${i}`"
            :to="`/orders/${item._id}`"
            :exact="true"
            :value="item._id"
            color="white"
            :class="isRail ? 'nav__item' : 'nav__item not__rail'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">{{ item.name }}</span>
              </small>
            </template>
            <template v-slot:append>
              <v-icon color="white" style="opacity: 1" size="small"
                >mdi-currency-usd</v-icon
              >
            </template>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="`/products`"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Produtos</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-list-box-outline</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'categories.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Categorias</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-tag</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2, 3].includes(user?.level)"
          :nav="true"
          :to="{ name: 'club.members.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Clube</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-group</v-icon
            >
          </template>
        </v-list-item>

        <v-list-group value="Content" v-if="[1, 2].includes(user?.level)">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-list-box-outline"
              value="1"
              color="white"
              :class="isRail ? '' : 'nav__item not__rail'"
            >
              <template v-slot:prepend>
                <v-icon color="white" style="opacity: 1"
                  >mdi-list-box-outline</v-icon
                >
              </template>
              <template v-slot:default>
                <span style="color: white">Conteúdo</span>
              </template>
              <template v-slot:append="{ isActive }">
                <v-icon v-if="isActive" color="white" style="opacity: 1">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else color="white" style="opacity: 1"
                  >mdi-chevron-down
                </v-icon>
              </template>
            </v-list-item>
          </template>

          <!-- Banners -->
          <v-list-item
            :nav="true"
            :to="'/banners'"
            color="white"
            value="banners"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Banners</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-image-area</v-icon
              >
            </template>
          </v-list-item>

          <!-- Jornal -->
          <v-list-item
            :nav="true"
            :to="'/news'"
            color="white"
            value="news"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Jornal de Ofertas</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-newspaper</v-icon
              >
            </template>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'stores.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Lojas</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-map-marker</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1].includes(user?.level)"
          :nav="true"
          :to="{ name: 'sections.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Seções</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-sort-variant</v-icon
            >
          </template>
        </v-list-item>

        <!-- Usuários -->
        <v-list-group value="Users">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-list-box-outline"
              value="1"
              color="white"
              :class="isRail ? '' : 'nav__item not__rail'"
            >
              <template v-slot:prepend>
                <v-icon color="white" style="opacity: 1">mdi-account</v-icon>
              </template>
              <template v-slot:default>
                <span style="color: white">Cadastros</span>
              </template>
              <template v-slot:append="{ isActive }">
                <v-icon v-if="isActive" color="white" style="opacity: 1">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else color="white" style="opacity: 1"
                  >mdi-chevron-down
                </v-icon>
              </template>
            </v-list-item>
          </template>

          <v-list-item
            v-if="[1, 2].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.usersapp.index' }"
            :exact="true"
            color="white"
            value="usersapp"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Usuários APP</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-account-card-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            v-if="[1].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.superadmin.index' }"
            :exact="true"
            color="white"
            value="superadmin"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Super Administradores</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-account-cog-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            v-if="[1, 2].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.admin.index' }"
            :exact="true"
            color="white"
            value="admin"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Administradores do Painel</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-account-cog-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            v-if="[1, 2].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.storeadmin.index' }"
            :exact="true"
            color="white"
            value="storeadmin"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Gerente de Loja</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-account-hard-hat-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            v-if="[1, 2, 3].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.storesectionmanager.index' }"
            :exact="true"
            color="white"
            value="storesectionmanager"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Gerentes de Seção</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-account-star-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            v-if="[1, 2, 3, 4].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.storesectionemployee.index' }"
            :exact="true"
            color="white"
            value="storesectionemployee"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Funcionários</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-account-tie-hat-outline</v-icon
              >
            </template>
          </v-list-item>

          <v-list-item
            v-if="[1, 2, 3, 4, 5].includes(user?.level)"
            :nav="true"
            :to="{ name: 'users.deliverymen.index' }"
            :exact="true"
            color="white"
            value="deliverymen"
            :class="'nav__item'"
          >
            <template v-slot:default>
              <small>
                <span style="color: white">Entregadores</span>
              </small>
            </template>
            <template v-slot:prepend>
              <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
                >mdi-bike-fast</v-icon
              >
            </template>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'coupons.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Cupons</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-sale</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'notifications.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Notificações APP</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-square-rounded-badge-outline</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'apphelp.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Ajuda APP</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-help</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'about.edit' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Sobre APP</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-information-outline</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2].includes(user?.level)"
          :nav="true"
          :to="{ name: 'privacypolicy.edit' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small v-if="!isRail">
              <span style="color: white">Política de Privacidade APP</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-newspaper</v-icon
            >
          </template>
        </v-list-item>

        <v-list-item
          v-if="[1, 2, 3].includes(user?.level)"
          :nav="true"
          :to="{ name: 'printers.index' }"
          color="white"
          :class="'nav__item'"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Impressoras</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-printer</v-icon
            >
          </template>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:append>
      <v-list>
        <v-list-item
          :nav="true"
          value="0"
          color="white"
          :class="isRail ? 'nav__item' : 'nav__item not__rail'"
          @click.stop="logout()"
          :loading="true"
        >
          <template v-slot:default>
            <small>
              <span style="color: white">Sair</span>
            </small>
          </template>
          <template v-slot:prepend>
            <v-icon color="white" style="opacity: 1" size="large" class="mr-5"
              >mdi-logout</v-icon
            >
          </template>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { self } from "@/main";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      drawer: true,
      loadingLogout: false,
      open: null,
      loadingSections: false,
      sections: reactive([]),
    });

    const computedData = {
      comp: computed(() => (computedData.isRail.value ? 1 : 16 / 4)),
      isRail: computed({
        get() {
          if (computedData.isMobile.value == true) {
            return false;
          }

          return store.state.isRailNavigationDrawer;
        },
        set(value) {
          store.commit("setIsRailNavigationDrawer", value);
        },
      }),
      user: computed(() => {
        return store.state.user;
      }),
      theme: computed(() => {
        return store.state.theme ?? "light";
      }),
      isMobile: computed(() => {
        return store.state.mobile;
      }),
      logoImg: computed(() => {
        if (computedData.isRail.value == true) {
          return self().$imgurl("utils/logo_mini_white.png");
        } else {
          return self().$imgurl("utils/logo_white.png");
        }
      }),
    };

    watch(
      () => computedData.user.value,
      (newValue, oldValue) => {
        getSections();
      }
    );

    async function getSections() {
      state.sections = [];
      state.loadingSections = true;
      self()
        .$http.get(`admin/sections`, { params: { perPage: 50 } })
        .then((r) => {
          state.loadingSections = false;
          state.sections = r?.data?.items?.data || [];
        })
        .catch((e) => {
          state.loadingSections = false;
          store.commit("showMessage", {
            type: "error",
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
    }

    async function logout() {
      state.loadingLogout = true;
      self()
        .$http.post(`auth/logout`)
        .then((r) => {
          state.loadingLogout = false;
        })
        .catch((e) => {
          state.loadingLogout = false;
          store.commit("showMessage", {
            type: "error",
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
      localStorage.removeItem("tk");
      self().$resetAxiosConfig();
      store.commit("setUser", null);
      router.replace("/auth");
    }

    getSections();

    return {
      state,
      ...computedData,
      logout,
    };
  },
};
</script>

<style scoped>
.hide-scroll >>> .v-navigation-drawer__content {
  overflow: hidden;
}

.no-padding {
  padding: 0 !important;
}
.nav__item {
  text-align: left;
}
.nav__item >>> .v-icon {
  transform: translateX(25%);
}
.nav__item.not__rail >>> .v-icon {
  margin-right: 1rem !important;
  transform: translateX(0);
}
</style>