<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title> </v-card-title>
      <v-card-text>
        <div class="d-flex justify-start mb-5 me-4">
          <v-sheet class="ma-1 pa-1">
            <v-btn
              color="primary"
              class="mb-2"
              :to="{ name: 'jornals.create' }"
            >
              Adicionar Jornal
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-sheet>
        </div>

        <div>
          <h1>Jornal de Ofertas</h1>
        </div>

        <v-data-table
          :headers="headers"
          :items="jornals"
          :server-items-length="jornals.length"
          :itemsPerPage="jornals.length"
          hide-default-footer
          return-object
        >
          <template v-slot:[`item.img`]="{ item }">
            <a :href="$imgurl(item.value.url)" target="_blank"> Ver PDF </a>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.value.name ?? "Sem nome" }}
          </template>
          <template v-slot:[`item.active`]="{ item }">
            {{ item.value.active == 1 ? "Ativo" : "Desativado" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-right">
              <v-btn
                variant="tonal"
                size="small"
                class="mr-2"
                :to="{ name: 'jornals.edit', params: { id: item.value._id } }"
              >
                <v-icon>mdi-pencil</v-icon>
                editar
              </v-btn>

              <v-btn
                variant="tonal"
                color="error"
                size="small"
                class="mr-2"
                @click="destroyJornal(item.value)"
              >
                <v-icon>mdi-delete</v-icon>
                remover
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script >
export default {
  data() {
    return {
      isOpenDialog: false,
      isLoading: false,
      product: null,
      jornals: [],
      headers: [
        {
          title: "Imagem",
          key: "img",
          sortable: true,
          align: "start",
        },
        {
          title: "Nome",
          key: "name",
          sortable: true,
          align: "start",
        },
        {
          title: "",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  mounted() {
    this.listJornals();
  },
  methods: {
    editProduct(item) {
      this.product = item;
      this.isOpenDialog = true;
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.product = null;
      this.listJornals();
    },
    listJornals() {
      this.isLoading = true;
      this.$http
        .get("/admin/news")
        .then((response) => {
          this.isLoading = false;
          this.jornals = response.data.items.data;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    destroyJornal(item) {
      if (confirm(`Você deseja realmente remover o jornal ${item.name}?`)) {
        this.isLoading = true;
        this.$http
          .delete(`/admin/news/${item._id}`)
          .then((response) => {
            this.isLoading = false;
            this.listJornals();

            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Jornal removido com sucesso!",
              group: "app",
            });
          })
          .catch((e) => {
            this.isLoading = false;
            this.$store.commit("showMessage", {
              type: "error",
              duation: 5000,
              text:
                e?.response?.data?.message ||
                "Ocorreu um erro, tente novamente!",
              group: "app",
            });
          });
      }
    },
  },
};
</script>

<style>
</style>