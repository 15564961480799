<template>
    <v-container>
        <v-progress-linear
            color="dark-blue"
            model-value="20"
            :height="4"
            v-if="!editItem"
        ></v-progress-linear>
        
        <v-card v-else>
            <v-card-title>
                <h2 class="text-left">Editar loja</h2>
            </v-card-title>
            <v-card-text>
                <v-progress-circular
                  color="dark-blue"
                  model-value="20"
                  :size="60"
                  :width="8"
                    v-if="!editItem"
                ></v-progress-circular>
                <StoreForm :editItem="editItem" v-if="editItem" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import StoreForm from './Form.vue';

export default {
    components: { StoreForm },

    data() {
        return {
            id: null,
            editItem: null,
        }
    },

    mounted() {
        this.id = this.$route.params.id;
        this.getStoreData();
    },

    methods: {
        getStoreData() {
            this.$http.get(`/admin/stores/${this.id}`)
                .then(response => {
                    this.editItem = response.data.data;
                })
                .catch(err => {
                    this.$store.commit("showMessage", {
                        type: 'error',
                        duration: 5000,
                        text: err.response.data.message,
                        group: 'app'
                    });
                });
        }
    }
}
</script>

<style>

</style>