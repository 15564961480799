
<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title>
        <div class="d-flex justify-space-between align-center">
          <div>
            <span class="text-xl">Lista Ajuda APP</span>
          </div>

          <v-btn
            color="primary"
            :to="{ name: 'apphelp.create' }"
            variant="elevated"
          >
            <v-icon>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </div>
      </v-card-title>
      <div class="d-flex justify-start mb-5 me-4">
        <v-text-field
          label="Procurar"
          v-model="search"
          :clearable="true"
        ></v-text-field>
      </div>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :itemsPerPage="items.length"
          class="elevation-1"
          return-object
        >
          <template v-slot:[`item.title`]="{ item }">
            <span v-html="item.value.title"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              :to="{ name: 'apphelp.edit', params: { id: item.value._id } }"
            >
              <v-icon>mdi-pencil</v-icon>
              Editar
            </v-btn>
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              color="red"
              :loading="loadingDeletes[item.value._id]"
              @click.capture.prevent="deleteItem(item.value)"
            >
              <v-icon>mdi-close</v-icon>
              Excluir
            </v-btn>
          </template>

          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import * as debounce from "lodash.debounce";
export default {
  name: "Index",
  data() {
    return {
      loading: false,
      loadingDeletes: [],
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
      items: [],
      headers: [
        {
          title: "Título",
          align: "start",
          sortable: false,
          key: "title",
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  watch: {
    "pagination.page": function () {
      if (this.loading) return;
      this.getList();
    },
    search() {
      this.debounceInput(this.prepareSearch);
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    async getList() {
      this.loading = false;
      const params = {
        search: this.search,
        ...this.pagination,
      };
      await this.$http
        .get("/admin/apphelp", { params })
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.pagination.page = response?.data?.items?.current_page;
          this.pagination.pageCount = response?.data?.items?.last_page;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    async deleteItem(item) {
      if (this.loadingDeletes[item._id]) return;
      if (window.confirm(`Deseja realmente excluir o item?`)) {
        this.loadingDeletes[item._id] = true;
        await this.$http
          .delete(`/admin/apphelp/${item._id}`)
          .then((r) => {
            this.loadingDeletes[item._id] = false;
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Ajuda excluída com sucesso",
              group: "app",
            });

            this.getList();
          })
          .catch((e) => {
            this.loadingDeletes[item._id] = false;
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message || e,
              group: "app",
            });
          });
      }
    },
  },
};
</script>