<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title class="text-left">
        <span class="text-xl">Lista de Membros</span>
      </v-card-title>
      <div class="d-flex justify-start mb-5 me-4">
        <v-text-field
          label="Procurar"
          v-model="search"
          :clearable="true"
        ></v-text-field>
      </div>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :itemsPerPage="items.length"
          class="elevation-1"
          return-object
          :loading="loading"
          loading-text="Carregando"
          no-data-text="Nada aqui por enquanto"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div class="content__name">
              <v-avatar
                size="3rem"
                :image="
                  $imgurl(
                    item?.value?.user?.photo_full ?? 'utils/genericuser.png'
                  )
                "
                class="mr-2"
              ></v-avatar>
              <p>{{ item.value?.user?.name }}</p>
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item?.value?.user?.email }}
          </template>
          <template v-slot:[`item.subscription_status`]="{ item }">
            <div
              class="ball_indicator"
              :style="{
                backgroundColor: item?.value?.subscription_status
                  ? 'green'
                  : 'red',
              }"
            ></div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              :to="{
                name: 'club.members.show',
                params: { id: item.value._id },
              }"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import * as debounce from "lodash.debounce";
export default {
  name: "Index",
  data() {
    return {
      loading: false,
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
      items: [],
      headers: [
        {
          title: "Nome",
          align: "start",
          sortable: false,
          key: "name",
        },
        {
          title: "E-mail",
          align: "start",
          sortable: false,
          key: "email",
        },
        {
          title: "Status",
          align: "start",
          sortable: false,
          key: "subscription_status",
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  watch: {
    "pagination.page": function () {
      if (this.loading) return;
      this.getList();
    },
    search() {
      this.debounceInput(this.prepareSearch);
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    async getList() {
      if (this.loading) return;
      try {
        this.loading = true;
        const params = {
          club_fields: 1,
          club_members: 1,
          search: this.search,
          ...this.pagination,
        };
        await this.$http
          .get("/admin/club", { params })
          .then((r) => {
            this.loading = false;
            this.items = r?.data?.items?.data ?? [];
            this.pagination.page = r?.data?.items?.current_page;
            this.pagination.pageCount = r?.data?.items?.last_page;
          })
          .catch((e) => {
            this.loading = false;
            this.$store.commit("showMessage", {
              type: "error",
              duation: 5000,
              text:
                e?.response?.data?.message ||
                "Ocorreu um erro, tente novamente!",
              group: "app",
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.ball_indicator {
  width: 1rem;
  height: 1rem;
  border-radius: 3600px;
}
.content__name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content__name p {
  line-height: 1;
  margin: unset;
}
</style>