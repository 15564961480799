<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-title>
        <div class="d-flex justify-space-between mb-5">
          <v-btn color="primary" class="mb-2" :to="{ name: 'sections.create' }">
            Adicionar sessão
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="sections"
          :server-items-length="sections.length"
          :itemsPerPage="sections.length"
          hide-default-footer
          return-object
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              :to="{ name: 'sections.edit', params: { id: item.value._id } }"
            >
              <v-icon>mdi-pencil</v-icon>
              Editar
            </v-btn>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormSection from "./Form.vue";

export default {
  components: { FormSection },
  data() {
    return {
      isLoading: false,
      section: null,
      sections: [],
      headers: [
        {
          title: "Nome",
          key: "name",
          sortable: false,
          align: "start",
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  mounted() {
    this.listSections();
  },
  methods: {
    async listSections() {
      try {
        const response = await this.$http.get("/admin/sections");
        this.sections = response.data.items.data;
        console.log("Stores", this.stores, this.sections[0].name);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>