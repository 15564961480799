<template>
  <div
    class="shipment__method"
    :style="`background-color: ${value == 1 ? 'blue' : 'green'}`"
  >
    <v-icon size="small" class="ml-1" color="white">
      mdi-{{ value == 1 ? "walk" : "motorbike" }}
    </v-icon>
    <span>
      {{ value == 1 ? "retirada" : "entrega" }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
.shipment__method {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 3600px;
  padding: 2px 5px;
}
.shipment__method span {
  color: white;
  font-size: .8rem;
  margin-left: 3px;
}
</style>