<template>
  <v-container>
    <v-card>
      <!-- <v-progress-linear
        indeterminate
        color="primary"
      ></v-progress-linear> -->

      <v-card-title>
        <h4 class="text-left">Novo Produto</h4>
      </v-card-title>
      <v-card-text>
        <ProductForm :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Form from "./Form.vue";

export default {
  components: {
    ProductForm: Form,
  },

  data() {
    return {
      id: null,
      editItem: null,
    };
  },

  created() {
    this.id = this.$route.params.id;
  },

  methods: {},
};
</script>

<style>
</style>