<template>
  <Create :level="1" :indexpathname="'users.superadmin.index'" />
</template>
  
<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    Create: defineAsyncComponent(() => import("../000Base/Create.vue")),
  },
};
</script>
  
  <style>
</style>