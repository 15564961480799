<template>
  <h1>{{ teste }}</h1>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const teste = ref('teste')
</script>

<style>

</style>