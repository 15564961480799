<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4 class="text-left">Nova Seção</h4>
      </v-card-title>
      <v-card-text>
        <FormSection :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormSection from './Form.vue';

export default {
components: { FormSection },
  data() {
    return {
      isOpenDialog: false,
      editItem: null,
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>

<style>

</style>