<template>
  <v-container v-if="level != null">
    <v-card :loading="loading">
      <v-card-title>
        <div class="d-flex justify-space-between">
          <span class="text-h5 text-left">{{ pageTitle }}</span>

          <v-btn
            color="primary"
            variant="elevated"
            @click="() => $router.push({ name: createpathname })"
          >
            Cadastrar <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <div class="d-flex justify-start mb-5 me-4">
        <v-text-field
          label="Procurar"
          v-model="search"
          :clearable="true"
        ></v-text-field>
      </div>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :itemsPerPage="items.length"
          class="elevation-1"
          return-object
          :loading="loading"
          loading-text="Carregando"
          no-data-text="Nada por aqui por enquanto"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <DeliverymenTracking
              v-if="item.value?.level == 6"
              :id="item.value._id"
              :btnprops="{
                density: 'compact',
                class: {
                  'mr-2': true,
                },
              }"
            />
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              :to="{
                name: editpathname,
                params: { id: item.value._id },
              }"
            >
              <v-icon>mdi-pencil</v-icon>
              Editar
            </v-btn>
            <v-btn
              variant="tonal"
              size="small"
              class="mr-2"
              color="red"
              :loading="loadingDeletes[item.value._id]"
              @click.capture.prevent="deleteItem(item.value)"
            >
              <v-icon>mdi-close</v-icon>
              Excluir
            </v-btn>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  props: {
    level: {
      type: Number,
      default: null,
    },
    createpathname: {
      type: String,
      default: null,
    },
    editpathname: {
      type: String,
      default: null,
    },
  },
  components: {
    DeliverymenTracking: defineAsyncComponent(() =>
      import("@/components/DeliverymenTracking/DeliverymenTracking.vue")
    ),
  },
  name: "Index",
  data() {
    return {
      items: [],
      headers: [
        {
          title: "Nome",
          align: "start",
          sortable: false,
          key: "name",
        },
        {
          title: "E-mail",
          align: "start",
          sortable: false,
          key: "email",
        },
        {
          title: "Telefone",
          key: "phone",
          align: "start",
          sortable: false,
        },
        {
          title: "Ações",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
      loading: false,
      loadingDeletes: [],
    };
  },

  computed: {
    pageTitle() {
      const titles = {
        0: "Lista de Usuários APP",
        1: "Lista de Super Administradores",
        2: "Lista de Administradores do Painel",
        3: "Lista de Gerentes de Loja",
        4: "Lista de Seção",
        5: "Lista Funcionários",
        6: "Lista de Entregadores",
      };
      return titles[this.level ?? 0];
    },
  },

  watch: {
    "pagination.page": function () {
      if (this.loading) return;
      this.getList();
    },
    search() {
      this.$debounce("personslist", this.prepareSearch);
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.getList();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    async getList() {
      if (this.loading) return;
      this.loading = true;
      let params = {
        level: this.level,
        search: this.search,
        ...this.pagination,
      };

      await this.$http
        .get("/admin/users", { params })
        .then((response) => {
          this.loading = false;
          this.items = response?.data?.items?.data ?? [];
          this.pagination.page = response?.data?.items?.current_page;
          this.pagination.pageCount = response?.data?.items?.last_page;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ?? "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
    },
    async deleteItem(item) {
      if (this.loadingDeletes[item?._id]) return;
      if (
        window.confirm(
          `Deseja realmente excluir o cadastro de ${
            item?.name ?? item?.email ?? item?._id
          }? Esta ação é IRREVERSÍVEL!`
        )
      ) {
        this.loadingDeletes[item._id] = true;
        await this.$http
          .delete(`/admin/users/${item._id}`)
          .then((r) => {
            this.loadingDeletes[item._id] = false;
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Ajuda excluída com sucesso",
              group: "app",
            });

            this.getList();
          })
          .catch((e) => {
            this.loadingDeletes[item._id] = false;
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message || e,
              group: "app",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
</style>