<template>
  <div class="text-left my-4">
      <span class="mr-3">
        <strong>Período: </strong>
      </span>
    <v-btn
        color="primary"
        variant="elevated"
    >
      <v-icon>mdi-calendar</v-icon> Últimos 7 dias
    </v-btn>
  </div>

  <div class="d-flex justify-space-between align-center mb-4">
    <span class="text-h4">Dados do clube</span>
    <v-btn
        variant="outlined"
        color="primary"
    >
      <v-icon>mdi-download</v-icon>
      <span>Baixar relatório do clube</span>
    </v-btn>
  </div>

  <div class="d-flex justify-start align-center mb-4">
    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>100</h4>
      <p>Usuários cadastrados</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>50</h4>
      <p>Usuários assinantes</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>10</h4>
      <p>Cancelamentos de assinatura</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>R$ 500,00</h4>
      <p>Receita das assinaturas</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>20%</h4>
      <p>Indice de cancelamento</p>
    </div>
  </div>

  <v-card class="mb-4">
    <v-card-title>
      <div class="d-flex justify-space-between align-center">
        <span class="text-h5">Usuários cadastrados no clube</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-table>
        <thead>
        <tr>
          <th>Nome</th>
          <th>Tempo de assinatura</th>
          <th>Desconto resgatados</th>
          <th>Ações</th>
        </tr>
        </thead>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>