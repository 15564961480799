<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title> </v-card-title>

      <v-card-text>
        <div class="d-flex justify-start mb-5 me-4">
          <v-sheet class="ma-1 pa-1">
            <v-btn
              color="primary"
              class="mb-2"
              :to="{ name: 'products.create' }"
            >
              Adicionar Produto
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-sheet>

          <!-- <v-sheet class="ma-1 pa-1">
            <v-btn
              color="primary-outline"
              class="mb-2"
              :to="{ name: 'products.create' }"
            >
              Ações em massa &nbsp;
              <v-icon>mdi-cards-variant</v-icon>
            </v-btn>
          </v-sheet> -->
        </div>

        <div class="d-flex justify-start mb-5 me-4">
          <v-text-field
            label="Procurar"
            v-model="search"
            :clearable="true"
          ></v-text-field>
        </div>

        <v-data-table
          :headers="headers"
          :items="products"
          :server-items-length="products.length"
          :itemsPerPage="products.length"
          :hide-default-footer="true"
          return-object
          :loading="isLoading"
          loading-text="Carregando"
          no-data-text="Nada por aqui por enquanto"
        >
          <template v-slot:[`item.price`]="{ item }">
            <div class="multiline__item">
              <span>{{
                Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.value.price)
              }}</span>
              <span>
                <small>{{
                  `Clube: ${Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }).format(item.value.price_loyalty)}`
                }}</small>
              </span>
            </div>
          </template>

          <template v-slot:[`item.sections`]="{ item }">
            {{ item?.value?.sections?.map((s) => s.name)?.join(",") ?? "--" }}
          </template>

          <template v-slot:[`item.categories`]="{ item }">
            {{ item?.value?.categories?.map((s) => s.name)?.join(",") ?? "--" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-right">
              <CloneProduct :id="item.value._id" />

              <v-btn
                variant="tonal"
                size="small"
                class="mr-2"
                :to="{ name: 'products.edit', params: { id: item.value._id } }"
              >
                <v-icon>mdi-pencil</v-icon>
                ver/editar
              </v-btn>

              <v-btn
                variant="tonal"
                color="error"
                size="small"
                class="mr-2"
                @click="destroyProduct(item.value._id, item.value.name)"
              >
                <v-icon>mdi-delete</v-icon>
                remover
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import * as debounce from "lodash.debounce";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    CloneProduct: defineAsyncComponent(() => import("./CloneProduct.vue")),
  },
  data() {
    return {
      isOpenDialog: false,
      isLoading: false,
      product: null,
      products: [],
      search: null,
      pagination: {
        pageCount: null,
        page: null,
      },
      headers: [
        {
          title: "Nome",
          key: "name",
          sortable: true,
          align: "start",
        },
        {
          title: "Preço",
          key: "price",
          sortable: true,
          align: "end",
        },
        {
          title: "Seção(ões)",
          key: "sections",
          sortable: true,
          align: "end",
        },
        {
          title: "Categoria(s)",
          key: "categories",
          sortable: true,
          align: "end",
        },
        {
          title: "",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    sectionIDRouteParam() {
      return this.$route.params.section_id;
    },
  },
  watch: {
    "pagination.page": function () {
      if (this.isLoading) return;
      this.listProducts();
    },
    search() {
      this.debounceInput(this.prepareSearch);
    },
  },
  created() {},
  mounted() {
    console.log("mounted called");
    this.listProducts();
  },
  methods: {
    debounceInput: debounce((functionRef) => {
      functionRef();
    }, 650),
    editProduct(item) {
      this.product = item;
      this.isOpenDialog = true;
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.product = null;
      this.listProducts();
    },
    prepareSearch() {
      if (this.pagination?.page == null) {
        this.listProducts();
      } else {
        this.pagination.page = null;
        this.pagination.pageCount = null;
      }
    },
    listProducts() {
      this.isLoading = true;
      this.$http
        .get("/admin/products", {
          params: {
            search: this.search,
            ...this.pagination,
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.products = response?.data?.items?.data ?? [];
          this.pagination.page = response?.data?.items?.current_page;
          this.pagination.pageCount = response?.data?.items?.last_page;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ?? "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
    },

    destroyProduct(id, name) {
      if (confirm(`Você deseja realmente remover ${name}? (id ${id})`)) {
        this.isLoading = true;
        this.$http
          .delete(`/admin/products/${id}`)
          .then((response) => {
            this.isLoading = false;
            this.listProducts();

            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Produto removido com sucesso!",
              group: "app",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.multiline__item {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
</style>