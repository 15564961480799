<template>
  <v-container>
    <v-card>
      <!-- progress linear -->
      <v-progress-linear
        v-if="!variant"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title>
        <h4 class="text-left">Novo item da variação</h4>
      </v-card-title>
      <v-card-text>
        <VariantItemForm :editItem="editItem" :variant="variant" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VariantItemForm from "./Form.vue";

export default {
  components: { VariantItemForm },
  data() {
    return {
      productId: null,
      variationId: null,
      variant: null,
      editItem: {
        _id: null,
        product_variation_set_id: null,
        name: null,
        description: null,
        type: null,
        selection_limit: null,
        price: null,
      },
    };
  },

  mounted() {
    this.productId = this.$route.params.productId;
    this.variationId = this.$route.params.variationId;

    this.getVariant();
  },

  methods: {
    async getVariant() {
      await this.$http
        .get(
          `/admin/products/${this.productId}/variations-sets/${this.variationId}`
        )
        .then((res) => {
          this.variant = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>