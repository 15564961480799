<template>
  <v-form
    ref="formData"
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <div class="d-flex justify-space-between mb-4">
      <div>
        <v-btn color="primary" type="submit" class="mr-4 my-2" size="small">
          Salvar &nbsp;
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </div>
    <v-tabs v-model="tab" class="mb-8" color="error">
      <v-tab value="details">Informações do Banner</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="details">
        <v-row no-gutters>
          <v-col cols="12">
            <a :href="$imgurl(state.localData.img)" target="_blank">
              <v-img
                :src="$imgurl(state.localData.img)"
                :aspect-ratio="comp"
                width="50%"
                class="mx-auto my-8"
              ></v-img>
            </a>
          </v-col>

          <v-col cols="12" class="pt-4">
            <p class="mb-2">
              {{
                state.localData.img == null
                  ? "Imagem do banner (obrigatória)"
                  : "Imagem do banner já selecionado. Se desejar você pode altera-la."
              }}
            </p>
            <div class="px-6">
              <UploadComponent
                class="mt-2 mb-6"
                :limit="1"
                :data="state.localData"
                :mini="state.localData.img"
                :field="'image'"
                :field_small="'image_small'"
                style="height: 100px"
                v-on:update_path="recebeUrlImage"
              ></UploadComponent>
            </div>

            <p class="text-left px-5 mt-2">
              Formatos aceitos: <strong>JPEG, JPG e PNG</strong><br />
              Peso máximo: <strong>20 MB</strong><br />
              Resolução mínima: <strong>300x275</strong>
            </p>
          </v-col>

          <v-col cols="12" class="pt-4">
            <v-text-field
              variant="outlined"
              v-model="state.localData.name"
              label="Nome do banner (obrigatório)"
              density="compact"
            ></v-text-field>

            <v-text-field
              variant="outlined"
              v-model="state.localData.url"
              label="Url"
              density="compact"
            ></v-text-field>

            <v-select
              variant="outlined"
              v-model="state.localData.section_id"
              label="Seção"
              density="compact"
              :items="sections"
              item-title="name"
              item-value="_id"
              clearable
            ></v-select>

            <v-select
              v-if="!state.localData.section_id"
              variant="outlined"
              v-model="state.localData.type"
              label="Tipo"
              density="compact"
              :items="typeBanner"
              item-title="name"
              item-value="value"
              clearable
            ></v-select>

            <v-select
              variant="outlined"
              v-model="state.localData.active"
              label="Ativo"
              density="compact"
              :items="sectionsActive"
              item-title="name"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-form>
</template>

<script>
import UploadComponent from "@/components/UploadComponent.vue";

export default {
  components: {
    UploadComponent,
  },
  props: {
    editItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tab: null,
      title: this.editItem?._id ? "Editar Banner" : "Novo Banner",
      method: this.editItem?._id ? "put" : "post",
      saveUrl: this.editItem?._id
        ? `/admin/banners/${this.editItem._id}`
        : "/admin/banners",
      sections: [],
      sectionsActive: [
        { name: "Sim", value: 1 },
        { name: "Não", value: 0 },
      ],
      typeBanner: [
        { name: "Home", value: "Outros" },
        { name: "Club", value: "Club" },
      ],
      state: {
        loading: false,
        formModel: null,
        localData: {
          _id: null,
          name: null,
          type: null,
          img: null,
          section_id: null,
          active: null,
        },
      },
    };
  },

  mounted() {
    this.listSections();
    this.setTabSelected();
    this.setLocalData();
    console.log("Dados pra editar", this.editItem);
  },

  watch: {},

  methods: {
    setLocalData() {
      if (this.editItem?._id) {
        this.state.localData = {
          ...this.editItem,
        };
      }
    },

    setTabSelected() {
      if (this.$route.query.tab) {
        const tabs = ["price_and_stock", "details", "variants", "photos"];
        if (tabs.includes(this.$route.query.tab)) {
          this.tab = this.$route.query.tab;
        }
      }
    },

    async save() {
      this.state.loading = true;

      const validation = await this.$refs.formData?.validate();
      if (validation.valid) {
        let data = { ...this.state.localData };
        console.log("Dados para enviar ao backend", data);

        await this.$http[this.method](this.saveUrl, data)
          .then((r) => {
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Banner salvo com sucesso",
              group: "app",
            });

            this.$emit("close-dialog");
            this.$router.push({ name: "Banners" });
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message,
              group: "app",
            });
          });
        console.log("erros de validacao do form");
      } else {
        this.$store.commit("showMessage", {
          type: "error",
          duration: 5000,
          text: "Preencha todos os dados corretamente",
          group: "app",
        });
      }
      this.state.loading = false;
    },
    recebeUrlImage(data) {
      console.log("Recebido evento do component de upload", data);
      this.state.localData.img = data;
    },
    async listSections() {
      await this.$http
        .get("/admin/sections/list-all")
        .then((r) => {
          this.sections = r.data.data;
        })
        .catch((e) => {
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },
  },
};
</script>

<style>
</style>