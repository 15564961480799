<template>
  <v-container>
    <v-card>
      <v-card-title class="text-left">
        <span class="text-h5">{{ pageTitle }}</span>
      </v-card-title>
      <v-card-text>
        <DeliverymenForm :editItem="editItem" :level="level" :indexpathname="indexpathname" />
        <router-view></router-view>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DeliverymenForm from "./Form.vue";
export default {
  name: "Create",
  props: {
    level: {
      type: Number,
      default: null,
    },
    indexpathname: {
      type: String,
      default: null,
    },
  },
  components: {
    DeliverymenForm,
  },
  data() {
    return {
      editItem: null,
    };
  },
  computed: {
    pageTitle() {
      const titles = {
        0: "Cadastrar Usuário APP",
        1: "Cadastrar Super Administrador",
        2: "Cadastrar Administrador do Painel",
        3: "Cadastrar Administrador de Loja",
        4: "Cadastrar Gerente",
        5: "Cadastrar Funcionário",
        6: "Cadastrar Entregador",
      };
      return titles[this.level ?? 0];
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>