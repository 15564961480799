<template>
  <div class="datepicker__content">
    <Datepicker
      v-model="data"
      locale="pt-BR"
      position="left"
      :teleport="true"
      teleport-center
      allow-prevent-default
      label="aqui"
      v-bind="{
        ...datepickerProps,
        clearable,
        disabled,
        readonly,
        required,
      }"
      :transitions="false"
    >
      <template #dp-input="{}">
        <v-text-field
          class="text__field"
          v-model="dataModel"
          @click:clear="clearAll"
          v-bind="{
            ...textFieldProps,
            clearable,
            disabled,
            readonly,
            required,
          }"
        ></v-text-field>
      </template>
    </Datepicker>
  </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import { defineAsyncComponent } from "vue";
import moment from "moment";
export default {
  props: {
    // for v-model
    modelValue: {
      default: null,
    },
    // let it null in case of object type date v-model
    format: {
      type: String,
      default: null,
    },
    formatoptions: {
      type: Object,
      default: null,
    },
    // start section common props
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    // end section common props
    // start section specific props
    datepickerProps: {
      type: Object,
      default: {},
    },
    textFieldProps: {
      type: Object,
      default: {},
    },
    // end section specific props
  },
  components: {
    Datepicker: defineAsyncComponent(() => import("@vuepic/vue-datepicker")),
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    dataModel: {
      get() {
        let v = this.modelValue;
        return this.$formatDate(
          v ? this.getValue(v) : null,
          "",
          this.formatoptions ? false : true,
          this.formatoptions
        );
      },
      set(_) {},
    },
  },
  watch: {
    data(v) {
      this.$emit(
        "update:modelValue",
        this.getValue(v, this.format ? true : false)
      );
    },
    modelValue() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.getValue(this.modelValue);
    },
    getValue(v, asString = false) {
      if (!v) return null;
      if (this.format && v) {
        const dt = moment(v, this.format);
        if (asString) return dt.format(this.format);
        return dt;
      }
      return v ? moment(v) : v;
    },
    clearAll() {
      this.data = null;
    },
  },
};
</script>

<style scoped>
.datepicker__content {
  display: flex;
  flex-direction: column;
}
.datepicker__content >>> * {
  box-sizing: inherit;
}
.text__field {
  width: 100%;
}
.datepicker__data
  >>> .dp__outer_menu_wrap
  .dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  position: relative !important;
  top: 0 !important;
  transform: none !important;
  left: 0 !important;
}
</style>