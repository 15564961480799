<template>
  <v-dialog
    v-model="dialog"
    width="95vw"
    max-width="375px"
    v-if="id"
    :persistent="loading"
  >
    <template #activator="{ props }">
      <v-btn variant="tonal" size="small" class="mr-2" v-bind="props">
        <v-icon>mdi-content-copy</v-icon>
        Clonar
      </v-btn>
    </template>
    <v-card width="100%" :loading="loading" :disabled="loading">
      <v-card-title>Clonar produto</v-card-title>
      <v-card-text>
        <v-form v-model="form" ref="formData">
          <v-text-field
            v-model="newName"
            variant="outlined"
            label="Nome do novo produto"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="text" color="white" @click="dialog = false">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          :loading="loading"
          :disabled="loading"
          @click="cloneProduct()"
        >
          Clonar Produto
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      dialog: false,
      loading: false,
      form: null,
      newName: null,
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.newName = null;
      }
    },
  },
  methods: {
    async cloneProduct() {
      if (!this.id) return;
      if (this.loading) return;
      const validation = await this.$refs.formData?.validate();
      if (!validation.valid) return;
      this.loading = true;
      const params = {
        name: this.newName,
      };
      await this.$http
        .post(`/admin/products/${this.id}/clone`, params)
        .then(async (res) => {
          this.loading = false;
          await this.$router.replace({
            name: "products.edit",
            params: { id: res?.data?.id },
            query: { tab: "details" },
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e?.response?.data?.message ?? "Tente novamente mais tarde",
            group: "app",
          });
        });
    },
  },
};
</script>

<style>
</style>