<template>
  <div>
    <v-text-field
      ref="inputRef"
      v-model="formattedValue"
      :label="label"
      :rules="rules"
      :density="density"
      :variant="variant"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :clearable="clearable"
      @input="updateValue"
      @click:clear="($event) => updateValue($event, true)"
      prefix="R$"
    />
  </div>
</template>
  
  <script>
import { defineComponent, watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default defineComponent({
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    label: {
      type: String,
      default: "Amount",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    density: {
      type: String,
      default: true,
    },
    variant: {
      type: String,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    precision: {
      type: Number,
      default: 2,
    },
    currencyOptions: {
      type: Object,
      default: () => ({
        locale: "pt-BR",
        currency: "BRL",
        currencyDisplay: "hidden",
        precision: 2,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: true,
        useGrouping: true,
        accountingSign: false,
      }),
    },
  },
  setup(props, { emit }) {
    let currencyOptions = {
      ...props.currencyOptions,
      precision: props.precision,
    };
    const { inputRef, formattedValue, numberValue, setValue } =
      useCurrencyInput(currencyOptions);

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue) {
          const parsed = Number.parseFloat(newValue);
          setValue(parsed);
        }
      }
    );

    if (props.modelValue != null) {
      const parsed =
        Number.parseFloat(props.modelValue) || Number.parseFloat(0);
      setValue(parsed);
    }

    const updateValue = (event, clear) => {
      let v = numberValue.value ? numberValue.value : 0;
      if (clear) {
        v = 0;
        setValue(0);
      }
      emit("update:value", v);
    };

    return {
      inputRef,
      formattedValue,
      updateValue,
    };
  },
});
</script>