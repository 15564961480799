<template>
  <div class="content__home">
    <div class="home">
      <v-img
        :src="
          $imgurl(theme == 'light' ? 'utils/logo.png' : 'utils/logo_white.png')
        "
        width="375px"
      ></v-img>
      <v-progress-linear
        v-if="loadingUser"
        indeterminate=""
        class="mt-2"
        color="red"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "HomeView",
  setup() {
    const store = useStore();
    const computedData = {
      theme: computed(() => {
        return store.state.theme ?? "light";
      }),
      loadingUser: computed(() => {
        return store.state.loadingUser;
      }),
    };
    return {
      ...computedData,
    };
  },
};
</script>

<style scoped>
.content__home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
}
.content__home .home {
  width: 375px;
}
</style>
