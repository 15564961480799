<template>
  <v-form
    ref="form"
    v-model="state.formModel"
    @keypress.enter.prevent="save(false)"
    @submit.prevent="save(false)"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="state.localData.active"
            :true-value="1"
            :false-value="0"
            :label="
              state.localData.active
                ? 'Impressora ativa. Clique para alterar'
                : 'Impressora inativa, clique para alterar'
            "
          ></v-checkbox>
          <v-text-field
            v-model="state.localData.name"
            label="Nome da Impressora"
            variant="outlined"
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) =>
                (v && v.length <= 255) ||
                'Este campo deve ter no máximo 255 caracteres',
            ]"
            density="compact"
          ></v-text-field>

          <v-autocomplete
            v-model="state.localData.store_id"
            :label="'Loja'"
            :chips="true"
            :closable-chips="true"
            :clearable="true"
            :items="stores"
            item-title="name"
            item-value="_id"
            v-model:search="searchStore"
            no-data-text="Nenhum resultado por enquanto"
            :loading="loadingStores"
            :rules="[(v) => !!v || 'Campo obrigatório!']"
            variant="outlined"
            density="compact"
          ></v-autocomplete>

          <v-autocomplete
            v-model="state.localData.section_id"
            :label="'Seção (opcional)'"
            :chips="true"
            :closable-chips="true"
            :clearable="true"
            :items="sections"
            item-title="name"
            item-value="_id"
            v-model:search="searchSection"
            no-data-text="Nenhum resultado por enquanto"
            :loading="loadingSections"
            variant="outlined"
            density="compact"
          ></v-autocomplete>

          <v-select
            v-model="state.localData.manufacturer"
            label="Fabricante"
            variant="outlined"
            density="compact"
            :items="manufacturers"
            :loading="loadingManufacturers"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-select>
          <v-text-field
            v-model="state.localData.host"
            label="Endereço (Ip ou Link, sem o protocolo)"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
          <v-text-field
            v-model="state.localData.port"
            label="Porta (padrão 9100)"
            variant="outlined"
            density="compact"
            type="number"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
          <v-text-field
            v-model="state.localData.width"
            label="Largura de Impressão"
            variant="outlined"
            density="compact"
            type="number"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
          <v-select
            v-model="state.localData.font_type"
            label="Tipo de Fonte"
            variant="outlined"
            density="compact"
            :items="fontTypes"
            item-value="value"
            item-title="name"
            :rules="[(v) => v != null || 'Campo obrigatório']"
          ></v-select>
          <v-text-field
            v-model="state.localData.characters_font_1"
            label="Caracteres por linha na fonte 1"
            variant="outlined"
            density="compact"
            type="number"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
          <v-text-field
            v-model="state.localData.characters_font_2"
            label="Caracteres por linha na fonte 2"
            variant="outlined"
            density="compact"
            type="number"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
          <v-text-field
            v-model="state.localData.characters_font_3"
            label="Caracteres por linha na fonte 3"
            variant="outlined"
            density="compact"
            type="number"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        variant="text"
        @click.prevent.stop.capture="save(true)"
        :loading="state.loadingTest"
      >
        Testar Configurações
      </v-btn>
      <v-btn
        variant="text"
        :to="{ name: 'printers.index' }"
        :disabled="state.loading"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        variant="elevated"
        :loading="state.loading"
      >
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { vMaska } from "maska";
import { defineAsyncComponent } from "vue";

export default {
  directives: {
    maska: vMaska,
  },

  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    UploadComponent: defineAsyncComponent(() =>
      import("@/components/UploadComponent.vue")
    ),
  },

  data() {
    return {
      title: this.editItem?._id ? "Editar Impressora" : "Nova Impressora",
      method: this.editItem?._id ? "put" : "post",
      urlSave: this.editItem?._id
        ? `/admin/printers/${this.editItem._id}`
        : "/admin/printers",
      state: {
        loading: false,
        loadingTest: false,
        formModel: null,
        localData: this.editItem ?? {
          _id: null,
          active: 1,
          store_id: null,
          section_id: null,
          name: null,
          manufacturer: null,
          host: null,
          port: null,
          width: null,
          font_type: null,
          characters_font_1: null,
          characters_font_2: null,
          characters_font_3: null,
        },
      },
      loadingManufacturers: false,
      manufacturers: [],
      fontTypes: [
        {
          name: "Fonte A",
          value: 0,
        },
        {
          name: "Fonte B",
          value: 1,
        },
        {
          name: "Fonte C",
          value: 2,
        },
      ],
      stores: [],
      searchStore: null,
      loadingStores: false,
      sections: [],
      searchSection: null,
      loadingSections: false,
    };
  },

  computed: {
    manufacturersComputed() {
      return this.manufacturers.map((m) => {
        return {
          id: m,
          name: m,
        };
      });
    },
  },

  watch: {
    searchStore() {
      this.$debounce("searchStore", this.getStores);
    },
    searchSections() {
      this.$debounce("searchSections", this.getSections);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.getManufacturers();
      this.getStores();
      this.getSections();
    },
    async newUrlImage(imageUrl) {
      this.state.localData.img = imageUrl;
    },

    back() {
      this.$router.push({ name: "printers.index" });
    },

    async getManufacturers() {
      this.loadingManufacturers = true;
      await this.$http
        .get("/admin/printers/manufacturers")
        .then((r) => {
          this.loadingManufacturers = false;
          this.manufacturers = r?.data?.items ?? [];
        })
        .catch((e) => {
          this.loadingManufacturers = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text: e.response.data.message,
            group: "app",
          });
        });
    },

    async getSections() {
      if (this.loadingSections) return;
      this.loadingSections = true;
      const params = {
        id: this?.state?.localData?.section_id,
        search: this.searchSection,
      };
      await this.$http
        .get("/admin/sections", { params })
        .then((r) => {
          console.log({ r });
          this.loadingSections = false;
          this.sections = r?.data?.items?.data ?? [];
        })
        .catch((e) => {
          console.log({ e });
          this.loadingSections = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ??
              "Ocorreu um erro, tente novamente mais tarde!",
            group: "app",
          });
        });
    },
    async getStores() {
      if (this.loadingStores) return;
      this.loadingStores = true;
      const params = {
        id: this?.state?.localData?.store_id,
        search: this.searchStore,
      };
      await this.$http
        .get("/admin/stores", { params })
        .then((r) => {
          this.loadingStores = false;
          this.stores = r?.data?.items?.data ?? [];
        })
        .catch((e) => {
          console.log({ e });
          this.loadingStores = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ??
              "Ocorreu um erro, tente novamente mais tarde!",
            group: "app",
          });
        });
    },

    async save(test) {
      let v = await this.$refs.form?.validate();
      if (v.valid) {
        if (this.state[test ? "loadingTest" : "loading"]) return;
        this.state[test ? "loadingTest" : "loading"] = true;
        let data = { ...this.state.localData };
        await (test
          ? this.$http.post("/admin/printers/print-test", data)
          : this.$http[this.method](this.urlSave, data)
        )
          .then((r) => {
            this.state[test ? "loadingTest" : "loading"] = false;
            if (test) {
              this.$store.commit("showMessage", {
                type: "success",
                duration: 5000,
                text: "Aparentemente ok. Veja se a impressora imprimirá algo!",
                group: "app",
              });
            } else {
              this.$store.commit("showMessage", {
                type: "success",
                duration: 5000,
                text: "Impressora salva com sucesso",
                group: "app",
              });
              this.$router.push({ name: "printers.index" });
            }
          })
          .catch((e) => {
            this.state[test ? "loadingTest" : "loading"] = false;
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e?.response?.data?.message ?? e?.response?.message ?? e,
              group: "app",
            });
          });
      }
      this.state[test ? "loadingTest" : "loading"] = false;
    },
  },
};
</script>

<style>
</style>