<template>
  <v-card :loading="loading">
    <v-progress-linear height="16" v-if="loading"></v-progress-linear>
    <v-toolbar
      dense
      color="transparent"
      class="text-left toolbar__data"
      v-else-if="order"
    >
      <v-toolbar-title>
        <v-card-title class="text-left px-0">
          <v-chip :color="color" variant="elevated" size="small">
            <small>{{ statusTitle }}</small>
          </v-chip>
          <h4 class="text-left" style="text-transform: capitalize">
            Cliente:
            {{
              order.user.name?.split(" ")[0]?.toString() ?? "Nome não informado"
            }}
            <br />
            <small>
              Pedido #{{ orderNumber }} - Feito em
              {{ $formatDate(order.created_at, "N/A", true) }}
            </small>
          </h4>
        </v-card-title>
      </v-toolbar-title>
      <v-tooltip location="bottom" :text="'Reimprimir Cupom'">
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            @click="forcePrint()"
            :loading="loadingForcePrint"
            v-bind="props"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-btn icon @click="$store.commit('setOrderId', null)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="text-left" v-if="order">
      <ShipmentMethodVisual :value="order.shipment_method" />
      <p class="mb-2">
        <v-icon>mdi-clock</v-icon>
        Previsão {{ order.shipment_method == 1 ? "retirada" : "entrega" }}:
        {{
          order.shipment_prevision_start && order.shipment_prevision_end
            ? "Entre"
            : ""
        }}
        {{ $formatDate(order.shipment_prevision_start, "-", true)
        }}{{
          order.shipment_prevision_start && order.shipment_prevision_end
            ? " até "
            : ""
        }}{{ $formatDate(order.shipment_prevision_end, "-", true) }}
      </p>
      <p class="mb-2">
        <v-icon>mdi-map</v-icon>
        Endereço: <strong>{{ addressResume }}</strong>
      </p>
      <p class="mb-2">
        <v-icon>mdi-phone</v-icon>
        Contato: <strong>{{ order.user.phone }}</strong>
      </p>

      <!-- Lista de items -->
      <v-card>
        <v-card-text>
          <OrderItem
            v-for="(item, i) in items"
            :key="`item${i}`"
            :item="item"
          />
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <div>
                  <strong>Subtotal</strong>
                </div>
                <div>
                  <strong>
                    {{
                      Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }).format(subtotal)
                    }}
                  </strong>
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                v-if="order.shipment_method != 1"
              >
                <div>
                  <strong>Taxa de Entrega</strong>
                </div>
                <div>
                  <strong>
                    {{
                      Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }).format(shippingPrice)
                    }}
                  </strong>
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                v-if="couponDiscount > 0"
              >
                <div>
                  <strong>Cupom desconto APP</strong>
                </div>
                <div>
                  <strong>
                    {{
                      Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }).format(couponDiscount * -1)
                    }}
                  </strong>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  <strong>Total</strong>
                </div>
                <div>
                  <strong>
                    {{
                      Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }).format(total)
                    }}
                  </strong>
                  <strong v-if="order.cash_payment_need_back_money">
                    (troco para
                    {{
                      Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }).format(cashPaymentBackMoney)
                    }})
                  </strong>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Botoes de aceite e cancelamento de pedido -->
      <v-card-actions>
        <CancelOrder
          :order="order"
          @reloadAll="
            $emit('reloadAll');
            findOrderById();
          "
        />

        <v-spacer></v-spacer>

        <ConfirmOrder
          :order="order"
          @reloadAll="
            $emit('reloadAll');
            findOrderById();
          "
        />

        <div
          v-if="
            [2, 3, 7, 8].includes(order.status) && order.shipment_method == 0
          "
        >
          <DeliverymenTracking
            :id="order?.current_shipment?.user_id"
            :btnprops="{
              density: 'compact',
              class: {
                'mr-2': true,
              },
            }"
          />
          <DriverSelector :order="order" @updateParent="findOrderById()" />
        </div>
      </v-card-actions>
    </v-card-text>
    <v-expansion-panels variant="accordion" v-if="order">
      <v-expansion-panel title="Histórico">
        <v-expansion-panel-text
          v-for="(itemStatus, indexStatus) in order.statuses ?? []"
          :key="`status${indexStatus}`"
          class="expansion__panel__data"
        >
          <div class="expansion__panel__status">
            <span
              class="color__data"
              :style="`background-color: ${
                $orderStatuses?.find((i) => i.key == itemStatus.status)?.color
              }`"
            ></span>
            <span>{{ $formatDate(itemStatus.created_at, "N/A", true) }}</span>
            <p>
              -
              {{
                $orderStatuses?.find((i) => i.key == itemStatus.status)?.title
              }}
            </p>
            <v-tooltip
              v-if="itemStatus.status_motive"
              :text="itemStatus.status_motive"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  size="x-small"
                  min-height="1rem"
                  min-width="1rem"
                  max-height="1rem"
                  max-width="1rem"
                  width="1rem"
                  height="1rem"
                  color="transparent"
                  elevation="0"
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        :title="order.shipments.length > 1 ? 'Entregas' : 'Entrega'"
      >
        <v-expansion-panel-text
          v-for="(itemShipment, indexShipment) in order.shipments ?? []"
          :key="`status${indexShipment}`"
          class="expansion__panel__data"
        >
          <div class="expandion__panel__shipment">
            <p>
              Entregador:
              {{ itemShipment.user?.name ?? itemShipment?.use.email ?? "--" }}
            </p>
            <a
              target="_blank"
              v-for="(itemMedia, indexMedia) in itemShipment.medias"
              :key="`media${indexMedia}`"
              :href="$imgurl(itemMedia.media_url)"
              >{{ itemMedia.media_url }}</a
            >
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "CardDetail",
  components: {
    OrderItem: defineAsyncComponent(() =>
      import("@/components/Orders/OrderItem.vue")
    ),
    ConfirmOrder: defineAsyncComponent(() =>
      import("./components/ConfirmOrder.vue")
    ),
    CancelOrder: defineAsyncComponent(() =>
      import("./components/CancelOrder.vue")
    ),
    ShipmentMethodVisual: defineAsyncComponent(() =>
      import("./components/ShipmentMethodVisual.vue")
    ),
    DriverSelector: defineAsyncComponent(() =>
      import("./components/DriverSelector.vue")
    ),
    DeliverymenTracking: defineAsyncComponent(() =>
      import("@/components/DeliverymenTracking/DeliverymenTracking.vue")
    ),
  },
  data() {
    return {
      dialog: false,
      dialogEntregador: false,
      deliveryTime: null,
      period: null,
      order: null,
      headers: [
        {
          title: "Imagem",
          key: "img_full",
          sortable: false,
          align: "start",
        },
      ],
      loading: false,
      loadingForcePrint: false,
    };
  },
  computed: {
    orderId() {
      return this.$store.state?.orderId || null;
    },
    color() {
      if (this.order?._id) {
        return (
          this.$orderStatuses?.find((i) => i.key == this.order?.status)
            ?.color ?? "grey"
        );
      }
      return "grey";
    },
    orderNumber() {
      let n = this.order?.number || null;
      if (n) {
        return `${n}`.padStart(4, "0");
      }
      return this.order?._id;
    },
    couponDiscount() {
      return this.order?.coupon_discount ?? 0;
    },
    shippingPrice() {
      return this.order?.shipment_price ?? 0;
    },
    subtotal() {
      return this.order?.original_price ?? 0;
    },
    total() {
      return this.order?.final_price ?? 0;
    },
    cashPaymentBackMoney() {
      return this.order?.cash_payment_back_money ?? 0;
    },
    addressResume() {
      let address = "";

      if (this.order.address.street) {
        address += this.order.address.street;
      }
      if (this.order.address.number) {
        address += `, ${this.order.address.number}`;
      }
      if (this.order.address.district) {
        address += ` - ${this.order.address.district}`;
      }
      return address;
    },
    // Return o title of status
    statusTitle() {
      const index = this.$orderStatuses.findIndex(
        (status) => status.key === this.order.status
      );
      return this.$orderStatuses[index].title;
    },
    items() {
      return this.order?.items ?? [];
    },
  },

  watch: {
    orderId(v) {
      this.order = null;
      if (v) {
        this.findOrderById();
      }
    },
  },

  mounted() {},

  methods: {
    async forcePrint() {
      if (this.loadingForcePrint) return;
      if (!this.orderId) return;
      this.loadingForcePrint = true;
      await this.$http
        .post(`/admin/orders/forceprint/${this.orderId}`)
        .then(() => {
          this.loadingForcePrint = false;
          this.$store.commit("showMessage", {
            type: "success",
            duration: 5000,
            text: "Reenviado para a impressora",
            group: "app",
          });
        })
        .catch((e) => {
          this.loadingForcePrint = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ??
              "Erro ao reenviar para a impressora",
            group: "app",
          });
          console.log(e);
        });
    },
    async findOrderById() {
      if (this.loading) return;
      if (!this.orderId) return;
      this.loading = true;
      await this.$http
        .get(`/admin/orders/show/${this.orderId}`)
        .then((response) => {
          this.loading = false;
          this.order = response?.data?.data;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.commit("showMessage", {
            type: "error",
            duration: 5000,
            text:
              e?.response?.data?.message ?? "Erro ao buscar ordem de compra",
            group: "app",
          });
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.toolbar__data >>> * {
  height: max-content !important;
}
.expansion__panel__data >>> * {
  padding: unset;
}
.expansion__panel__status {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.541);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.expansion__panel__status p {
  margin: unset;
  line-height: 1;
  flex: 1;
  text-align: left;
  font-size: 1rem;
}
.expansion__panel__status span {
  width: max-content;
  line-height: 1;
  font-size: 0.8rem;
  font-weight: bold;
}
.expansion__panel__status span:not(.color__data) {
  margin-right: 0.5rem;
}
.expansion__panel__status span.color__data {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 3600rem;
}
.expandion__panel__shipment {
  display: flex;
  flex-direction: column;
}
</style>