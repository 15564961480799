<template>
  <v-container class="fill-height">
    <v-row class="fill-height" justify="center" align="center">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-form v-model="state.formModel" ref="myForm">
          <v-card>
            <div class="img__container">
              <div>
                <v-img :src="$imgurl('utils/logo.png')" width="100%"></v-img>
              </div>
            </div>
            <v-window v-model="state.tab">
              <v-window-item>
                <div class="title__card">Login</div>
                <v-card-text v-if="state.tab == 0">
                  <v-text-field
                    class="no-autocomplete-bg"
                    autocomplete="off"
                    v-bind="$regularStyle"
                    outlined
                    label="E-mail"
                    v-model="state.localData.email"
                  :rules="[
                    (v) => !!v || 'Campo obrigatório',
                    (v) => isValidEmail(v) || 'E-mail Inválido!',
                  ]"
                  ></v-text-field>
                  <v-text-field
                    v-bind="$regularStyle"
                    clearable
                    @keypress.prevent.enter="loading ? null : signIn()"
                    outlined
                    label="Senha"
                    v-model="state.localData.password"
                    :append-icon="
                      state.passwordView ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    :type="state.passwordView ? 'text' : 'password'"
                    @click:append="state.passwordView = !state.passwordView"
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) => (v && v.length >= 6) || 'Senha muito curta',
                    ]"
                  ></v-text-field>
                  <v-btn
                    block
                    :color="$rootColor"
                    :dark="!state.loading"
                    :light="state.loading"
                    class="mb-2 text-white"
                    @click="signIn()"
                    :loading="state.loading"
                    :disabled="state.loading"
                    >Login</v-btn
                  >
                  <v-btn
                    text
                    block
                    @click="state.tab = 1"
                    :disabled="state.loading"
                    class="mb-2"
                    >realizar cadastro</v-btn
                  >
                  <v-dialog
                    :persistent="state.loadingRecovery"
                    width="95vw"
                    max-width="375px"
                    v-model="state.recoveryDialog"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        text
                        block
                        :disabled="state.loading"
                        small
                        >Esqueceu sua senha?</v-btn
                      >
                    </template>
                    <v-card width="100%">
                      <v-card-title>Esqueci minha senha</v-card-title>
                      <v-card-subtitle class="mt-0 subtitle__item">
                        Enviaremos a você um link para redefinição de senha
                      </v-card-subtitle>
                      <v-card-text>
                        <v-text-field
                          v-bind="$regularStyle"
                          outlined
                          label="Insira seu e-mail"
                          v-model="state.localData.email"
                          :rules="[
                            (v) => !!v || 'Campo obrigatório',
                            (v) => isValidEmail(v) || 'E-mail Inválido!',
                          ]"
                        ></v-text-field>
                        <v-btn
                          class="mt-1 text-white"
                          block
                          color="#00968f"
                          :dark="!state.loadingRecovery"
                          :light="state.loadingRecovery"
                          @click="recovery()"
                          :loading="state.loadingRecovery"
                          :disabled="state.loadingRecovery"
                        >
                          Redefinir senha
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-card-text>
              </v-window-item>
              <v-window-item>
                <div class="title__card">Cadastro</div>
                <v-card-text v-if="state.tab == 1">
                  <v-text-field
                    v-bind="$regularStyle"
                    outlined
                    label="Nome"
                    v-model="state.localData.name"
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v &&
                          v.length >= 6 &&
                          v.split(' ')[1] &&
                          v.split(' ')[1] != '') ||
                        'Digite seu nome completo!',
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-bind="$regularStyle"
                    outlined
                    label="E-mail"
                    v-model="state.localData.email"
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) => isValidEmail(v) || 'E-mail Inválido!',
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-bind="$regularStyle"
                    outlined
                    :label="currentDocumentType"
                    v-model="state.localData.document"
                    ref="document"
                    v-maska
                    :data-maska="documentMask"
                    @paste="pasteDocument"
                    :rules="[
                      (v) => !!v || 'Campo Obrigatório',
                      (v) =>
                        validateDocument(v) ||
                        'Não parece um CPF ou CNPJ Inválido',
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-bind="$regularStyle"
                    @input="validateForm()"
                    outlined
                    label="Senha"
                    v-model="state.localData.password"
                    :append-icon="
                      state.passwordView ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    :type="state.passwordView ? 'text' : 'password'"
                    @click:append="state.passwordView = !state.passwordView"
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) => (v && v.length >= 6) || 'Senha muito curta',
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-bind="$regularStyle"
                    @input="validateForm()"
                    outlined
                    label="Confirmar Senha"
                    v-model="state.localData.password_confirmation"
                    :append-icon="
                      state.passwordConfirmationView ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    :type="state.passwordConfirmationView ? 'text' : 'password'"
                    @click:append="
                      state.passwordConfirmationView =
                        !state.passwordConfirmationView
                    "
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) => (v && v.length >= 6) || 'Senha muito curta',
                      (v) =>
                        (v && v == state.localData.password) ||
                        'Senhas não conferem!',
                    ]"
                  ></v-text-field>
                  <v-btn
                    block
                    :color="$rootColor"
                    :dark="!state.loading"
                    :light="state.loading"
                    class="mb-2 text-white"
                    @click="signIn()"
                    :loading="state.loading"
                    :disabled="state.loading"
                    >Cadastrar</v-btn
                  >
                  <v-btn
                    text
                    block
                    @click="state.tab = 0"
                    :disabled="state.loading"
                    >Já possuo cadastro</v-btn
                  >
                </v-card-text>
              </v-window-item>
            </v-window>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";
import * as EmailValidator from "email-validator";
import { vMaska } from "maska";
import { reactive, computed, ref } from "vue";
import { useStore } from "vuex";
import { self } from "@/main";
import { useRouter } from "vue-router";

export default {
  directives: {
    maska: vMaska,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      loading: false,
      loadingRecovery: false,
      tab: 0,
      formModel: null,
      localData: reactive({}),
      passwordView: false,
      passwordConfirmationView: false,
      recoveryDialog: false,
    });

    const computedData = {
      documentMask: computed(() => {
        let id = state.localData?.document;
        let mask = getMask(id);
        return mask;
      }),
      currentDocumentType: computed(() => {
        let id = state.localData?.document;
        if (cpf.isValid(id || "")) {
          return "CPF";
        }
        if (cnpj.isValid(id || "")) {
          return "CNPJ";
        }
        return "CPF / CNPJ";
      }),
    };

    const myForm = ref(null);

    function validateForm() {
      myForm?.value?.validate();
    }

    function isValidEmail(email) {
      return EmailValidator.validate(email);
    }
    function validateDocument(v) {
      return cpf.isValid(v) || cnpj.isValid(v);
    }
    function pasteDocument(event) {
      let id = event?.clipboardData?.getData("text");
      if (cpf.isValid(id)) {
        setTimeout(() => {
          state.localData.document = cpf.format(id);
        }, 500);
      }
      if (cnpj.isValid(id)) {
        setTimeout(() => {
          state.localData.document = cnpj.format(id);
        }, 500);
      }
    }
    function getMask(id) {
      let cpfMask = "###.###.###-NNNNN";
      let cnpjMask = "##.###.###/####-##";
      if (cnpj.isValid(id)) return cnpjMask;
      if (id) {
        id = id.replaceAll(".", "");
        id = id.replaceAll("-", "");
        id = id.replaceAll(" ", "");
        id = id.replaceAll(",", "");
        if (id?.length > 11) return cnpjMask;
      }
      return cpfMask;
    }

    async function recovery() {
      state.loadingRecovery = true;
      let data = state.localData;
      await self()
        .$http.post(`auth/recovery`, data)
        .then((r) => {
          state.loadingRecovery = false;
          store.commit("showMessage", {
            type: "success",
            duation: 10000,
            text:
              r?.data?.message || "Enviamos um e-mail de redefinição de senha.",
            group: "app",
          });
          state.recoveryDialog = false;
        })
        .catch((e) => {
          state.loadingRecovery = false;
          store.commit("showMessage", {
            type: "error",
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
      state.loadingRecovery = false;
    }
    async function signIn() {
      const { valid } = await myForm.value.validate();
      if (!valid) {
        store.commit("showMessage", {
          type: "error",
          duation: 5000,
          text: "Preencha todos os campos obrigatórios!",
          group: "app",
        });
        return;
      }
      if (state.loading) return;
      state.loading = true;
      let url = "login";
      if (state.tab == 1) {
        url = "register";
      }
      let data = { ...state.localData };
      data.admin_panel = 1;
      await self()
        .$http.post(`auth/${url}`, data)
        .then((r) => {
          state.loading = false;
          localStorage.setItem("tk", r?.data?.token);
          self().$resetAxiosConfig();
          if (state.tab == 1) {
            store.commit("showMessage", {
              type: "success",
              duation: 10000,
              text:
                r.data?.message ||
                "Cadastro realizado com sucesso. Peça aprovação a um administrador",
              group: "app",
            });
            state.tab = 0;
          } else {
            router.push("/");
          }
        })
        .catch((e) => {
          console.log({ e });

          state.loading = false;
          let type = "error";
          if (e?.response?.status == 403) type = "warning";
          store.commit("showMessage", {
            type: type,
            duation: 5000,
            text:
              e?.response?.data?.message || "Ocorreu um erro, tente novamente!",
            group: "app",
          });
        });
      state.loading = false;
    }

    return {
      state,
      ...computedData,
      myForm,
      validateForm,
      isValidEmail,
      validateDocument,
      pasteDocument,
      getMask,
      recovery,
      signIn,
    };
  },
};
</script>

<style scoped>
.img__container {
  display: flex;
  justify-content: center;
}
.img__container > div {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 30%;
}
.title__card {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.7rem;
}
.subtitle__item {
  line-height: 1;
}
</style>
