<template>
  <v-container>
    <v-card>
      <v-progress-linear
        v-if="!editItem"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-text v-else>
        <FormSection :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormSection from './Form.vue';

export default {
  components: { FormSection },

  data() {
    return {
      editItem: null,
    };
  },
  mounted() {
    this.getSectionData();
  },
  methods: {
    getSectionData() {
      this.$http.get(`/admin/sections/${this.$route.params.id}`)
        .then((response) => {
          this.editItem = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
}
</script>

<style>

</style>