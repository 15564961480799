<template>
  <div class="content__upload" v-if="limit && data && field">
    <v-responsive :aspect-ratio="miniAvailable ? null : 1600 / 838">
      <div
        class="content__placeholder"
        @drop.prevent="
          draggingFile = false;
          onDrop($event);
        "
        @dragover.prevent="draggingFile = true"
        @dragenter.prevent="draggingFile = true"
        @dragleave.prevent="draggingFile = false"
      >
        <div class="no__photo">
          <v-icon color="black" size="1.6rem">mdi-image-area-close</v-icon>
          <span v-if="limit == 1">Clique ou arraste o arquivo aqui</span>
          <span v-else>Clique ou arraste os arquivos aqui</span>
        </div>
        <h3>field {{ data[`${field}`] }}</h3>
        <v-file-input
          v-model="fileOrFiles"
          class="file__input"
          hide-input
          @change="changeInput()"
          ref="upload"
          :accept="accept || 'image/*'"
          :multiple="limit > 1 ? true : false"
        ></v-file-input>
        <div class="loading__section" v-if="uploading">
          <v-progress-circular
            indeterminate
            color="white"
            size="50"
          ></v-progress-circular>
          <span
            >Fazendo Upload {{ concluded + 1 }}/{{ total }}
            {{ upload_percentage }}% ({{ upload_speed_formatted }}/s)
          </span>
          <span class="filename" v-if="currentFileUploading">
            {{ currentFileUploading.name }}
          </span>
          <div class="px-4 mt-2" style="width: 100%">
            <div
              style="
                width: 100%;
                border-radius: 5000px;
                overflow: hidden;
                height: 15px;
              "
            >
              <v-progress-linear
                :value="upload_percentage"
                color="orange"
                height="100%"
              ></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="dragging__file" v-if="draggingFile">
          <span>Solte o arquivo aqui para fazer o upload</span>
        </div>
      </div>
    </v-responsive>
    <div
      class="content__actions"
      v-if="
        filesSelected.length ||
        failedFilesUpload.length ||
        concludedFilesUpload.length
      "
    >
      <v-row class="ma-0">
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pa-0">
          <div class="item__action">
            <span>Pendentes: {{ filesSelected.length }}</span>
            <v-tooltip bottom color="black" v-if="filesSelected.length">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  icon
                  x-small
                  v-on="{ ...tooltip }"
                  @click="switchUploadStatus()"
                >
                  <v-icon>{{ uploading ? "mdi-pause" : "mdi-play" }}</v-icon>
                </v-btn>
              </template>
              <span>
                <small>{{
                  uploading ? "Pausar Upload" : "Continuar Upload"
                }}</small>
              </span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pa-0">
          <div class="item__action">
            <span>Falhas: {{ failedFilesUpload.length }}</span>
            <v-tooltip bottom color="black" v-if="failedFilesUpload.length">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  icon
                  x-small
                  v-on="{ ...tooltip }"
                  @click="retryFailed()"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>
                <small>Tentar Novamente</small>
              </span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pa-0">
          <div class="item__action">
            <span>Concluídos: {{ concludedFilesUpload.length }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: [
    "limit",
    "data",
    "field",
    "field_small",
    "mini",
    "mimesTypes",
    "accept",
  ],
  data() {
    return {
      fileOrFiles: null,
      draggingFile: false,
      upload_percentage: 0,
      upload_speed: 0,
      inputData: [],
      filesSelected: [],
      uploadController: null,
      uploading: false,
      currentFileUploading: null,
      concludedFilesUpload: [],
      failedFilesUpload: [],
      concluded: 0,
      total: 0,
      mimes: this.mimesTypes || ["image/jpeg", "image/png"],
    };
  },
  computed: {
    upload_speed_formatted() {
      let bytes = this.upload_speed;
      const decimals = 2;
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    miniAvailable() {
      return this.mini && !this.uploading;
    },
  },
  watch: {
    data: {
      deep: true,
      handler(n, o) {
      },
    },
    uploading(v) {
      if (v) {
        this.prepareToUpload();
      }
    },
  },
  methods: {
    changeInput() {
      if (!this.fileOrFiles) return;
      if (Array.isArray(this.fileOrFiles)) {
        if (!this.fileOrFiles?.length) return;
        if (this.fileOrFiles.length == 0) return;
        this.inputData = [];
        this.inputData.push(...this.fileOrFiles);
      } else {
        this.inputData = [];
        this.inputData.push(this.fileOrFiles);
      }
      this.changeInputPostAction();
    },
    async changeInputPostAction() {
      if (this.inputData.length == 0) {
        return alert("Nenhum dado selecionado!");
      }
      this.filesSelected = [...this.inputData];
      this.inputData = [];
      this.concluded = 0;
      this.total = this.filesSelected.length;
      this.uploading = true;
      // this.$refs.upload.$refs.input.value = null;
      // this.$refs.upload.value = null;
      this.$refs.upload.reset();
      this.$refs.upload.blur();
    },
    async prepareToUpload() {
      var i = this.filesSelected.length;
      while (i--) {
        let item = this.filesSelected[i];
        console.log({ item });
        if (!item) break;
        this.currentFileUploading = item;
        if (!this.uploading) break;
        try {
          let res = await this.upload(item);
          console.log({ res });
          if (res == true) {
            this.concludedFilesUpload.push(item);
          } else {
            throw new Error("Error while uploading");
          }
        } catch (error) {
          console.log("error prepareToUpload", error);
          if (this.uploading == true) {
            let file = new File([item], item.name, { type: item.type });
            this.failedFilesUpload.push(file);
          }
        } finally {
          if (this.uploading == true) {
            this.filesSelected.splice(i, 1);
            this.concluded++;
          }
        }
      }
      if (this.filesSelected.length > 0) {
        return await this.prepareToUpload();
      }
      if (this.uploading == true) {
        this.concluded = null;
      }
      this.uploading = false;
      this.currentFileUploading = null;
    },
    onDrop(e) {
      if (e?.dataTransfer?.files?.length > this.limit) {
        this.draggingFile = false;
        return alert(`Somente é permitido adicionar ${this.limit} arquivo(s)!`);
      }
      const acceptedImageTypes = this.mimes;
      Array.from(e?.dataTransfer?.files).forEach((file) => {
        if (!acceptedImageTypes.includes(file["type"])) {
          this.draggingFile = false;
          return alert("Tipo de arquivo não aceito.");
        }
        this.filesSelected.push(file);
      });
      this.draggingFile = false;
      this.uploading = true;
    },
    async upload(e) {
      if (!e) return;
      this.upload_percentage = 0;
      this.uploadController = new AbortController();
      var formData = new FormData();
      formData.append("file", e);
      let res = await this.$http
        .post("upload", formData, {
          signal: this.uploadController.signal,
          onUploadProgress: (event) => {
            this.upload_percentage = Math.round(
              (event.loaded * 100) / event.total
            );
            this.upload_speed = event.rate;
          },
        })
        .then((r) => {
          console.log({ r });
          this.$emit(`update_path`, r?.data?.url);
          // this.$emit(`update_path_small`, r?.data?.path_small);
          return true;
        })
        .catch((err) => {
          console.error({ err });
          if (this.uploading) {
            alert("Ocorreu um erro no Upload, tente novamente mais tarde.");
            console.log("errupload", e.response);
          }
          return e.response?.message || e;
        });
      this.upload_percentage = 0;
      this.upload_speed = 0;
      return res;
    },
    retryFailed() {
      this.filesSelected.push(...this.failedFilesUpload);
      if (!this.uploading) {
        this.total = 0;
      }
      this.uploading = true;
      this.total = this.total + this.failedFilesUpload.length;
      this.failedFilesUpload = [];
    },
    switchUploadStatus() {
      if (this.uploading) {
        if (!this.uploadController) return;
        this.uploading = false;
        this.uploadController.abort();
      } else {
        this.uploading = true;
      }
    },
    stopUpload() {
      this.currentFileUploading = null;
      this.filesSelected = [];
      this.failedFilesUpload = [];
      if (this.uploading) {
        this.uploadController.abort();
        this.uploading = false;
      }
    },
  },
};
</script>
  
  <style scoped>
.content__placeholder {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 2px dotted black;
}
.content__placeholder .no__photo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-weight: normal;
  font-family: "Libre Franklin", sans-serif;
  font-size: 0.9rem;
  color: black !important;
}
.file__input {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  opacity: 0;
}
.file__input >>> * {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.content__btns {
  position: absolute;
  width: max-content;
  height: max-content;
  top: 5px;
  right: 5px;
  z-index: 3;
  display: flex;
  align-items: center;
}
.loading__section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.726);
  font-family: "Libre Franklin", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  z-index: 4;
  transition: 0.8s;
}
.loading__section span {
  font-weight: normal;
  font-size: 0.8rem;
}
.loading__section span.filename {
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 1;
}
.dragging__file {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.455);
  font-family: "Libre Franklin", sans-serif;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  z-index: 7;
  pointer-events: none;
}
.image__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.image__text p {
  margin: 0;
  color: black;
  font-size: 0.9rem;
  font-family: "Libre Franklin", sans-serif;
  font-weight: normal;
}
.content__upload {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.item__action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
}
.item__action p,
.item__action span {
  margin: 0;
  line-height: 1;
  font-size: 0.8rem;
}
</style>