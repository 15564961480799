<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4 class="text-left">Novo Banners</h4>
      </v-card-title>
      <v-card-text>
        <BannerForm :editItem="editItem" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Form from "./Form.vue";

export default {
  components: {
    BannerForm: Form,
  },

  data() {
    return {
      id: null,
      editItem: null,
    };
  },

  created() {
    this.id = this.$route.params.id;
  },

  methods: {},
};
</script>

<style>
</style>