<template>
  <v-container>
    <v-card>
        <!-- add linear progress -->
        <v-progress-linear
            v-if="state.loading"
            indeterminate
            color="primary"
        ></v-progress-linear>

        <v-card-title>
            <h4 class="text-left">Editar estoque</h4>
        </v-card-title>
        <v-card-text>
          <v-form 
            v-model="state.formModel"
            @keypress.enter.prevent="save"
            @submit.prevent="save"
          >
            <v-select
              label="Ação de estoque"
              :items="types"
              item-title="description"
              item-value="type"
              v-model="state.localData.type"
              :rules="[(v) => !!v || 'Ação de estoque é obrigatória']"
              variant="outlined"
              density="compact"
            ></v-select>

            <v-text-field
              v-show="state.localData.type != null"
              :label="!state.localData.type ? 'Quantidade' : (state.localData.type == '0') ? 'Quantidade que será retirada' : 'Quantidade que será acrescentada'"
              v-model="state.localData.amount"
              :rules="[(v) => !!v || 'Ação de estoque é obrigatória']"
              variant="outlined"
              density="compact"
            ></v-text-field>

            <v-text-field
              label="Número do item do pedido de compra"
              v-model="state.localData.order_item_id"
              v-show="state.localData.type == '1'"
              :disable="state.localData.type == '0' ? true : false"
              variant="outlined"
              density="compact"
            ></v-text-field>
            
            <v-text-field
              label="Número do pedido venda"
              v-model="state.localData.invoice_number"
              v-show="state.localData.type == '0'"
              :disable="state.localData.type == '1' ? true : false"
              variant="outlined"
              density="compact"
            ></v-text-field>

            <v-textarea
              v-show="state.localData.type != null"
              label="Observações"
              v-model="state.localData.annotations"
              variant="outlined"
              density="compact"
              rows="3"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="text"
            @click="() => $router.push({ name: 'products.edit', params: { id: productId }, query: { tab: 'price_and_stock' } })"
            :disabled="state.loading"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="save"
              :disabled="state.loading"
          >
              Salvar
              <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
          types: [
            { type: "0", description: 'Saída' },
            { type: "1", description: 'Entrada' }
          ],
          product: null,
          stock: null,
          state: {
              localData: {
                product_stock_id: this.$route.params.stockId,
                type: null,
                amount: null,
                order_item_id: null,
                invoice_number: null,
                annotations: null,
              },
              loading: false,
          },
          stockId: null,
          productId: null,
        }
    },

    mounted() {
        this.stockId = this.$route.params.stockId;
        this.productId = this.$route.params.id;
    },

    methods: {
      async save() {
        this.state.loading = true;
        if (this.state.formModel) {
          console.log("dados para enviar ao server", this.state.localData);
          // const { data } = await this.$http.put(`/stock/${this.stockId}`, this.state.localData);
          // this.$router.push({ name: 'products' });


          await this.$http.post(`/admin/products/${this.productId}/stocks/${this.stockId}/stocks-movements`, this.state.localData)
            .then(response => {
              this.$store.commit("showMessage", {
                type: 'success',
                duration: 5000,
                text: 'Movimentação de estoque adicionado com sucesso',
                group: 'app'
              });
              this.$router.push({ name: 'products.edit', params: { id: this.productId }, query: { tab: 'price_and_stock' } });
            })
            .catch(error => {
              console.log(error);
              this.$store.commit("showMessage", {
                type: 'error',
                duration: 5000,
                text: e.response.data.message,
                group: 'app'
              });
            })
            .finally(() => {
              this.state.loading = false;
            });
        }
      }  
    }
}
</script>

<style>

</style>