<template>
  <div class="text-left my-4">
      <span class="mr-3">
        <strong>Período: </strong>
      </span>
    <v-btn
        color="primary"
        variant="elevated"
    >
      <v-icon>mdi-calendar</v-icon> Últimos 7 dias
    </v-btn>
  </div>

  <div class="d-flex justify-space-between align-center mb-4">
    <span class="text-h4">Dados de vendas</span>
    <v-btn
        variant="outlined"
        color="primary"
    >
      <v-icon>mdi-download</v-icon>
      <span>Baixar relatório de vendas</span>
    </v-btn>
  </div>

  <div class="d-flex justify-start align-center mb-4">
    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>645</h4>
      <p>Total de pedidos</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>40</h4>
      <p>Total de cancelamentos</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>R$ 8.000</h4>
      <p>Total de receita</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>R$ 12,40</h4>
      <p>Ticket médio</p>
    </div>

    <div class="indicator px-2 py-4 rounded border mr-4">
      <h4>6,2%</h4>
      <p>Indice de cancelamento</p>
    </div>
  </div>

  <!-- Graficos -->
  <v-row class="mb-4">
    <v-col cols="6">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between">
            <span class="text-h6">Pedidos</span>
          </div>
        </v-card-title>
        <v-card-text>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between">
            <span class="text-h6">Ticket médio</span>
          </div>
        </v-card-title>
        <v-card-text>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <v-card class="mb-4">
    <v-card-title>
      <div class="d-flex justify-space-between align-center">
        <span class="text-h5">Produtos mais vendidos</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-table>
        <thead>
        <tr>
          <th>Posição</th>
          <th>Produto</th>
          <th>Qtd. de vendas</th>
          <th>Ticket médio</th>
          <th>Ações</th>
        </tr>
        </thead>
      </v-table>
    </v-card-text>
  </v-card>

  <div class="d-flex justify-space-between align-center mb-4">
    <span class="text-h4">Dados de entregadores</span>
    <v-btn
        variant="outlined"
        color="primary"
    >
      <v-icon>mdi-download</v-icon>
      <span>Baixar relatório de entregadores</span>
    </v-btn>
  </div>

  <v-card class="mb-4">
    <v-card-title>
      <div class="d-flex justify-space-between align-center">
        <span class="text-h5">Entregadores</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-table>
        <thead>
        <tr>
          <th>Nome do entregador</th>
          <th>Qtd. de entregas</th>
          <th>Tempo médio de entrega</th>
          <th>Ações</th>
        </tr>
        </thead>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashboardSection.vue"
}
</script>

<style scoped>

</style>