<template>
  <v-form
    ref="form"
    v-model="state.formModel"
    @keypress.enter.prevent="save"
    @submit.prevent="save"
  >
    <v-card-text>
      <v-row class="ma-0 pa-0">
        <v-img
          :src="$imgurl(state.localData.img)"
          aspect-ratio="1"
          v-if="state.localData.img"
          style="height: 7rem"
        ></v-img>
      </v-row>
      <UploadComponent
        class="mt-2 mb-6"
        :limit="1"
        :data="state.localData"
        :field="'img'"
        :field_small="'img_small'"
        style="height: 100px"
        v-on:update_path="newUrlImage"
      ></UploadComponent>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="state.localData.name"
            label="Nome da loja"
            variant="outlined"
            :rules="[
              (v) => !!v || 'O nome da loja é obrigatório',
              (v) =>
                (v && v.length <= 255) ||
                'O nome da loja deve ter no máximo 255 caracteres',
            ]"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="state.localData.phone_number"
            class="append__no__margin"
            label="Telefone"
            :rules="[
              (v) => !!v || 'Telefone é obrigatório',
              (v) =>
                (v && v.length <= 20) ||
                'Telefone deve ter no máximo 20 caracteres',
            ]"
            variant="outlined"
            density="compact"
          >
            <template #append>
              <input
                v-maska
                :data-maska="phoneMask"
                v-model="state.localData.phone_number"
                :style="{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  userSelect: 'none',
                }"
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="state.localData.radius"
            label="Raio atendimento (metros)"
            variant="outlined"
            type="number"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="state.localData.free_shipment_tax_distance"
            label="Raio máximo para frete grátis (metros)"
            variant="outlined"
            type="number"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <currency-input
            v-model="state.localData.shipment_tax_meter"
            label="Preço de frete (por metro)"
            variant="outlined"
            type="number"
            density="compact"
            :precision="4"
          ></currency-input>
        </v-col>

        <v-col cols="3">
          <!-- put mask with maska -->
          <v-text-field
            v-model="state.localData.zipcode"
            label="CEP"
            ref="zipcode"
            v-maska
            maxlength="8"
            variant="outlined"
            :data-maska="'########'"
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) =>
                (v && v.length <= 8) == true ||
                'O CEP deve ter no máximo 8 caracteres',
              (v) =>
                (v && v.match(/^[0-9]{5}[0-9]{3}$/)?.length > 0) == true ||
                'CEP inválido',
            ]"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="state.localData.street"
            label="Logradouro"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="state.localData.number"
            label="Número"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="state.localData.complement"
            label="Complemento"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="state.localData.neighborhood"
            label="Bairro"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="state.localData.city"
            label="Cidade"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            maxlength="2"
            v-model="state.localData.state"
            label="Estado (UF)"
            variant="outlined"
            :rules="[
              (v) =>
                (v && v.length == 2) == true ||
                'O estado deve ter 2 caracteres',
              (v) =>
                (v && v.match(/^[A-Z]{2}$/)?.length > 0) == true ||
                'Estado inválido',
            ]"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="state.localData.allow_shipment_tracking"
            label="Permitir rastreamento de remessa"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="text" :to="{ name: 'stores.index' }"> Cancelar </v-btn>
      <v-btn color="primary" type="submit" variant="elevated">
        Salvar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { vMaska } from "maska";
import { defineAsyncComponent } from "vue";

export default {
  directives: {
    maska: vMaska,
  },

  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    CurrencyInput: defineAsyncComponent(() =>
      import("@/components/CurrencyInput.vue")
    ),
    UploadComponent: defineAsyncComponent(() =>
      import("@/components/UploadComponent.vue")
    ),
  },

  data() {
    return {
      title: this.editItem?._id ? "Editar loja" : "Nova loja",
      method: this.editItem?._id ? "put" : "post",
      urlSave: this.editItem?._id
        ? `/admin/stores/${this.editItem._id}`
        : "/admin/stores",
      state: {
        loading: false,
        formModel: null,
        localData: this.editItem ?? {
          _id: null,
          img: null,
          name: "",
          zipcode: "",
          street: "",
          number: "",
          neighborhood: "",
          city: "",
          state: "",
          allow_shipment_tracking: false,
          shipment_tax_meter: 0,
          free_shipment_tax_distance: 0,
        },
      },
    };
  },
  computed: {
    phoneMask() {
      const v = this.state?.localData?.phone_number;
      const p1 = "(##) ####-#####";
      const p2 = "(##) #####-####";
      if (!v) return p1;
      const getNumbersAsString = (str) => (str.match(/\d+/g) || []).join("");
      if (getNumbersAsString(v).length > 10) return p2;
      return p1;
    },
  },

  mounted() {
    console.log("Dados vindos de Props: ", this.props);
  },

  methods: {
    async newUrlImage(imageUrl) {
      this.state.localData.img = imageUrl;
    },

    back() {
      this.$router.push({ name: "stores.index" });
    },

    async save() {
      let v = await this.$refs.form?.validate();
      if (v.valid) {
        this.state.loading = true;
        let data = { ...this.state.localData };
        await this.$http[this.method](this.urlSave, data)
          .then((r) => {
            this.$store.commit("showMessage", {
              type: "success",
              duration: 5000,
              text: "Loja salva com sucesso",
              group: "app",
            });
            this.$router.push({ name: "stores.index" });
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              type: "error",
              duration: 5000,
              text: e.response.data.message,
              group: "app",
            });
          });
      }
      this.state.loading = false;
    },
  },
};
</script>

<style>
</style>