<template>
  <v-expansion-panel>
    <v-expansion-panel-title class="px-2 py-1">
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <div
        v-else
        class="title d-flex text-left py-1 px-2 align-center"
        style="width: 100%"
      >
        <h4>{{ status.title }}</h4>
        <v-spacer></v-spacer>
        <v-tooltip
          :text="loading ? 'Aguarde' : 'Recarregar este status'"
          location="end"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="ml-1 pa-0"
              icon
              size="x-small"
              @click.prevent.stop="listOrdersByStatus()"
              :loading="loading"
              min-height="1rem"
              min-width="1rem"
              max-height="1rem"
              max-width="1rem"
              width="1rem"
              height="1rem"
              color="transparent"
              elevation="0"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        {{ orders.length }}
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <OrderCard
        v-for="(order, orderIndex) in orders"
        :key="order._id"
        :order="order"
        :color="status.color"
        :isLast="orderIndex + 1 == orders.length"
        @click="clickOrder(order)"
      ></OrderCard>
      <span class="text-center" v-if="orders.length == 0"
        >Nada por enquanto</span
      >
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import OrderCard from "@/components/Orders/OrderCard.vue";
import moment from "moment";

export default {
  name: "OrdersStatusList",
  components: { OrderCard },
  props: {
    status: Object,
    sectionId: String,
    filters: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      orders: [],
    };
  },

  beforeUnmount() {
    this.orders = [];
  },

  mounted() {
    this.listOrdersByStatus();
  },

  watch: {
    sectionId() {
      this.listOrdersByStatus();
    },
  },

  methods: {
    clickOrder(order) {
      this.$store.commit("setOrderId", null);
      setTimeout(() => {
        this.$store.commit("setOrderId", order?._id);
      }, 500);
    },

    async listOrdersByStatus() {
      if (this.loading) return;
      this.loading = true;
      let filtersLocal = {...(this.filters ?? {})};
      if (!filtersLocal.start) {
        filtersLocal.start = moment().format("YYYY-MM-DD");
      }
      if (!filtersLocal.end) {
        filtersLocal.end = moment().add(1, "days").format("YYYY-MM-DD");
      }
      const params = {
        ...filtersLocal,
        id:
          this.orders?.filter((o) => o._id == this.$store.state?.orderId)
            .length > 0
            ? this.$store.state?.orderId
            : null,
      };
      await this.$http
        .get(`/admin/orders/status/${this.status.key}/${this.sectionId}`, {
          params,
        })
        .then((response) => {
          this.loading = false;
          this.orders = response.data.items;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
</style>