<template>
  <v-menu v-if="show && user">
    <template v-slot:activator="{ props }">
      <v-btn style="height: max-content" class="btn__popover" v-bind="props">
        <!--  -->
        <v-avatar color="surface-variant">
          <v-img :src="$imgurl(user.img || 'utils/genericuser.png')"></v-img>
        </v-avatar>
        <template v-slot:append>
          <v-icon :color="$rootColor" size="x-large">mdi-menu-down</v-icon>
        </template>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="logout()" v-if="!loadingLogout">
        <template v-slot:prepend>
          <v-icon icon="mdi-logout" color="red"></v-icon>
        </template>
        <v-list-item-title>Sair</v-list-item-title>
      </v-list-item>
      <v-list-item v-else>
        <v-progress-linear
          :indeterminate="true"
          color="red"
        ></v-progress-linear>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  props: ["show", "navigationdrawerref"],
  setup(props) {
    const store = useStore();

    const computedData = {
      user: computed(() => {
        return store.state.user;
      }),
      loadingLogout: computed(() => {
        return props.navigationdrawerref?.state?.loadingLogout ?? false;
      }),
    };

    const logout = () => {
      props.navigationdrawerref?.logout();
    };
    return {
      ...computedData,
      logout,
    };
  },
};
</script>

<style scoped>
.btn__popover {
  height: 100% !important;
}
</style>